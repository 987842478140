<div class="d-flex flex-row">
  <div
    class="pill d-flex justify-content-center align-items-center"
    *ngIf="isCompetencySelectActive"
  >
    <input
      id="typeahead-basic"
      type="text"
      [(ngModel)]="selectedCompetency"
      [ngbTypeahead]="search"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      [editable]="false"
      (selectItem)="checkChoosenCompetency($event)"
      (keydown.escape)="clearCompetencySelect($event)"
      (focusout)="clearCompetencySelect($event)"
      #instance="ngbTypeahead"
    />
  </div>
  <div
    class="pill d-flex justify-content-center align-items-center gap-3"
    *ngIf="isCompetencyLevelSelectActive"
  >
    {{ competency.name | truncate : 5 }}
    <span
      ngbTooltip="{{
        'competency.unassigned_competency_tooltip'
          | translate : { competency: competency.name }
      }}"
      placement="bottom"
      container="body"
      class="ms-1"
      ><fa-icon [icon]="['fas', 'circle-info']"></fa-icon
    ></span>
    <input
      class="level-input-box"
      type="number"
      min="1"
      max="5"
      step="1"
      [(ngModel)]="newCompetencyLevel"
      (click)="$event.stopPropagation()"
    />
    <fa-icon
      data-testid="save-command"
      class="command"
      [icon]="['fas', 'check']"
      (click)="onSaveLevelEditing()"
    ></fa-icon>
    <fa-icon
      data-testid="clear-command"
      class="command"
      [icon]="['fas', 'xmark']"
      (click)="clearLevelSelect()"
    ></fa-icon>
  </div>
  <div
    id="addCompetency"
    class="pill d-flex justify-content-center align-items-center command"
    [ngClass]="{
      'pill-disabled':
        isCompetencySelectActive ||
        isCompetencyLevelSelectActive ||
        isAddDisabled
    }"
    (click)="openNewCompetencyInput()"
  >
    <fa-icon [icon]="['fas', 'plus']"></fa-icon>
  </div>
</div>
