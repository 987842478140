<div class="d-flex w-100 h-100 base-container">
  <div class="content mw-100 mh-100 d-flex flex-grow-1 pt-3 flex-column">
    <div class="title-container">
      <img
        src="..\..\assets\img\squirrel_hardhat.png"
        class="title-squirrel-image"
      />
      <h1 class="title">{{ "settings.title" | translate }}</h1>
    </div>
    <div class="flex-grow-1 d-flex flex-column pb-3 tabs">
      <div
        class="d-flex flex-column h-100 shadow rounded-3 separated-display-container"
      >
        <ul
          ngbNav
          #nav="ngbNav"
          class="nav-tabs rounded-top-3 border-bottom-0"
          [destroyOnHide]="true"
        >
          <li [ngbNavItem]="0">
            <button ngbNavLink class="nav-link border-bottom-0">
              <label class="nav-link-label text-overflow-dotted">{{
                "settings.user_management.title" | translate
              }}</label>
            </button>
            <ng-template ngbNavContent
              ><app-table-user-management
                class="user-table h-100"
              ></app-table-user-management
            ></ng-template>
          </li>
          <li [ngbNavItem]="1">
            <button ngbNavLink class="nav-link border-bottom-0">
              <label class="nav-link-label text-overflow-dotted">{{
                "settings.competency_management.title" | translate
              }}</label>
            </button>
            <ng-template ngbNavContent
              ><app-table-competency-management></app-table-competency-management
            ></ng-template>
          </li>
          <li [ngbNavItem]="2">
            <button ngbNavLink class="nav-link border-bottom-0">
              <label class="nav-link-label text-overflow-dotted">{{
                "settings.competency_import.title" | translate
              }}</label>
            </button>
            <ng-template ngbNavContent
              ><app-competency-import></app-competency-import
            ></ng-template>
          </li>
        </ul>
        <div
          [ngbNavOutlet]="nav"
          class="mh-100 p-3 rounded-bottom-3 flex-grow-1 bordered-container"
        ></div>
      </div>
    </div>
  </div>
</div>
