import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { finalize, Subscription } from "rxjs";
import {
  LookingForResourceDTO,
  LookingForResourceService,
  UserSelectDTO,
} from "src/shared/api/generated";
import { DisplayAndEditProjectCompetencyDto } from "src/shared/models/display-and-edit-project-competency.model";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { isDeepEqual } from "src/shared/util/is-deep-equal";
import { ProjectMembersModalComponent } from "../../project-members-modal/project-members-modal.component";
import { LookingForCommentModalComponent } from "../looking-for-comment-modal/looking-for-comment-modal.component";
import { errorMessage } from "src/shared/constant/error-message.constant";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "[app-tr-looking-for-resource]",
  templateUrl: "./tr-looking-for-resource.component.html",
  styleUrls: ["./tr-looking-for-resource.component.scss"],
})
export class TrLookingForResourceComponent implements OnInit, OnDestroy {
  @Input() lookingForResource = {} as LookingForResourceDTO;
  @Input() users: UserSelectDTO[] = [];
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<number, string> = new Map<number, string>();

  @Output() onLookingForResourceUpdated = new EventEmitter<LookingForResourceDTO>();
  @Output() onCommentSaved = new EventEmitter<void>();
  @Output() onProjectMembersModified = new EventEmitter<void>();

  editedCell = "";

  editLookingForResource: LookingForResourceDTO = {} as LookingForResourceDTO;
  projectStateLabel: string = "";
  selectedCellProjectId: number | null = null;
  selectedMemberCellProjectId: number | null = null;
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService,
    private lookingForResourceService: LookingForResourceService,
    private modalService: NgbModal,
    private confirmationModalService: ConfirmationModalService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.resetEditedLookingForResources();
    this.projectStateLabel = this.translate.instant(
      "home.looking_for_resources.state." +
        `${this.lookingForResource.projectState}`
    );
    this.subscriptions.add(
      this.competencyManagerService.projectCompetency.subscribe(
        (data: DisplayAndEditProjectCompetencyDto) => {
          this.selectedCellProjectId = data.projectId;
        }
      )
    );
  }

  handleLookingForResourceUpdate(): void {
    if (isDeepEqual(this.editLookingForResource, this.lookingForResource)) {
      this.setEditStateFalse();
      return;
    }

    if (this.editLookingForResource.fte === null) {
      this.setEditStateFalse();
      return;
    }

    this.updateLookingForResource(this.editLookingForResource);
    this.setEditStateFalse();
  }

  private updateLookingForResource(
    lookingForResource: LookingForResourceDTO
  ): void {
    this.lookingForResourceService
      .updateLookingForResource(lookingForResource)
      .pipe(finalize(() => this.resetEditedLookingForResources()))
      .subscribe({
        next: (savedLookingForResource) => {
          this.lookingForResource = savedLookingForResource
          this.onLookingForResourceUpdated.emit(savedLookingForResource);
        },
        error: (error) => this.handleLookingForResourceSaveError(error),
      });
  }

  private handleLookingForResourceSaveError(error: HttpErrorResponse): void {
    if (error.error.message === errorMessage.lookingForResourceAlreadyExists) {
      this.confirmationModalService.openConfirmationModal(
        "home.looking_for_resources.error.title",
        "home.looking_for_resources.error.already_has_active_entry",
        true
      );
    }
  }

  selectCompetencyCell($event: MouseEvent): void {
    this.inputClicked($event);
    if (!this.lookingForResource.projectId) {
      return;
    }
    if (this.lookingForResource.projectId === this.selectedCellProjectId) {
      this.competencyManagerService.clearProjectCompetency();
      return;
    }
    this.competencyManagerService.setProjectCompetency(
      true,
      this.lookingForResource.projectId,
      this.lookingForResource.projectName ?? "",
      this.lookingForResource.competencies ?? []
    );
  }

  selectMemberCell($event: MouseEvent): void {
    this.inputClicked($event);
    if (!this.lookingForResource.id) {
      return;
    }

    const modalRef = this.modalService.open(ProjectMembersModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
      keyboard: false,
    });
    modalRef.componentInstance.projectName =
      this.lookingForResource.projectName;
    modalRef.componentInstance.projectMembers = this.lookingForResource.members;
    modalRef.componentInstance.allCompetencies = this.allCompetencies;
    modalRef.componentInstance.projectId = this.lookingForResource.projectId;
    this.selectedMemberCellProjectId = this.lookingForResource.projectId;
    modalRef.closed.subscribe((result) => {
      if (result) this.onProjectMembersModified.emit();
      this.selectedMemberCellProjectId = null;
    });
    modalRef.dismissed.subscribe((result) => {
      if (result) this.onProjectMembersModified.emit();
      this.selectedMemberCellProjectId = null;
    });
  }

  resetEditedLookingForResources(): void {
    this.editLookingForResource = JSON.parse(
      JSON.stringify(this.lookingForResource)
    );
  }

  isMemberSearchResult() {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return this.lookingForResource.members.filter((member) =>
      member.employeeName
        ?.toLowerCase()
        .includes(this.searchTerm!.toLowerCase())
    ).length;
  }

  isCompetencySearchResult(): boolean {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return (
      (
        this.lookingForResource.competencies?.filter((competency) => {
          return !!this.allCompetencies
            .get(competency.competencyId)
            ?.toLowerCase()
            .includes(this.searchTerm?.toLowerCase() ?? "");
        }) ?? []
      ).length > 0
    );
  }

  setEditStateFalse(): void {
    this.editedCell = "";
    this.resetEditedLookingForResources();
  }

  setEditStateTrue(key: string): void {
    this.editedCell = key;
  }

  inputClicked($event: MouseEvent): void {
    $event.stopPropagation();
  }

  openCommentEditModal($event: MouseEvent): void {
    this.inputClicked($event);
    const modalRef = this.modalService.open(LookingForCommentModalComponent, {
      windowClass: "custom-modal-window",
    });
    modalRef.componentInstance.lookingForEntity = {
      ...this.lookingForResource,
    };
    modalRef.componentInstance.entityName = this.lookingForResource.projectName;
    modalRef.componentInstance.mode = "looking-for-resource";
    modalRef.closed.subscribe((result) => {
      if (result) this.onCommentSaved.emit();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
