import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ConfigurationService } from "src/shared/services/configuration.service";
import { PermissionService } from "src/shared/services/permission.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
  language = localStorage.getItem('language') ?? 'en';
  languages: string[] = [];
  isAdmin: boolean = false;
  isNotSales: boolean = true;

  constructor(
    private configService: ConfigurationService,
    public permissionService: PermissionService,
    public translate: TranslateService,
    private router: Router
  ) {
    this.isAdmin = permissionService.checkAdmin();
    this.isNotSales = permissionService.checkNotSalesUser();
    this.languages = this.translate.getLangs();
  }

  logout() {
    this.configService.clearToken();
    this.router.navigateByUrl("/login");
  }

  changeLanguage(language: string){
    this.language = language;
    this.translate.use(this.language)
    localStorage.setItem('language', this.language);
  }
}
