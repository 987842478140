<app-base-modal
  [title]="'competency.competency_add_modal.create_competency' | translate"
  [activeModal]="activeModal"
>
  <div class="content">
    <form class="form" [formGroup]="competencyForm">
      <div class="form-row">
        <label for="competency-name-input" class="row-label">
          {{ "competency.competency_add_modal.competency_name" | translate }}:
        </label>
        <div class="wrapper w-100">
          <input
            id="competency-name-input"
            type="text"
            formControlName="name"
            class="w-50"
            (blur)="trimText(competencyForm.get('name')?.value)"
          />
          <span
            *ngIf="
              competencyForm.get('name')?.hasError('required') &&
              competencyForm.get('name')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="competency-group-select" class="row-label">
          {{ "competency.competency_add_modal.competency_type" | translate }}:
        </label>
        <div class="wrapper w-100">
          <select
            id="competency-group-select"
            formControlName="competencyGroupId"
            class="px-1 w-50"
          >
            <option *ngFor="let type of competencyGroups" value="{{ type.id }}">
              {{ type.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="error" class="error mt-4">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
      {{ errorMessage }}
    </ngb-alert>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveCompetency()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
