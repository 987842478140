<div class="d-flex w-100 h-100 base-container">
  <div class="content mw-100 mh-100 d-flex flex-grow-1 pt-3 flex-column">
    <div class="title-container">
      <img
        src="..\..\assets\img\squirrel_magnifier.png"
        class="title-squirrel-image"
      />
      <h1 class="title">{{ "smart_search.title" | translate }}</h1>
    </div>
    <div
      class="flex-grow-1 d-flex flex-column mb-3 mh-50 h-50 p-3 rounded-3 shadow bordered-container"
    >
      <div class="h-100">
        <label class="result-title">
          {{ "smart_search.exact_results" | translate }}
        </label>
        <div class="table-container" data-testid="exact">
          <app-table-smart-search-result
            #exactMatchesTable
            [headers]="headers"
            [results]="exactMatches"
            [page]="exactPage"
            [pageSize]="EXACT_PAGE_SIZE"
            [collectionSize]="exactCollectionSize"
            (refresh)="refreshExact($event)"
            (employeeSelectionChanged)="exactEmployeeSelectionChanged($event)"
          ></app-table-smart-search-result>
        </div>
      </div>
    </div>
    <div
      class="flex-grow-1 d-flex flex-column mb-3 mh-50 h-50 p-3 rounded-3 shadow bordered-container"
    >
      <div class="h-100">
        <label class="result-title">
          {{ "smart_search.close_results" | translate }}
        </label>
        <div class="table-container" data-testid="approximate">
          <app-table-smart-search-result
            #approxMatchesTable
            [headers]="headers"
            [results]="approxMatches"
            [page]="approxPage"
            [pageSize]="APPROX_PAGE_SIZE"
            [collectionSize]="approxCollectionSize"
            [conditions]="heatmapConditions"
            [heatmap]="true"
            (refresh)="refreshApprox($event)"
            (employeeSelectionChanged)="approxEmployeeSelectionChanged($event)"
          ></app-table-smart-search-result>
        </div>
        <div class="w-100 basket-icon-container" *ngIf="isNotSales">
          <app-basket-floating-button [employeeIds]="allSelectedEmployeeIds">
          </app-basket-floating-button>
        </div>
      </div>
    </div>
  </div>
  <app-side-toolbar [title]="'search_sidebar.title' | translate">
    <app-search-sidebar
      (companySelectionChanged)="onCompanySelectionChanged($event)"
      (search)="assembleTable($event)"
    ></app-search-sidebar>
  </app-side-toolbar>
</div>
