import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  catchError,
  forkJoin,
  throwError,
} from "rxjs";
import {
  CompetencyDTO,
  CompetencyService,
  EmployeeCompetencyService,
  EmployeeDTO,
  EmployeeService,
  PlanService,
  ProjectSelectDTO,
  ProjectService,
} from "src/shared/api/generated";
import { CompetencyPillCommand } from "src/shared/models/competency-pill-command.model";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { dateValidator } from "src/shared/validators/date.validator";

@Component({
  selector: "app-create-plan-modal",
  templateUrl: "./create-plan-modal.component.html",
  styleUrls: ["./create-plan-modal.component.scss"],
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: "yyyy/MM/dd" },
    },
  ],
})
export class CreatePlanModalComponent implements OnInit {
  @Input() employeeOption: boolean = false;
  @Input() employeeId: number = -1;
  @Input() employeeName: string = "";

  employeeSelectConfig: any;
  projectSelectConfig: any;

  error: boolean = false;
  errorMessage: string = "";

  employeeCompetencies: CompetencyDTO[] = [];
  projects: ProjectSelectDTO[] = [];
  employees: EmployeeDTO[] = [];

  planForm: FormGroup = new FormGroup(
    {
      selectedEmployee: new FormControl<EmployeeDTO | null>(
        { value: null, disabled: true },
        Validators.required
      ),
      selectedProject: new FormControl<ProjectSelectDTO | null>(
        { value: null, disabled: true },
        Validators.required
      ),
      from: new FormControl<string>("", Validators.required),
      to: new FormControl<string>("", Validators.required),
      description: new FormControl<string | null>(null),
      percentage: new FormControl<number>(100, [
        Validators.required,
        Validators.min(1),
        Validators.max(100),
      ]),
    },
    [dateValidator]
  );

  competencies: CompetencyDTO[] = [];
  allCompetencies: CompetencyDTO[] = [];

  isLoading: boolean = false;

  deleteCompetencyFromList = (selectedCompetency: CompetencyDTO) => {
    this.competencies = this.competencies.filter(
      (competency) => competency.id !== selectedCompetency.id
    );
  };
  commands: CompetencyPillCommand[] = [
    {
      icon: "trash",
      function: this.deleteCompetencyFromList,
    },
  ];

  ngAfterViewInit() {
    this.employeeSelectConfig = {
      customComparator: (item: EmployeeDTO) => { item.name.toLowerCase() },
      search: true,
      limitTo: 0,
      height: '350px',
      searchOnKey: 'name',
      displayKey: 'name',
      displayFn: (item: EmployeeDTO) => item.name,
      placeholder: this.translate.instant('project_assignments.select.employe_placeholder'),
      moreText: this.translate.instant('project_assignments.select.moreText'),
      noResultsFound: this.translate.instant('project_assignments.select.noResultsFound'),
      searchPlaceholder: this.translate.instant('project_assignments.select.searchPlaceholder')
    };

    this.projectSelectConfig = {
      customComparator: (item: ProjectSelectDTO) => { item.name?.toLowerCase() },
      search: true,
      limitTo: 0,
      height: '350px',
      searchOnKey: 'name',
      displayKey: 'name',
      displayFn: (item: ProjectSelectDTO) => item.name,
      placeholder: this.translate.instant('project_assignments.select.project_placeholder'),
      moreText: this.translate.instant('project_assignments.select.moreText'),
      noResultsFound: this.translate.instant('project_assignments.select.noResultsFound'),
      searchPlaceholder: this.translate.instant('project_assignments.select.searchPlaceholder')
    };

    this.cdr.detectChanges();
  }

  saveNewPlan(): void {
    this.closeAlert();

    if (this.isDisabled()) {
      this.planForm.markAllAsTouched();
      return;
    }

    const { from, to, percentage, description } = this.planForm.value;
    const employeeId = this.employeeOption ? this.selectedEmployee.id! : this.employeeId;
    this.planService
      .createPlan({
        from,
        to,
        percentage,
        description,
        employeeId: employeeId,
        projectId: this.planForm.value.selectedProject.id!,
        competencyIdList: this.competencies.map((competency) => competency.id!),
      })
      .pipe(catchError((error) => this.handleNewPlanSaveError(error)))
      .subscribe((newPlan) => this.activeModal.close(newPlan));
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public activeModal: NgbActiveModal,
    private planService: PlanService,
    private competencyService: CompetencyService,
    private employeeCompetencyService: EmployeeCompetencyService,
    private employeeService: EmployeeService,
    private projectService: ProjectService,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.fetchData();
  }

  fetchData() {
    if (this.employeeOption) {
      forkJoin([
        this.employeeService.getAllEmployeesFromCompany(),
        this.projectService.getAllProjectSelectDtos(),
      ])
        .pipe(showLoadingSpinner(this.spinnerService))
        .subscribe(([allEmployees, allProjects]) => {
          this.employees = allEmployees;
          this.projects = allProjects;
          this.planForm.get("selectedEmployee")?.enable();
          this.planForm.get("selectedProject")?.enable();
          this.sortFilterData();
        });
    } else {
      forkJoin([
        this.projectService.getAllProjectSelectDtos(),
        this.competencyService.getAllCompetenciesByEmployeeId(this.employeeId),
      ])
        .pipe(showLoadingSpinner(this.spinnerService))
        .subscribe(([allProjects, employeeCompetencies]) => {
          this.projects = allProjects;
          this.employeeCompetencies = employeeCompetencies;
          this.planForm.get("selectedProject")?.enable();
          this.sortFilterData();
        });
    }
  }

  private sortFilterData() {
    this.projects.sort((a, b) => a.name!.toLowerCase().localeCompare(b.name!.toLowerCase()));
    this.employees.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    const updatedProjects = [...this.projects];
    this.projects = [];
    this.cdr.detectChanges();
    this.projects = updatedProjects;
    this.cdr.detectChanges();

    const updatedEmployees = [...this.employees];
    this.employees = [];
    this.cdr.detectChanges();
    this.employees = updatedEmployees;
    this.cdr.detectChanges();
  }

  fetchCompetencies(selectItem: any) {
    this.sortFilterData();

    const projectId = selectItem.item?.id || selectItem.value?.id;

    if (!projectId) {
      return;
    }

    this.competencyService
      .getAllCompetenciesByProjectId(projectId)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((allCompetencies) => (this.allCompetencies = allCompetencies));
    this.competencies = [];
  }

  fetchEmployeeCompetencies(selectItem: any) {
    this.sortFilterData();

    if (!selectItem.value) {
      return;
    }

    this.employeeId = selectItem.value.id;
    this.employeeName = selectItem.value.name;

    this.competencyService
      .getAllCompetenciesByEmployeeId(this.employeeId)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((employeeCompetencies) => (this.employeeCompetencies = employeeCompetencies));
  }

  isDisabled(): boolean {
    return this.selectedProject?.id! < 0 || this.planForm.invalid;
  }

  isCompetencyPillDisabled(): boolean {
    if (!this.employeeOption) {
      return !this.selectedProject;
    }

    const isProjectUnselected = !this.selectedProject || this.isEmptyArray(this.selectedProject);
    const isEmployeeUnselected = !this.selectedEmployee || this.isEmptyArray(this.selectedEmployee);
    return isProjectUnselected || isEmployeeUnselected;
  }

  isEmptyArray(value: any): boolean {
    return Array.isArray(value) && value.length === 0;
  }

  closeAlert() {
    this.error = false;
    this.errorMessage = "";
  }

  checkAndAddNewCompetency({ competency, level }: any): void {
    this.isLoading = true;
    if (level) {
      this.saveEmployeeCompetency(competency, level);
    } else {
      this.addNewCompetency(competency);
    }
  }

  saveEmployeeCompetency(competency: CompetencyDTO, level: number): void {
    this.employeeCompetencyService
      .createEmployeeCompetency({
        employeeId: this.employeeId,
        competencyId: competency.id!,
        level: level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: () => {
          this.employeeCompetencies.push(competency);
          this.addNewCompetency(competency);
        },
      });
  }

  addNewCompetency(competency: CompetencyDTO): void {
    this.competencies.push(competency);
    this.isLoading = false;
  }

  validatePercentageField(): void {
    const control = this.planForm.get("percentage");
    if (!control || !control.value) return;

    if (control.value > 100) {
      control.setValue(100);
    } else if (control.value < 1) {
      control.setValue(1);
    }
  }

  private handleNewPlanSaveError(error: HttpErrorResponse): Observable<never> {
    if (error.status === HttpStatusCode.Conflict) {
      this.error = true;
      this.errorMessage = this.translate.instant(
        "plan.create_modal.error.plans_overlap_on_employee"
      );
    }
    return throwError(() => error.error);
  }

  get selectedProject() {
    return this.planForm.get("selectedProject")?.value;
  }

  get selectedEmployee() {
    return this.planForm.get("selectedEmployee")?.value;
  }

}
