import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserToken } from 'src/shared/models/user-token.model';
import { AuthService } from 'src/shared/services/auth.service';
import { ConfigurationService } from 'src/shared/services/configuration.service';
import { UserConfigStoreService } from 'src/shared/services/user-config-store.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit, OnDestroy {
  protected user: UserToken = {} as UserToken;
  private subscription: Subscription;
  public badAuthentication: boolean = false;

  constructor(
    private router: Router,
    private readonly authService: AuthService,
    private readonly configService: ConfigurationService,
    private readonly route: ActivatedRoute,
    private userConfigStore: UserConfigStoreService,
    public translate: TranslateService,
  ) {
    this.subscription = this.route.queryParams.subscribe((params) => {
      const token = params['token'] as string;
      const error = params['error'] as string;
      if (token) {
        const response = this.authService.decodeAndSaveUser(token);
        if (response) {
          this.router.navigate(['./home']);
          this.userConfigStore.loadUserConfig();
        } else {
        this.badAuthentication = true;
        }
      }
      if(error) {
        this.badAuthentication = true;
      }
    });
  }

  ngOnInit(): void {
    this.authService.user.subscribe((data) => {
      this.user = data;
    });
    if (this.checkToken() && !!this.user) {
      this.router.navigate(['./home']);
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  protected checkToken(): boolean {
    return (!!this.configService.fetchToken('accessToken') && !!this.user);
  }

  signInWithGoogle() {
    window.location.href = environment.googleAuthLink;
  }

  signInWithMicrosft() {
    window.location.href = environment.microsoftAuthLink;
  }
}
