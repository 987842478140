import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { DisplayAndEditEmployeeCompetencyDto } from "../models/display-and-edit-employee-competency.model";
import { DisplayAndEditProjectCompetencyDto } from "../models/display-and-edit-project-competency.model";
import {
  EmployeeDTOCompetencies,
  ProjectCompetencyDTO,
} from "../api/generated";
import { convertEmployeeCompetencies } from "../util/convert-employee-competencies";

@Injectable({
  providedIn: "root",
})
export class CompetencyManagerService {
  private readonly selectedEmployeeCompetencySubject =
    new BehaviorSubject<DisplayAndEditEmployeeCompetencyDto>(
      {} as DisplayAndEditEmployeeCompetencyDto
    );
  readonly selectedEmployeeCompetency =
    this.selectedEmployeeCompetencySubject.asObservable();

  private readonly selectedProjectCompetencySubject =
    new BehaviorSubject<DisplayAndEditProjectCompetencyDto>(
      {} as DisplayAndEditProjectCompetencyDto
    );
  readonly selectedProjectCompetency =
    this.selectedProjectCompetencySubject.asObservable();

  private readonly competencyWithUsagesUpdatedSubject = new Subject<void>();
  readonly competencyWithUsagesUpdated =
    this.competencyWithUsagesUpdatedSubject.asObservable();

  private readonly competencyWithUsagesDeletedSubject = new Subject<void>();
  readonly competencyWithUsagesDeleted =
    this.competencyWithUsagesDeletedSubject.asObservable();

  private readonly newCompetencyCreatedSubject = new Subject<void>();
  readonly newCompetencyCreated =
    this.newCompetencyCreatedSubject.asObservable();

  private readonly employeeCompetencyUpdatedSubject = new Subject<void>();
  readonly employeeCompetencyModified =
    this.employeeCompetencyUpdatedSubject.asObservable();

  get employeeCompetency(): Observable<DisplayAndEditEmployeeCompetencyDto> {
    return this.selectedEmployeeCompetency;
  }

  get projectCompetency(): Observable<DisplayAndEditProjectCompetencyDto> {
    return this.selectedProjectCompetency;
  }

  setEmployeeCompetency(
    displayOnly: boolean,
    employeeId: number,
    employeeName: string,
    competencyList: EmployeeDTOCompetencies
  ) {
    this.selectedEmployeeCompetencySubject.next({
      displayOnly,
      employeeId,
      employeeName,
      competencyList: convertEmployeeCompetencies(competencyList),
    });
  }

  updateEmployeeCompetency(
    displayOnly: boolean,
    employeeId: number,
    employeeName: string,
    competencyList: {
      id: number;
      level: number;
    }[]
  ) {
    this.selectedEmployeeCompetencySubject.next({
      displayOnly,
      employeeId,
      employeeName,
      competencyList: competencyList,
    });
  }

  clearBothCompetencies() {
    this.clearEmployeeCompetency();
    this.clearProjectCompetency();
  }

  clearEmployeeCompetency() {
    this.selectedEmployeeCompetencySubject.next(
      {} as DisplayAndEditEmployeeCompetencyDto
    );
  }

  setProjectCompetency(
    displayOnly: boolean,
    projectId: number,
    projectName: string,
    competencyList: ProjectCompetencyDTO[]
  ) {
    this.selectedProjectCompetencySubject.next({
      displayOnly,
      projectId,
      projectName,
      competencyList,
    });
  }

  clearProjectCompetency() {
    this.selectedProjectCompetencySubject.next(
      {} as DisplayAndEditProjectCompetencyDto
    );
  }

  get competencyUpdated(): Observable<void> {
    return this.competencyWithUsagesUpdated;
  }

  setCompetencyUpdated() {
    this.competencyWithUsagesUpdatedSubject.next(undefined);
  }

  get competencyCreated(): Observable<void> {
    return this.newCompetencyCreated;
  }

  setCompetencyCreated() {
    this.newCompetencyCreatedSubject.next(undefined);
  }

  get competencyDeleted(): Observable<void> {
    return this.competencyWithUsagesUpdated;
  }

  setCompetencyDeleted() {
    this.competencyWithUsagesUpdatedSubject.next(undefined);
  }

  get employeeCompetencyUpdated(): Observable<void> {
    return this.employeeCompetencyModified;
  }

  setEmployeeCompetencyUpdated() {
    this.employeeCompetencyUpdatedSubject.next(undefined);
  }
}
