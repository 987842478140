<td>
  <label
    class="text-overflow-dotted w-100"
    ngbTooltip="{{ profile?.employeeName }}"
    [openDelay]="300"
    placement="top"
    container="body"
    >{{ profile?.employeeName }}</label
  >
</td>
<td>
  <label
    class="text-overflow-dotted w-100"
    ngbTooltip="{{ profile?.employeeEmail }}"
    [openDelay]="300"
    placement="top"
    container="body"
    >{{ profile?.employeeEmail }}</label
  >
</td>
<td class="text-center">
  <select [(ngModel)]="profile!.done" (change)="saveProfile()">
    <option [value]="false">
      {{ "basket.employee_table.status.draft" | translate }}
    </option>
    <option [value]="true">
      {{ "basket.employee_table.status.done" | translate }}
    </option>
  </select>
</td>
<td class="text-center">
  <fa-icon
    data-testid="edit"
    class="edit-icon"
    [ngClass]="isModifyingDisabled() ? 'disabled' : ''"
    (click)="openModifyProfileModal()"
    placement="top"
    [ngbTooltip]="'basket.employee_table.not_editable' | translate"
    [disableTooltip]="!isModifyingDisabled()"
    [icon]="['fas', 'pen']"
  ></fa-icon>
</td>
<td class="text-center">
  <fa-icon
    data-testid="remove"
    class="remove-icon"
    [ngClass]="isModifyingDisabled() ? 'disabled' : ''"
    (click)="removeEmployee()"
    placement="top"
    [ngbTooltip]="'basket.employee_table.not_editable' | translate"
    [disableTooltip]="!isModifyingDisabled()"
    [icon]="['fas', 'trash']"
  ></fa-icon>
</td>
