import { HttpErrorResponse } from "@angular/common/http";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { finalize, Subscription } from "rxjs";
import {
  EmployeeDTO,
  EmployeeService,
  PositionDTO,
} from "src/shared/api/generated";
import { DisplayAndEditEmployeeCompetencyDto } from "src/shared/models/display-and-edit-employee-competency.model";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { convertEmployeeCompetencies } from "src/shared/util/convert-employee-competencies";
import { isDeepEqual } from "src/shared/util/is-deep-equal";

import { ReadOnlyProfileModalComponent } from "../../read-only-profile-modal/read-only-profile-modal.component";
import { EducationModalComponent } from "../education-modal/education-modal.component";
import { EmployeeProjectsModalComponent } from "../employee-projects-modal/employee-projects-modal.component";

@Component({
  selector: "[app-tr-employee]",
  templateUrl: "./tr-employee.component.html",
  styleUrls: ["./tr-employee.component.scss"],
})
export class TrEmployeeComponent implements OnInit, OnDestroy {
  @Input() employee: EmployeeDTO = {} as EmployeeDTO;
  @Input() positions: PositionDTO[] = [];
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<number, string> = new Map();
  @Input() createMode: boolean = false;

  @Output() onEmployeeSaveError = new EventEmitter<HttpErrorResponse>();
  @Output() onEducationSaved = new EventEmitter<void>();

  seniorities = EmployeeDTO.SeniorityEnum;

  editEmployee: EmployeeDTO = {} as EmployeeDTO;
  selectedCellEmployeeId: number | null = null;
  selectedProjectCellEmployeeId: number | null = null;

  editedCell: string = "";
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService,
    private modalService: NgbModal,
    private employeeService: EmployeeService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.resetEditedEmployee();
    if (this.createMode) {
      this.competencyManagerService.clearEmployeeCompetency();
    }
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          this.selectedCellEmployeeId = data.employeeId;
          if (data.employeeId && data.employeeId === this.employee.id) {
            this.employee.competencies = Object.fromEntries(
              data.competencyList.map((c) => [c.id, c.level])
            );
          }
        }
      )
    );
  }

  handleEmployeeUpdate() {
    if (this.createMode) return;

    if (
      isDeepEqual(this.editEmployee, this.employee) ||
      this.editEmployee.name.trim() === "" ||
      this.editEmployee.email.trim() === ""
    ) {
      this.setEditStateFalse();
      return;
    }

    this.updateEmployee({
      ...this.editEmployee,
      name: this.editEmployee.name.trim(),
      email: this.editEmployee.email.trim(),
      externalPartner: this.editEmployee.externalPartner?.trim(),
    });
    this.setEditStateFalse();
  }

  private updateEmployee(employee: EmployeeDTO): void {
    this.employeeService
      .updateEmployee(employee)
      .pipe(finalize(() => this.resetEditedEmployee()))
      .subscribe({
        next: (savedEmployee) => (this.employee = savedEmployee),
        error: (error) => this.onEmployeeSaveError.emit(error),
      });
  }

  setEditStateFalse() {
    if (this.createMode) {
      return;
    }
    this.resetEditedEmployee();
    this.editedCell = "";
  }

  setEditStateTrue(key: string) {
    if (this.createMode) {
      return;
    }

    if (key === "externalPartner" && this.employee.internal) {
      return;
    }

    this.editedCell = key;
  }

  resetEditedEmployee() {
    this.editEmployee = JSON.parse(JSON.stringify(this.employee));
  }

  selectCompetencyCell($event: MouseEvent) {
    this.inputClicked($event);
    if (!this.employee.id || this.createMode) {
      return;
    }
    if (this.employee.id === this.selectedCellEmployeeId) {
      this.competencyManagerService.clearEmployeeCompetency();
      return;
    }
    this.competencyManagerService.setEmployeeCompetency(
      false,
      this.employee.id,
      this.employee.name,
      this.employee.competencies
    );
  }

  isSearchResult(): boolean {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return (
      convertEmployeeCompetencies(this.employee.competencies).filter(
        (competency) => {
          return !!this.allCompetencies
            .get(competency.id)
            ?.toLowerCase()
            .includes(this.searchTerm?.toLowerCase() ?? "");
        }
      ).length !== 0
    );
  }

  isEditingDisabled(): boolean {
    if (this.employee.id && this.createMode) {
      return true;
    }
    return false;
  }

  openReadOnlyProfile(id: number | undefined) {
    if (id) {
      const modalRef = this.modalService.open(ReadOnlyProfileModalComponent, {
        windowClass: "profile-modal-window",
        scrollable: true,
      });
      modalRef.componentInstance.employeeId = id;
    }
  }

  openEducationModal() {
    if (!this.employee.id || this.createMode) {
      return;
    }

    const modalRef = this.modalService.open(EducationModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.employeeName = this.employee.name;
    modalRef.componentInstance.employeeId = this.employee.id;
    modalRef.closed.subscribe((result) => {
      if (result) this.onEducationSaved.emit();
    });
  }

  selectProjectCell($event: MouseEvent) {
    this.inputClicked($event);
    this.selectedProjectCellEmployeeId = this.employee.id!;
    const modalRef = this.modalService.open(EmployeeProjectsModalComponent, {
      windowClass: "custom-modal-window",
      keyboard: false,
      scrollable: true,
    });
    modalRef.componentInstance.employeeName = this.employee.name;
    modalRef.componentInstance.employeeId = this.employee.id;
    modalRef.componentInstance.projectIds =
      this.employee.currentAndFutureProjects ?? [];
    modalRef.closed.subscribe(
      () => (this.selectedProjectCellEmployeeId = null)
    );
    modalRef.dismissed.subscribe(
      () => (this.selectedProjectCellEmployeeId = null)
    );
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
