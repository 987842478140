import { Component, Input } from "@angular/core";
import { SideToolbarService } from "src/shared/services/side-toolbar.service";

@Component({
  selector: "app-side-toolbar",
  templateUrl: "./side-toolbar.component.html",
  styleUrl: "./side-toolbar.component.scss",
})
export class SideToolbarComponent {
  @Input() title: string = "";
  @Input() openingDisabled: boolean = false;
  isOpened: boolean = false;
  constructor(public sideToolbarService: SideToolbarService) {}

  ngOnInit(): void {
    this.sideToolbarService.isOpened.subscribe((isOpened) => {
      this.isOpened = isOpened;
    });
  }

  toggleSideToolbar() {
    if(!this.isOpened && this.openingDisabled) {
      return;
    }
    this.sideToolbarService.toggleSidebar();
  }
}
