import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  OperatorFunction,
  Subject,
  throwError,
} from "rxjs";
import {
  EmployeeSelectDTO,
  LookingForProjectService,
  UserSelectDTO,
} from "src/shared/api/generated";

@Component({
  selector: "app-create-looking-for-project-modal",
  templateUrl: "./create-looking-for-project-modal.component.html",
  styleUrls: ["./create-looking-for-project-modal.component.scss"],
})
export class CreateLookingForProjectModalComponent implements OnInit {
  @Input() employees: EmployeeSelectDTO[] = [];
  @Input() users: UserSelectDTO[] = [];

  error: boolean = false;
  errorMessage: string = "";

  lookingForProjectForm: FormGroup = new FormGroup({
    employee: new FormControl<EmployeeSelectDTO | null>(
      null,
      Validators.required
    ),
    assignee: new FormControl<UserSelectDTO | null>(null, Validators.required),
    freeFrom: new FormControl<string>("", Validators.required),
  });

  @ViewChild("employeeInstance", { static: true })
  employeeInstance: NgbTypeahead = {} as NgbTypeahead;
  @ViewChild("assigneeInstance", { static: true })
  assigneeInstance: NgbTypeahead = {} as NgbTypeahead;

  employeeFocus$ = new Subject<string>();
  assigneeFocus$ = new Subject<string>();
  employeeClick$ = new Subject<string>();
  assigneeClick$ = new Subject<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private lookingForProjectService: LookingForProjectService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {}

  closeAlert() {
    this.error = false;
    this.errorMessage = "";
  }

  saveNewEmployee(): void {
    this.error = false;
    this.errorMessage = "";

    if (this.isDisabled()) {
      this.lookingForProjectForm.markAllAsTouched();
      return;
    }

    this.lookingForProjectService
      .createLookingForProject({
        status: "WAITING",
        employeeId: this.employee.id!,
        assigneeId: this.assignee.id!,
        freeFrom: this.freeFrom,
      })
      .pipe(catchError((error) => this.handleEmployeeSaveError(error)))
      .subscribe((newLookingForProject) =>
        this.activeModal.close(newLookingForProject)
      );
  }

  private handleEmployeeSaveError(error: HttpErrorResponse): Observable<never> {
    if (error.status === HttpStatusCode.Conflict) {
      this.error = true;
      this.errorMessage = this.translate.instant(
        "home.looking_for_project.create_modal.error.already_has_active_entry"
      );
      return throwError(
        () =>
          new Error(
            this.translate.instant(
              "home.looking_for_project.create_modal.error.already_has_active_entry"
            )
          )
      );
    }
    return throwError(() => error.error);
  }

  searchEmployee: OperatorFunction<string, EmployeeSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.employeeClick$.pipe(
      filter(() => !this.employeeInstance.isPopupOpen())
    );
    const inputFocus$ = this.employeeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.employees.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };

  isDisabled(): boolean {
    return (
      !this.assignee ||
      this.assignee.id < 0 ||
      !this.employee ||
      this.employee?.id! < 0 ||
      !this.freeFrom
    );
  }

  searchAssignee: OperatorFunction<string, UserSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.assigneeClick$.pipe(
      filter(() => !this.assigneeInstance.isPopupOpen())
    );
    const inputFocus$ = this.assigneeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.users
          : this.users.filter((v) => new RegExp(term, "miu").test(v.name!))
        )
          .sort((a, b) => a.name!.localeCompare(b.name!))
          .slice(0, 10)
      )
    );
  };

  formatter = (x: EmployeeSelectDTO | UserSelectDTO) => x.name!;

  get employee() {
    return this.lookingForProjectForm.get("employee")?.value;
  }

  get assignee() {
    return this.lookingForProjectForm.get("assignee")?.value;
  }

  get freeFrom() {
    return this.lookingForProjectForm.get("freeFrom")?.value;
  }
}
