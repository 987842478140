import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import {
  Observable,
  OperatorFunction,
  Subject,
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  merge,
  throwError,
} from "rxjs";
import {
  LookingForResourceService,
  ProjectSelectDTO,
  ProjectService,
  UserSelectDTO,
} from "src/shared/api/generated";
import { errorMessage } from "src/shared/constant/error-message.constant";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-create-looking-for-resource-modal",
  templateUrl: "./create-looking-for-resource-modal.component.html",
  styleUrls: ["./create-looking-for-resource-modal.component.scss"],
})
export class CreateLookingForResourceModalComponent implements OnInit {
  @Input() users: UserSelectDTO[] = [];
  @Input() error: boolean = false;
  @Input() errorMessage: string = "";

  lookingForResourceForm: FormGroup = new FormGroup({
    project: new FormControl<ProjectSelectDTO | null>(
      null,
      Validators.required
    ),
    assignee: new FormControl<UserSelectDTO | null>(null, Validators.required),
    requestStart: new FormControl<string | undefined>(undefined),
    requestEnd: new FormControl<string | undefined>(undefined),
    fte: new FormControl<number>(0, Validators.required),
  });

  projects: ProjectSelectDTO[] = [];

  @ViewChild("projectInstance", { static: true })
  projectInstance: NgbTypeahead = {} as NgbTypeahead;
  @ViewChild("assigneeInstance", { static: true })
  assigneeInstance: NgbTypeahead = {} as NgbTypeahead;

  projectFocus$ = new Subject<string>();
  assigneeFocus$ = new Subject<string>();
  projectClick$ = new Subject<string>();
  assigneeClick$ = new Subject<string>();

  constructor(
    public activeModal: NgbActiveModal,
    private projectService: ProjectService,
    private spinnerService: NgxSpinnerService,
    private lookingForResourceService: LookingForResourceService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.getProjects();
  }

  getProjects(): void {
    this.projectService
      .getAllProjectSelectDtos()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((allProjects) => {
        this.projects = allProjects;
      });
  }

  closeAlert(): void {
    this.error = false;
    this.errorMessage = "";
  }

  saveNewLookingForResource(): void {
    this.error = false;
    this.errorMessage = "";

    if (this.isDisabled()) {
      this.lookingForResourceForm.markAllAsTouched();
      return;
    }

    const { requestStart, requestEnd, fte } = this.lookingForResourceForm.value;
    this.lookingForResourceService
      .createLookingForResource({
        status: "WAITING",
        projectId: this.project.id!,
        assigneeId: this.assignee.id!,
        requestStart,
        requestEnd,
        fte,
      })
      .pipe(catchError((error) => this.handleLookingForResourceSave(error)))
      .subscribe((newLookingForResource) =>
        this.activeModal.close(newLookingForResource)
      );
  }

  private handleLookingForResourceSave(
    error: HttpErrorResponse
  ): Observable<never> {
    if (error.error.message === errorMessage.lookingForResourceAlreadyExists) {
      this.error = true;
      this.errorMessage = this.translate.instant(
        "home.looking_for_resources.create_modal.error.already_has_active_entry"
      );
      return throwError(
        () =>
          new Error(
            this.translate.instant(
              "home.looking_for_resources.create_modal.error.already_has_active_entry"
            )
          )
      );
    }
    return throwError(() => error.error);
  }

  isDisabled(): boolean {
    return (
      !this.project ||
      this.project.id < 0 ||
      !this.assignee ||
      this.assignee.id < 0 ||
      this.fte === null
    );
  }

  searchProject: OperatorFunction<string, ProjectSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.projectClick$.pipe(
      filter(() => !this.projectInstance.isPopupOpen())
    );
    const inputFocus$ = this.projectFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.projects.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };

  searchAssignee: OperatorFunction<string, UserSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.assigneeClick$.pipe(
      filter(() => !this.assigneeInstance.isPopupOpen())
    );
    const inputFocus$ = this.assigneeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? this.users
          : this.users.filter((v) => new RegExp(term, "miu").test(v.name!))
        )
          .sort((a, b) => a.name!.localeCompare(b.name!))
          .slice(0, 10)
      )
    );
  };

  formatter = (x: ProjectSelectDTO | UserSelectDTO) => x.name!;

  get project() {
    return this.lookingForResourceForm.get("project")?.value;
  }

  get assignee() {
    return this.lookingForResourceForm.get("assignee")?.value;
  }

  get fte() {
    return this.lookingForResourceForm.get("fte")?.value;
  }
}
