import { Component, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BasketDTO, BasketService, ProfileDTO } from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { CreateBasketModalComponent } from "./create-basket-modal/create-basket-modal.component";

@Component({
  selector: "app-basket",
  templateUrl: "./basket.component.html",
  styleUrls: ["./basket.component.scss"],
})
export class BasketComponent {
  @Input() profileExportQueue: ProfileDTO[] = [];
  allBaskets: BasketDTO[] = [];
  editedBasket: BasketDTO = {} as BasketDTO;

  constructor(
    private basketService: BasketService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    private confirmationModalService: ConfirmationModalService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.refreshBaskets();
  }

  refreshBaskets() {
    this.basketService
      .getAllBaskets()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((result) => {
        this.allBaskets = result;
      });
  }

  onDeleteBasket(basketId: number) {
    this.confirmationModalService
      .openConfirmationModal(
        "basket.confirm_delete",
        "basket.delete_dialog_message"
      )
      .then(
        (result) => {
          if (result) {
            this.deleteBasket(basketId);
          }
        },
        () => {}
      );
  }

  deleteBasket(basketId: number) {
    this.basketService.deleteBasket(basketId).subscribe(() => {
      this.refreshBaskets();
    });
  }

  openNewBasketModal() {
    const modalRef = this.modalService.open(CreateBasketModalComponent);
    modalRef.result.then(
      (result) => {
        if (result) {
          this.refreshBaskets();
        }
      },
      () => {}
    );
  }

  onStartEditing(basket: BasketDTO) {
    this.editedBasket = { ...basket };
  }

  onSaveEditing() {
    this.editedBasket.name = this.editedBasket.name.trim();
    this.basketService.updateBasket(this.editedBasket).subscribe(() => {
      this.refreshBaskets();
      this.editedBasket = {} as BasketDTO;
    });
  }

  onCancelEditing() {
    this.editedBasket = {} as BasketDTO;
  }

  onProfileRemovedFromBasket(basket: BasketDTO) {
    this.allBaskets.find((b) => b.id === basket.id)!.profiles = basket.profiles;
  }

  onProfileUpdated(value: { basketId: number; profiles: ProfileDTO[] }) {
    this.allBaskets.find((b) => b.id === value.basketId)!.profiles =
      value.profiles;
  }
}
