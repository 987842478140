/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CompetencyDTO } from './competencyDTO';


export interface PlanEmployeeProjectDTO { 
    planId: number;
    employeeId: number;
    employeeName: string;
    projectId: number;
    projectName: string;
    description: string;
    fte: string;
    startDate: string;
    endDate: string;
    competencies: Array<CompetencyDTO>;
}

