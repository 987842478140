<div class="modal-content">
  <div class="modal-header">
    <ng-content select="[modal-title]">
      <div class="base-modal-title-container">
        <fa-icon *ngIf="titleIcon" [icon]="['fas', titleIcon]"></fa-icon>
        <span class="modal-title base-modal-title h-100" id="modal-title">
          {{ title }}
        </span>
        <span
          *ngIf="secondaryTitle && title"
          class="modal-title base-modal-title h-100 mx-2"
          id="modal-title-separator"
        >
          -
        </span>
        <span
          class="modal-title base-modal-secondary-title h-100"
          id="modal-secondary-title"
        >
          {{ secondaryTitle }}
        </span>
      </div>
    </ng-content>
    <button
      *ngIf="showXButton"
      type="button"
      aria-label="Dismiss"
      class="btn-close btn-close-white ms-auto"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <ng-content></ng-content>
  </div>
  <div class="modal-footer">
    <ng-content select="[modal-actions]"></ng-content>
  </div>
</div>
