export const errorMessage = {
  competencyAlreadyExists: "Competency already exists with the name",
  lookingForProjectAlreadyExists:
    "There is already an active Looking For Project for the given employee",
  lookingForResourceAlreadyExists:
    "There is already an active Looking For Resource for the given project",
  planOverlaps:
    "Plan overlaps with another plan for the given employee and project",
  projectAlreadyExists: "Project already exists with the given name",
  employeeAlreadyExists: "Employee already exists with the same email address",
  competencyAlreadyInUse:
    "Competency cannot be deleted because it's already in use.",
  invalidEmailFormat: "Invalid email format",
};
