<div class="d-flex w-100 h-100 base-container">
  <div class="content mw-100 flex-grow-1 pt-3 d-flex flex-column">
    <div class="title-container">
      <img
        src="..\..\assets\img\squirrel_standing_tie.png"
        class="title-squirrel-image"
      />
      <h1 class="title">{{ "project.title" | translate }}</h1>
    </div>
    <app-company-separated-display
      [companies]="this.visibleCompanies"
      class="flex-grow-1 d-flex pb-3 flex-column tabs"
      (companyAddedEvent)="refreshCompanies()"
    >
      <ng-template companyMarker *ngFor="let company of visibleCompanies">
        <app-table-project
          [company]="company"
          class="project-table h-100"
        ></app-table-project>
      </ng-template>
    </app-company-separated-display>
  </div>
  <div class="competency">
    <app-side-toolbar [openingDisabled]="competencySidebarDisabled" [title]="'competency.competencies' | translate">
      <app-competency>
        <app-project-competency class="competencies"></app-project-competency>
      </app-competency>
    </app-side-toolbar>
  </div>
</div>
