import { Component, ViewChild } from "@angular/core";
import { NgbAccordionDirective, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin, Subscription } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  CompetencyGroupService,
  CompetencyService,
  EmployeeCompetencyDTO,
  EmployeeCompetencyService,
} from "src/shared/api/generated";
import { DisplayAndEditEmployeeCompetencyDto } from "src/shared/models/display-and-edit-employee-competency.model";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { PermissionService } from "src/shared/services/permission.service";

import { TranslateService } from "@ngx-translate/core";
import { EmployeeCompetencyModalComponent } from "../competency-modal/employee-competency-modal/employee-competency-modal.component";

@Component({
  selector: "app-employee-competency",
  templateUrl: "./employee-competency.component.html",
  styleUrls: ["./employee-competency.component.scss"],
})
export class EmployeeCompetencyComponent {
  @ViewChild("employeeCompetencyAccordion")
  employeeCompetencyAccordion!: NgbAccordionDirective;
  searchText: string = "";
  errorFetchingCompetencies: boolean = false;
  editEmployeeCompetenciesMode: boolean = false;
  editedEmployeeCompetency: DisplayAndEditEmployeeCompetencyDto =
    {} as DisplayAndEditEmployeeCompetencyDto;
  levelEditedEmployeeCompetency: { id: number; level: number } | null = null;
  competencyGroups: CompetencyGroupDTO[] = [];
  selectableCompetencyGroups: CompetencyGroupDTO[] = [];

  public competencies: CompetencyDTO[] = [];
  public employeeCompetencies: { competency: CompetencyDTO; level: number }[] =
    [];
  public filteredEmployeeCompetencies: {
    competency: CompetencyDTO;
    level: number;
  }[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private competencyService: CompetencyService,
    private competencyManagerService: CompetencyManagerService,
    private competencyGroupService: CompetencyGroupService,
    private employeeCompetencyService: EmployeeCompetencyService,
    private spinnerService: NgxSpinnerService,
    private confirmationModalService: ConfirmationModalService,
    public permissionService: PermissionService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          if (this.editedEmployeeCompetency.employeeId !== data.employeeId) {
            this.clearEditing();
          }
          this.editedEmployeeCompetency = data;
          if (data.employeeId) {
            this.filterCompetencyArrays(this.competencies);
            this.editEmployeeCompetenciesMode = true;
          } else {
            this.editEmployeeCompetenciesMode = false;
            this.clearEditing();
          }
        }
      )
    );
  }

  initialLoad() {
    forkJoin([
      this.getRootCompetencyGroups(),
      this.getSelectableCompetencyGroups(),
      this.competencyService.getAllCompetencies(),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: ([rootGroups, selectableGroups, competencies]) => {
          this.competencyGroups = rootGroups;
          this.selectableCompetencyGroups = selectableGroups;
          this.filterCompetencyArrays(competencies);
        },
        error: () => (this.errorFetchingCompetencies = true),
      });
  }

  getRootCompetencyGroups() {
    return this.competencyGroupService.getAllRootCompetencyGroups();
  }

  getSelectableCompetencyGroups() {
    return this.competencyGroupService.getAllSelectableCompetencyGroups();
  }
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearchInput() {
    const searchTerm = this.searchText.trim().toLowerCase();
    if (searchTerm === "") {
      this.filteredEmployeeCompetencies = this.employeeCompetencies;
    } else {
      this.filteredEmployeeCompetencies = this.employeeCompetencies.filter(
        (competency) =>
          competency.competency.name.toLowerCase().includes(searchTerm)
      );

      this.expandCategoriesWithSearchResult();
    }
  }

  collapseAllCategories() {
    this.employeeCompetencyAccordion.collapseAll();
  }

  expandCategoriesWithSearchResult() {
    for (let type of this.competencyGroups) {
      this.expandCompetencyCategories(type);
    }
  }

  private expandCompetencyCategories(type: CompetencyGroupDTO) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.filteredEmployeeCompetencies,
      this.employeeCompetencyAccordion
    );
  }

  expandCompetencyCategoriesWithSearchResult(
    type: CompetencyGroupDTO,
    competencies: { competency: CompetencyDTO; level: number }[],
    accordion: NgbAccordionDirective
  ) {
    const filteredCompetenciesNumber = this.getCompetenciesInCategoryFromArray(
      competencies,
      type.id
    ).length;
    if (filteredCompetenciesNumber !== 0) {
      accordion.expand(type.name);
    } else {
      accordion.collapse(type.name);
    }
  }

  public getCompetencies(): void {
    this.competencyService
      .getAllCompetencies()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (response) => {
          this.filterCompetencyArrays(response);
        },
        error: () => {
          this.errorFetchingCompetencies = true;
        },
      });
  }

  filterCompetencyArrays(competencyArray: CompetencyDTO[]) {
    this.competencies = [...competencyArray].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    if (this.editedEmployeeCompetency.employeeId) {
      this.employeeCompetencies =
        this.editedEmployeeCompetency.competencyList.map((obj) => ({
          competency: this.competencies.find((c) => c.id === obj.id)!,
          level: obj.level,
        }));

      this.filteredEmployeeCompetencies = this.employeeCompetencies;
    }
    this.onSearchInput();
    this.errorFetchingCompetencies = false;
  }

  deleteEditedCompetency(competency: {
    competency: CompetencyDTO;
    level: number;
  }) {
    if (this.editedEmployeeCompetency.employeeId) {
      this.deleteEmployeeCompetency(competency);
    }
  }

  deleteEmployeeCompetency(competency: {
    competency: CompetencyDTO;
    level: number;
  }) {
    if (
      this.editedEmployeeCompetency.displayOnly ||
      this.levelEditedEmployeeCompetency
    ) {
      return;
    }
    this.employeeCompetencyService
      .deleteEmployeeCompetency({
        employeeId: this.editedEmployeeCompetency.employeeId!,
        competencyId: competency.competency.id!,
        level: competency.level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (_) => {
          this.refreshEmployeeCompetenciesAfterDelete(competency.competency);
          this.competencyManagerService.setEmployeeCompetencyUpdated();
        },
        error: (error) => {
          if (
            error.error.message ===
            "Unable to remove competency from employee because this competency is connected to at least one of the employee's plans"
          ) {
            this.confirmationModalService.openConfirmationModal(
              "employee.error.title",
              "employee.error.competency_already_planned",
              true
            );
          }
        },
      });
  }

  refreshEmployeeCompetenciesAfterDelete(competency: CompetencyDTO) {
    this.employeeCompetencies = this.filteredEmployeeCompetencies;
    this.editedEmployeeCompetency.competencyList =
      this.editedEmployeeCompetency.competencyList.filter(
        (c) => c.id !== competency.id
      );
    this.refreshEditedEmployeeCompetency();
  }

  getRootGroupName(id: number) {
    return this.competencyGroups.find((c) => c.id === id)!.name;
  }

  onStartEditingLevel(competency: CompetencyDTO) {
    this.levelEditedEmployeeCompetency = {
      id: competency.id!,
      level: this.getCompetencyLevel(competency.id!)!,
    };
  }

  onSaveLevelEditing() {
    this.saveEditedEmployeeCompetency();
  }

  openLevelEditingErrorModal(error: any) {
    if (error.error.message === "Level cannot be less than 1") {
      this.confirmationModalService.openConfirmationModal(
        "employee.error.title",
        "employee.error.competency_less_than_1",
        true
      );
    } else if (error.error.message === "Level cannot be more than 5") {
      this.confirmationModalService.openConfirmationModal(
        "employee.error.title",
        "employee.error.competency_more_than_5",
        true
      );
    }
  }

  saveEditedEmployeeCompetency() {
    this.employeeCompetencyService
      .updateEmployeeCompetency({
        employeeId: this.editedEmployeeCompetency.employeeId!,
        competencyId: this.levelEditedEmployeeCompetency!.id,
        level: this.levelEditedEmployeeCompetency!.level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (updatedEmployeeCompetency) => {
          this.editedEmployeeCompetency.competencyList.find(
            (item) => item.id === updatedEmployeeCompetency.competencyId
          )!.level = updatedEmployeeCompetency.level;
          this.refreshEditedEmployeeCompetency();
          this.levelEditedEmployeeCompetency = null;
          this.competencyManagerService.setEmployeeCompetencyUpdated();
        },
        error: (error) => {
          this.openLevelEditingErrorModal(error);
        },
      });
  }

  onCancelLevelEditing() {
    this.levelEditedEmployeeCompetency = null;
  }

  refreshEditedEmployeeCompetency() {
    this.competencyManagerService.updateEmployeeCompetency(
      this.editedEmployeeCompetency.displayOnly,
      this.editedEmployeeCompetency.employeeId!,
      this.editedEmployeeCompetency.employeeName!,
      this.editedEmployeeCompetency.competencyList
    );
  }

  getCompetencyLevel(id: number) {
    if (!this.editedEmployeeCompetency.employeeId) {
      return -1;
    }
    return this.editedEmployeeCompetency.competencyList.find((c) => c.id === id)
      ?.level;
  }

  clearEditing() {
    this.onCancelLevelEditing();
  }

  getCompetenciesInCategoryFromArray(
    array: { competency: CompetencyDTO; level: number }[],
    typeId: number
  ): { competency: CompetencyDTO; level: number }[] {
    return array
      .filter((c) => c.competency.competencyGroup.rootGroupId === typeId)
      .sort(
        (a, b) =>
          b.level - a.level ||
          a.competency.name.localeCompare(b.competency.name)
      );
  }

  openAssignCompetencyModal(): void {
    const modalRef = this.modalService.open(EmployeeCompetencyModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.allCompetencies = this.competencies;
    modalRef.componentInstance.assignedCompetencies =
      this.employeeCompetencies.map((competency) => {
        return { level: competency.level, id: competency.competency.id };
      });
    modalRef.componentInstance.competencyGroups = this.competencyGroups;
    modalRef.componentInstance.selectableCompetencyGroups =
      this.selectableCompetencyGroups;
    modalRef.componentInstance.employeeId =
      this.editedEmployeeCompetency.employeeId;
    modalRef.closed.subscribe(
      (result: {
        isNewCompetency: boolean;
        isNewAssignedCompetency: boolean;
        competencies: EmployeeCompetencyDTO[] | null;
      }) => {
        if (result.isNewCompetency) {
          this.getCompetencies();
        }
        if (result.isNewAssignedCompetency && result.competencies) {
          this.editedEmployeeCompetency.competencyList = [
            ...this.editedEmployeeCompetency.competencyList,
            ...result.competencies.map((competency) => {
              return { id: competency.competencyId, level: competency.level };
            }),
          ];
          this.refreshEditedEmployeeCompetency();
          this.competencyManagerService.setEmployeeCompetencyUpdated();
        }
      }
    );
  }
}
