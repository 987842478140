<app-base-modal
  [title]="'settings.role_management.role_add_modal.create_role' | translate"
  [activeModal]="activeModal"
>
  <div class="content">
    <form class="form" [formGroup]="positionForm">
      <div class="form-row">
        <label for="position-type-input" class="row-label">
          {{ "settings.role_management.role_add_modal.role_type" | translate }}:
        </label>
        <div class="wrapper w-100">
          <input
            id="position-type-input"
            type="text"
            formControlName="type"
            class="w-50"
            (blur)="trimText(positionForm.get('type')?.value)"
          />
          <span
            *ngIf="
              positionForm.get('type')?.hasError('required') &&
              positionForm.get('type')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="error" class="error mt-4">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
      {{ errorMessage }}
    </ngb-alert>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="savePosition()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
