import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { catchError, Observable, throwError } from "rxjs";
import {
  PositionDTO,
  PositionService,
} from "src/shared/api/generated";

@Component({
  selector: "app-add-or-edit-position-modal",
  templateUrl: "./add-or-edit-position-modal.component.html",
  styleUrls: ["./add-or-edit-position-modal.component.scss"],
})
export class AddOrEditPositionModalComponent {
  @Input() position!: PositionDTO;
  @Input() createMode: boolean = false;

  error: boolean = false;
  errorMessage: string = "";
  positionForm!: FormGroup;

  ngOnInit(): void {
    this.positionForm = new FormGroup({
      id: new FormControl<number | undefined>(this.position.id),
      type: new FormControl<string>(this.position.type, Validators.required),
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private positionService: PositionService,
    private translate: TranslateService
  ) { }

  closeAlert(): void {
    this.error = false;
    this.errorMessage = "";
  }

  savePosition(): void {
    if (this.positionForm.invalid) {
      this.positionForm.markAllAsTouched();
      return;
    }
    const positionToSave: PositionDTO =
      this.positionForm.value;
    const positionObservable = this.createMode
      ? this.positionService.createPosition(positionToSave)
      : this.positionService.updatePosition(positionToSave);

    positionObservable
      .pipe(catchError((error) => this.handlePositionSaveError(error)))
      .subscribe((position) => {
        this.activeModal.close(position);
      });
  }

  trimText(value: string): void {
    this.positionForm
      .get("type")
      ?.setValue(value.trim(), { emitEvent: false });
  }

  private handlePositionSaveError(
    error: HttpErrorResponse
  ): Observable<never> {
    if (error.status === HttpStatusCode.BadRequest) {
      this.error = true;
      this.errorMessage = this.translate.instant(
        "settings.role_management.role_add_modal.already_exists"
      );
      return throwError(
        () =>
          new Error(
            this.translate.instant(
              "settings.role_management.role_add_modal.already_exists"
            )
          )
      );
    }
    console.error("Position could not be saved", error);

    return throwError(() => error.error);
  }
}
