import { Injectable } from "@angular/core";
import { UserConfigControllerService, UserConfigDTO } from "../api/generated";
import { AuthService } from "./auth.service";
import { BehaviorSubject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { Theme, USER_CONFIG_KEY } from "src/shared/constant/user-config.constants";

@Injectable({
  providedIn: "root",
})
export class UserConfigStoreService {
  private _userConfigDto = new BehaviorSubject<UserConfigDTO | null>(null);
  public userConfigDto$ = this._userConfigDto.asObservable();

  constructor(
    private userConfigService: UserConfigControllerService,
    private authService: AuthService,
    private translate: TranslateService
  ) {
    this.userConfigDto = JSON.parse(localStorage.getItem(USER_CONFIG_KEY) || '{}');
    translate.use((this.userConfigDto?.jsonConfig as any)?.language);
    this.applyTheme((this.userConfigDto?.jsonConfig as any)?.theme);
  }
  
  get userConfigDto(): UserConfigDTO | null {
    return this._userConfigDto.value;
  }

  set userConfigDto(config: UserConfigDTO | null) {
    this._userConfigDto.next(config);
  }

  public loadUserConfig() {
    let currentUser = this.authService.user.getValue().username;
    this.userConfigService.getUserConfigForUser(currentUser).subscribe({
      next: (response) => {
        this.userConfigDto = response;
        localStorage.setItem(USER_CONFIG_KEY, JSON.stringify(response));
        const language = (response.jsonConfig as any).language;
        const theme = (response.jsonConfig as any).theme;
        this.translate.use(language);
        this.applyTheme(theme);
      },
      error: (error) => console.error(error),
    });
  }

  private updateUserConfig() {
    const currentUser = this.authService.user.getValue().username;
    const currentConfig = this.userConfigDto;

    if (!currentConfig || !currentConfig.jsonConfig) {
      console.error("No user config available to update.");
      return;
    }
    localStorage.setItem(USER_CONFIG_KEY, JSON.stringify(currentConfig));
    this.userConfigService.updateConfig(currentUser, currentConfig.jsonConfig).subscribe({
      next: (response) => this._userConfigDto.next(response),
      error: (error) => console.error("Error updating config: ", error)
    });
  }

  public setLanguage(language: string): void {
    if (this.userConfigDto && this.userConfigDto.jsonConfig) {
      (this.userConfigDto.jsonConfig as any).language = language;
      this.translate.use(language);
      this.updateUserConfig();
    }
  }

  public setTheme(theme: string): void {
    if (this.userConfigDto && this.userConfigDto.jsonConfig) {
      (this.userConfigDto.jsonConfig as any).theme = theme;
      this.applyTheme(theme);
      this.updateUserConfig();
    }
  }

  private applyTheme(theme: string) {
    if (theme === Theme.DARK) {
      document.body.classList.add('dark-theme');
      document.documentElement.setAttribute('data-bs-theme', Theme.DARK);
    } else {
      document.body.classList.remove('dark-theme');
      document.documentElement.setAttribute('data-bs-theme', Theme.LIGHT);
    }
  }
}
