<div class="content">
  <form class="form" [formGroup]="addMemberForm">
    <div class="form-row">
      <label for="employee" class="row-label">
        {{ "plan.create_modal.employee" | translate }}
      </label>
      <ng-template #resultTemplate let-r="result" let-t="term">
        <ngb-highlight
          class="typeahead-highlight me-1"
          [result]="r.name"
          [term]="t"
        ></ngb-highlight>
      </ng-template>
      <div class="wrapper">
        <input
          id="employee"
          type="text"
          formControlName="selectedEmployee"
          [ngbTypeahead]="searchEmployee"
          [resultTemplate]="resultTemplate"
          [inputFormatter]="inputFormatter"
          [editable]="false"
          container="body"
          (focus)="employeeFocus$.next($any($event).target.value)"
          (click)="employeeFocus$.next($any($event).target.value)"
          (selectItem)="fetchCompetencies($event)"
          #employeeInstance="ngbTypeahead"
        />
        <span
          *ngIf="
            addMemberForm.get('selectedEmployee')?.hasError('required') &&
            addMemberForm.get('selectedEmployee')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="start" class="row-label">{{
        "plan.create_modal.start" | translate
      }}</label>
      <div class="wrapper">
        <input id="start" formControlName="from" type="date" />
        <span
          *ngIf="
            addMemberForm.get('from')?.hasError('required') &&
            addMemberForm.get('from')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label for="end" class="row-label">{{
        "plan.create_modal.end" | translate
      }}</label>
      <div class="wrapper">
        <input id="end" formControlName="to" type="date" />
        <span
          *ngIf="
            addMemberForm.get('to')?.hasError('required') &&
            addMemberForm.get('to')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
        <span *ngIf="addMemberForm.hasError('invalidDate')" class="form-error">
          {{ "plan.create_modal.error.to_earlier_than_from" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label class="row-label">{{
        "plan.create_modal.percentage" | translate
      }}</label>
      <div class="wrapper">
        <div class="range-input-wrapper">
          <input
            formControlName="percentage"
            type="range"
            min="0"
            max="100"
            step="5"
            [value]="addMemberForm.value.percentage"
          />
          <input
            formControlName="percentage"
            type="number"
            min="0"
            max="100"
            class="percentage-number-input"
            [value]="addMemberForm.value.percentage"
            (blur)="validatePercentageField()"
          />
        </div>
        <span
          *ngIf="
            addMemberForm.get('percentage')?.hasError('min') &&
            addMemberForm.get('percentage')?.touched
          "
          class="form-error"
        >
          {{ "form.error.percentage_min_value" | translate }}
        </span>
        <span
          *ngIf="
            addMemberForm.get('percentage')?.hasError('required') &&
            addMemberForm.get('percentage')?.touched
          "
          class="form-error"
        >
          {{ "form.error.required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-row">
      <label class="row-label">{{
        "plan.create_modal.description" | translate
      }}</label>
      <div class="wrapper">
        <textarea
          class="px-2"
          rows="4"
          placeholder="{{ 'plan.create_modal.placeholder' | translate }}"
          formControlName="description"
        ></textarea>
      </div>
    </div>
    <div class="form-row">
      <label class="row-label">{{
        "plan.create_modal.competency" | translate
      }}</label>
      <div class="w-50">
        <app-competency-pill-container
          [competencies]="competencies"
          [allCompetencies]="projectCompetencies"
          [employeeCompetencies]="employeeCompetencies"
          [commands]="commands"
          [isAddPillButtonActive]="true"
          [isAddDisabled]="!selectedEmployee || selectedEmployee?.id! < 0"
          (newCompetencyEvent)="checkAndAddNewCompetency($event)"
        ></app-competency-pill-container>
      </div>
    </div>
  </form>
</div>
<div *ngIf="error" class="mt-4 error">
  <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
    {{ errorMessage }}
  </ngb-alert>
</div>
