import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import {
  CompetencyDTO,
  CompetencyService,
  LookingForResourceDTO,
  LookingForResourceService,
  UserSelectDTO,
  UserService,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";

import { CreateLookingForResourceModalComponent } from "../create-looking-for-resource-modal/create-looking-for-resource-modal.component";

@Component({
  selector: "app-table-looking-for-resource",
  templateUrl: "./table-looking-for-resource.component.html",
  styleUrls: ["./table-looking-for-resource.component.scss"],
})
export class TableLookingForResourceComponent implements OnInit, OnDestroy {
  lookingForResourceEntities: LookingForResourceDTO[] = [];
  users: UserSelectDTO[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "project.name", direction: "asc" };
  selectedRow: number = -1;
  searchText = new FormControl("");
  search: boolean = false;
  competencies: Map<number, string> = new Map();

  dataLoaded: boolean = false;

  displayedLookingForResources = "active";

  subscriptions: Subscription = new Subscription();

  constructor(
    private spinnerService: NgxSpinnerService,
    private lookingForResourceService: LookingForResourceService,
    private userService: UserService,
    private competencyService: CompetencyService,
    private competencyManagerService: CompetencyManagerService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.searchLookingForResources();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  initialLoad() {
    forkJoin([
      this.getUsers(),
      this.getAllCompetencies(),
      this.getLookingForResourceEntities(),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(([users, competencies, lookingForResourceEntities]) => {
        this.users = users;
        competencies.forEach((competency: CompetencyDTO) => {
          this.competencies.set(competency.id!, competency.name);
        });
        this.selectedRow - 1;
        this.lookingForResourceEntities =
          lookingForResourceEntities.content ?? [];
        this.collectionSize = lookingForResourceEntities.totalElements ?? 0;
        this.dataLoaded = true;
      });
  }

  getLookingForResourceEntities(searchTerm: string = "") {
    this.competencyManagerService.clearBothCompetencies();
    if (this.displayedLookingForResources === "active") {
      return this.lookingForResourceService.getAllActiveLookingForResources(
        searchTerm.trim(),
        this.pageSize,
        this.page - 1,
        this.sorting.column,
        this.sorting.direction
      );
    }
    return this.lookingForResourceService.getAllLookingForResources(
      searchTerm.trim(),
      this.pageSize,
      this.page - 1,
      this.sorting.column,
      this.sorting.direction
    );
  }

  getUsers() {
    return this.userService.getAllUsers();
  }

  getAllCompetencies() {
    return this.competencyService.getAllCompetencies();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshLookingForResources();
  }

  searchLookingForResources() {
    this.searchText.valueChanges
      .pipe(
        tap(() => (this.search = false)),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.competencyManagerService.clearEmployeeCompetency();
        }),
        switchMap((text) =>
          this.getLookingForResourceEntities(text ?? "").pipe(
            showLoadingSpinner(this.spinnerService)
          )
        )
      )
      .subscribe((lookingForResourcePage) => {
        this.selectedRow - 1;
        this.lookingForResourceEntities = lookingForResourcePage.content ?? [];
        this.collectionSize = lookingForResourcePage.totalElements ?? 0;
        this.search = true;
      });
  }

  toggleDisplayMode() {
    this.page = 1;
    this.refreshLookingForResources();
  }

  refreshLookingForResources() {
    this.getLookingForResourceEntities(this.searchText.value ?? "")
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((lookingForResourcePage) => {
        this.selectedRow - 1;
        this.lookingForResourceEntities = lookingForResourcePage.content ?? [];
        this.collectionSize = lookingForResourcePage.totalElements ?? 0;
      });
  }

  openAddProjectDialog() {
    const modalRef = this.modalService.open(
      CreateLookingForResourceModalComponent,
      {
        windowClass: "custom-modal-window",
        scrollable: true,
      }
    );
    modalRef.componentInstance.users = this.users;

    modalRef.result.then(
      (result) => {
        if (result) {
          this.refreshLookingForResources();
        }
      },
      () => {}
    );
  }

  selectRow(id: number) {
    if (this.selectedRow === id) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = id;
    }
  }
}
