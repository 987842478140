<div class="d-flex flex-column justify-content-between h-100">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="d-flex flex-row justify-content-between table-actions">
      <div class="flex-grow-1 d-flex flex-row align-items-center search">
        <fa-icon
          class="ms-auto remove-icon"
          [icon]="['fas', 'magnifying-glass']"
        ></fa-icon>
        <input
          class="form-control form-control-sm flex-grow-1"
          type="text"
          placeholder="{{
            'settings.role_management.search' | translate
          }}"
          [formControl]="searchText"
        />
      </div>
      <div>
        <button class="btn primary-btn ms-2" (click)="openCreateModal()">
          {{ "settings.role_management.add" | translate }}
        </button>
      </div>
    </div>

    <div class="table-container">
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              sortable="type"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "settings.role_management.name" | translate }}
            </th>
            <th class="table-header text-center delete"></th>
          </tr>
        </thead>
        <tbody>
          @for (position of positions; track position.id) {
          <tr
            app-tr-role-management
            [position]="position"
            (positionDeleted)="refreshPositions()"
            (positionAlreadyExists)="showErrorToast = true"
          ></tr>
          }
        </tbody>
      </table>
    </div>

    <div
      *ngIf="positions.length || searchText.value?.trim()?.length"
      class="d-flex justify-content-between align-items-top p-2"
    >
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        [ellipses]="true"
        [maxSize]="15"
        (pageChange)="refreshPositions()"
        class="pages"
      >
      </ngb-pagination>
      <div class="information">
        {{ positions.slice(0, pageSize).length }} /
        {{ collectionSize }}
      </div>
    </div>
  </div>
  <ngx-spinner size="large" type="ball-fussion"></ngx-spinner>
</div>

<ngb-toast
  *ngIf="showErrorToast"
  class="error-toast bg-danger text-light"
  [autohide]="true"
  (hidden)="showErrorToast = false"
  [delay]="4000"
  >{{
    "settings.role_management.already_exists_with_name" | translate
  }}</ngb-toast
>
