import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export const dateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const fromDate = control.get('from')?.value;
    const toDate = control.get('to')?.value;

    if (!fromDate || !toDate) {
        return null;
    }

    return fromDate > toDate ? { invalidDate: true } : null;
};
