<div class="d-flex w-100 h-100 base-container">
  <div class="content mw-100 mh-100 d-flex flex-grow-1 pt-3 flex-column">
    <div class="title-container">
      <img src="..\..\assets\img\squirrel_standing_tie.png" class="title-squirrel-image">
      <h1 class="title">{{ "project_assignments.title" | translate }}</h1>
    </div>
    <app-company-separated-display
      [companies]="this.visibleCompanies"
      class="flex-grow-1 d-flex pb-3 flex-column tabs"
      (companyAddedEvent)="refreshCompanies()"
    >
      <ng-template companyMarker *ngFor="let company of visibleCompanies">
        <table-project-assignments
          [company]="company"
          class="project-table h-100"
        ></table-project-assignments>
      </ng-template>
    </app-company-separated-display>
  </div>
  <div class="competency">
    <app-side-toolbar [openingDisabled]="competencySidebarDisabled" [title]="'competency.competencies' | translate">
      <app-competency>
        <app-employee-competency [isForProjects]="true" class="competencies"></app-employee-competency>
      </app-competency>
    </app-side-toolbar>
  </div>
</div>