import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { catchError, forkJoin, Observable, throwError } from "rxjs";
import {
  CompetencyDTO,
  CompetencyService,
  EmployeeCompetencyService,
  PlanDTO,
  PlanService,
} from "src/shared/api/generated";
import { CompetencyPillCommand } from "src/shared/models/competency-pill-command.model";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { dateValidator } from "src/shared/validators/date.validator";

@Component({
  selector: "app-modify-plan-modal",
  templateUrl: "./modify-plan-modal.component.html",
  styleUrls: ["./modify-plan-modal.component.scss"],
})
export class ModifyPlanModalComponent implements OnInit {
  @Input() plan: PlanDTO = {} as PlanDTO;
  @Input() projectName: string = "";
  @Input() employeeName: string = "";
  error: boolean = false;
  errorMessage: string = "";

  planForm!: FormGroup;

  employeeCompetencies: CompetencyDTO[] = [];
  competencies: CompetencyDTO[] = [];
  allCompetencies: CompetencyDTO[] = [];

  isLoading: boolean = false;

  deleteCompetencyFromList = (selectedCompetency: CompetencyDTO) => {
    this.competencies = this.competencies.filter(
      (competency) => competency.id !== selectedCompetency.id
    );
  };
  commands: CompetencyPillCommand[] = [
    {
      icon: "trash",
      function: this.deleteCompetencyFromList,
    },
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private competencyService: CompetencyService,
    private employeeCompetencyService: EmployeeCompetencyService,
    private spinnerService: NgxSpinnerService,
    private planService: PlanService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initPlanForm();
    this.fetchData();
    this.competencies = JSON.parse(JSON.stringify(this.plan.competencyList));
  }

  private initPlanForm(): void {
    this.planForm = new FormGroup(
      {
        from: new FormControl<string>(this.plan.from, Validators.required),
        to: new FormControl<string>(this.plan.to, Validators.required),
        description: new FormControl<string | undefined>(this.plan.description),
        percentage: new FormControl<number>(this.plan.percentage, [
          Validators.required,
          Validators.min(1),
          Validators.max(100),
        ]),
      },
      [dateValidator]
    );
  }

  private fetchData(): void {
    forkJoin([
      this.competencyService.getAllCompetenciesByProjectId(this.plan.projectId),
      this.competencyService.getAllCompetenciesByEmployeeId(
        this.plan.employeeId
      ),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(([allCompetencies, employeeCompetencies]) => {
        this.allCompetencies = allCompetencies;
        this.employeeCompetencies = employeeCompetencies;
      });
  }

  isDisabled(): boolean {
    return this.planForm.invalid;
  }

  closeAlert() {
    this.error = false;
    this.errorMessage = "";
  }

  checkAndAddNewCompetency({ competency, level }: any): void {
    this.isLoading = true;
    if (level) {
      this.saveEmployeeCompetency(competency, level);
    } else {
      this.addNewCompetency(competency);
    }
  }

  updatePlan(): void {
    this.closeAlert();

    if (this.isDisabled()) {
      this.planForm.markAllAsTouched();
      return;
    }

    this.planService
      .updatePlan({
        ...this.planForm.value,
        id: this.plan.id,
        projectId: this.plan.projectId,
        employeeId: this.plan.employeeId,
        competencyIdList: this.competencies.map((competency) => competency.id!),
      })
      .pipe(catchError((error) => this.handlePlanUpdateError(error)))
      .subscribe((updatedPlan) => this.activeModal.close(updatedPlan));
  }

  private handlePlanUpdateError(error: HttpErrorResponse): Observable<never> {
    if (error.status === HttpStatusCode.Conflict) {
      this.error = true;
      this.errorMessage = this.translate.instant(
        "plan.create_modal.error.plans_overlap_on_employee"
      );
    }
    return throwError(() => error.error);
  }

  saveEmployeeCompetency(competency: CompetencyDTO, level: number): void {
    this.employeeCompetencyService
      .createEmployeeCompetency({
        employeeId: this.plan.employeeId,
        competencyId: competency.id!,
        level: level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: () => {
          this.employeeCompetencies.push(competency);
          this.addNewCompetency(competency);
        },
      });
  }

  addNewCompetency(competency: CompetencyDTO): void {
    this.competencies.push(competency);
    this.isLoading = false;
  }

  validatePercentageField(): void {
    const control = this.planForm.get("percentage");
    if (!control || !control.value) return;

    if (control.value > 100) {
      control.setValue(100);
    } else if (control.value < 1) {
      control.setValue(1);
    }
  }
}
