import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CompanyDTO, CompetencyCondition, CompetencyDTO, CompetencyService } from "src/shared/api/generated";
import { SideToolbarService } from "src/shared/services/side-toolbar.service";

@Component({
  selector: "app-search-sidebar",
  templateUrl: "./search-sidebar.component.html",
  styleUrls: ["./search-sidebar.component.scss"],
})
export class SearchSidebarComponent implements OnInit {
  @Output() search = new EventEmitter<CompetencyCondition[]>();
  @Output() companySelectionChanged = new EventEmitter<CompanyDTO[]>();
  private competencies: CompetencyDTO[] = [];
  filteredCompetencies: CompetencyDTO[] = [];
  competencyInputs: CompetencyCondition[] = [];
  private competencyConditions: CompetencyCondition[] = [];

  constructor(private competencyService: CompetencyService, private sideToolbarService: SideToolbarService) {}

  ngOnInit() {
    this.sideToolbarService.openSideToolbar();
    this.fetchCompetencies().subscribe((allCompetencies) => {
      this.sideToolbarService.openSideToolbar();
      this.competencies = allCompetencies;
      this.filterCompetencies();
    });
  }

  private fetchCompetencies() {
    return this.competencyService.getAllCompetencies();
  }

  onCompanySelectionChanged(companies: CompanyDTO[]) {
    this.companySelectionChanged.emit(companies);
  }

  filterCompetencies() {
    this.filteredCompetencies = this.competencies
      .filter((competency) => {
        return !this.competencyConditions
          .map((input) => input.competencyId)
          .includes(competency.id!);
      }).sort((a,b) => a.name.localeCompare(b.name));
  }

  addNewCompetencyInput() {
    this.competencyInputs.push({
      competencyId: -1,
      lowerBound: 1,
      upperBound: 5,
    });
    this.competencyConditions.push({
      competencyId: -1,
      lowerBound: 1,
      upperBound: 5,
    });
  }

  removeCompetencyInput(index: number) {
    this.competencyInputs.splice(index, 1);
    this.competencyConditions.splice(index, 1);
    this.filterCompetencies();
  }

  updateCompetencyAt(condition: CompetencyCondition, index: number) {
    this.competencyConditions[index] = condition;
    this.filterCompetencies();
  }

  isSearchButtonDisabled() {
    if (this.competencyConditions.length === 0) {
      return true;
    }
    return (
      this.competencyConditions.filter((condition) => {
        return (
          condition.competencyId < 0 ||
          condition.lowerBound < 1 ||
          condition.upperBound > 5 ||
          condition.lowerBound > condition.upperBound
        );
      }).length > 0
    );
  }

  searchWithConditions() {
    if (this.isSearchButtonDisabled()) return;
    this.search.emit(this.competencyConditions);
  }

  clearAllCompetencyConditions() {
    this.competencyInputs = [];
    this.competencyConditions = [];
    this.filterCompetencies();
  }
}
