<div
  [ngClass]="{ 'sidebar-collapsed': !isOpened }"
  class="sidebar rounded-start-3 shadow"
>
  <div
    class="header-container ps-2"
    (click)="toggleSideToolbar()"
    [ngClass]="openingDisabled && !isOpened ? 'disabled-icon' : ''"
    data-testid="collapse-icon"
  >
    <fa-icon
      [icon]="!isOpened ? ['fas', 'chevron-left'] : ['fas', 'chevron-right']"
    ></fa-icon>
    <h4 class="sidebar-title fw-bold pb-1">
      {{ title }}
    </h4>
  </div>
  <div class="sidebar-content h-100 mt-3">
    <ng-content></ng-content>
  </div>
</div>
