import {
  Component,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";
import { formatDate } from "@angular/common";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangeDetectorRef } from '@angular/core';
import { CreatePlanModalComponent } from "src/app/application/plan/create-plan-modal/create-plan-modal.component";
import { NgxSpinnerService } from "ngx-spinner";
import {
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  Observable,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import {
  CompanyDTO,
  CompetencyDTO,
  CompetencyService,
  EmployeeDTO,
  EmployeeDTOCompetencies,
  EmployeePlanDTO,
  EmployeeService,
  PlanEmployeeProjectDTO,
  PlanEmployeeProjectResponseDTO,
  PlanService,
  ProjectService,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { PermissionService } from "src/shared/services/permission.service";
import { EmployeeProject } from "src/shared/models/employee-project.model";
import { TranslateService } from '@ngx-translate/core';
import { ModifyPlanModalComponent } from "../../plan/modify-plan-modal/modify-plan-modal.component";

@Component({
  selector: "table-project-assignments",
  templateUrl: "./table-project-assignments.component.html",
  styleUrls: ["./table-project-assignments.component.scss"],
})
export class TableProjectAssignmentsComponent implements OnInit, OnDestroy {
  @Input() company: CompanyDTO = {} as CompanyDTO;

  @ViewChildren(NgbdSortableHeader)
  headers = new QueryList<NgbdSortableHeader>();

  isAdmin: boolean = false;
  plans: EmployeePlanDTO[] = [];
  transformedPlans: EmployeeProject[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "name", direction: "asc" };
  selectedRowEmployee: number = -1;
  selectedRowEmployeeName: string = "";
  selectedRowProject: number = -1;
  selectedPlanId: number = -1;
  search: boolean = false;
  competencies: Map<string, { id: number; level: number; }[]> = new Map();
  employeeCompetencies: Map<number, EmployeeDTOCompetencies> = new Map();
  allCompetencies: CompetencyDTO[] = [];
  displayedProjects: string = "all";

  timeoutId: any = null;

  createProjectMode: boolean = false;
  cloneProjectMode: boolean = false;
  projectIdToClone: number = -1;
  subscriptions: Subscription = new Subscription();

  originalProjects: string[] = [];
  projects: string[] = [];
  selectedProjects = new FormControl([])

  originalEmployees: string[] = [];
  employees: string[] = [];
  selectedEmployees = new FormControl([])

  employeeSelectConfig: any;

  projectSelectConfig: any;

  constructor(
    private employeeService: EmployeeService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private modalService: NgbModal,
    private planService: PlanService,
    private competencyService: CompetencyService,
    private projectService: ProjectService,
    private spinnerService: NgxSpinnerService,
    private competencyManagerService: CompetencyManagerService,
    private permissionService: PermissionService,
    private confirmationModalService: ConfirmationModalService,
    @Inject(LOCALE_ID) private locale: string
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.permissionService.checkAdmin();
    this.initialLoad();
    this.subscriptions.add(
      this.competencyManagerService.competencyDeleted.subscribe((_) => {
        this.refreshProjectsAndCompetencies(false);
      })
    );
    this.searchProjects();
    this.columnSearch();
  }

  ngAfterViewInit() {
    this.employeeSelectConfig = {
      customComparator: (item: string) => { item.toLowerCase() },
      multiple: true,
      search: true,
      limitTo: 0,
      height: '350px',
      placeholder: this.translate.instant('project_assignments.select.employe_placeholder'),
      moreText: this.translate.instant('project_assignments.select.moreText'),
      noResultsFound: this.translate.instant('project_assignments.select.noResultsFound'),
      searchPlaceholder: this.translate.instant('project_assignments.select.searchPlaceholder')
    };

    this.projectSelectConfig = {
      customComparator: (item: string) => { item.toLowerCase() },
      multiple: true,
      search: true,
      limitTo: 0,
      height: '350px',
      placeholder: this.translate.instant('project_assignments.select.project_placeholder'),
      moreText: this.translate.instant('project_assignments.select.moreText'),
      noResultsFound: this.translate.instant('project_assignments.select.noResultsFound'),
      searchPlaceholder: this.translate.instant('project_assignments.select.searchPlaceholder')
    };

    this.cdr.detectChanges();
  }

  private sortAndSetValues(originalArray: string[], selectedFormControl: FormControl) {
    originalArray.sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
    selectedFormControl.value?.sort((a: string, b: string) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()));
    selectedFormControl.setValue(selectedFormControl.value);
  }

  employeeSelectChange() {
    this.page = 1;
    this.sortAndSetValues(this.originalEmployees, this.selectedEmployees);
  }

  projectSelectChange() {
    this.page = 1;
    this.sortAndSetValues(this.originalProjects, this.selectedProjects);
  }

  convertEmployeeCompetencies(inputObject: {
    [key: string]: number;
  }): { id: number; level: number }[] {
    return Object.entries(inputObject).map(([key, value]) => {
      return { id: parseInt(key), level: value };
    });
  }

  transformPlans(plans: PlanEmployeeProjectDTO[]): EmployeeProject[] {
    let transformedPlans: EmployeeProject[] = [];
    plans.forEach((plan) => {
      const comp = this.employeeCompetencies.get(plan.employeeId!)
      let idLevelObj = null;
      if (comp) {
        idLevelObj = this.convertEmployeeCompetencies(comp)
      }

      let transformedPlan: EmployeeProject = {} as EmployeeProject;
      transformedPlan.planId = plan.planId;
      transformedPlan.employeeId = plan.employeeId;
      transformedPlan.employeeName = plan.employeeName;
      transformedPlan.projectId = plan.projectId;
      transformedPlan.projectName = plan.projectName;
      transformedPlan.description = plan.description;
      transformedPlan.fte = plan.fte;
      transformedPlan.startDate = plan.startDate;
      transformedPlan.endDate = plan.endDate;

      this.assignCompetencies(idLevelObj, plan, transformedPlan);
      transformedPlans.push(transformedPlan);
    });
    return transformedPlans;
  }

  private assignCompetencies(idLevelObj: { id: number; level: number; }[] | null,
    projectPlan: PlanEmployeeProjectDTO,
    transformedPlan: EmployeeProject) {
    if (idLevelObj) {
      const competencies = projectPlan.competencies?.map(competency => {
        const competencyObj = idLevelObj.find(obj => obj.id === competency.id);
        const level = competencyObj ? competencyObj.level : 3;
        return { id: competency.id!, level: level };
      });
      transformedPlan.competencies = competencies?.filter(competency => competency.id !== undefined) as { id: number; level: number; }[];
    }

    if (transformedPlan.competencies) {
      if (transformedPlan.competencies.length > 0) {
        const key = `${transformedPlan.employeeId!}-${transformedPlan.projectId!}`;
        this.competencies.set(key, transformedPlan.competencies);
      }
    }
  }

  addEmptyProject() {
    const modalRef = this.modalService.open(CreatePlanModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
    });
    modalRef.componentInstance.employeeOption = true;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.refreshProjectsAndCompetencies(false);
        }
      },
      () => { }
    );
  }
  openEditItemDialog(item: EmployeeProject) {
    const modalRef = this.modalService.open(ModifyPlanModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
    });
    modalRef.componentInstance.projectName = item.projectName;
    modalRef.componentInstance.employeeName = item.employeeName;
    modalRef.componentInstance.plan = {
      id: item.planId,
      from: item.startDate,
      description: item.description,
      to: item.endDate,
      percentage: Number(item.fte) * 100,
      projectId: item.projectId,
      employeeId: item.employeeId,
      competencyList: this.collectCompetencyDTOs(item),
    };
    modalRef.result.then(
      (result) => {
        if (result) {
          this.refreshPlans();
        }
      },
      () => {}
    );
  }

  collectCompetencyDTOs(item: EmployeeProject): CompetencyDTO[] {
    return item.competencies?.map(competency => {
      return this.allCompetencies.find(dto => dto.id === competency.id);
    }).filter(dto => dto !== undefined) as CompetencyDTO[];
  }

  getPlansForCurrentCompany(selectedProjects: string[], selectedEmployees: string[]): Observable<PlanEmployeeProjectResponseDTO> {
    return this.planService.getAllMatchingEmployeePlans(
      this.company.id!,
      selectedProjects,
      selectedEmployees,
      this.pageSize,
      this.page - 1
    );
  }

  getEmployees(): Observable<EmployeeDTO[]> {
    return this.employeeService.getAllEmployeesFromCompany();
  }

  dateToString(date: Date) {
    let stringDate = formatDate(date, "yyyy-MM-dd", this.locale);
    return stringDate;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getAllCompetenciesObservable(): Observable<Array<CompetencyDTO>> {
    return this.competencyService.getAllCompetencies();
  }

  initialLoad(): void {
    this.refreshProjectsAndCompetencies(true);
  }

  refreshProjectsAndCompetencies(initial: boolean): void {
    forkJoin([
      this.getPlansForCurrentCompany(this.selectedProjects.value ?? [], this.selectedEmployees.value ?? []),
      this.getEmployees(),
      this.getAllCompetenciesObservable()
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(([pagePlanEmployeeProjectDTO, employees, competencies]) => {
        this.allCompetencies = competencies;
        this.employeeCompetencies.clear();
        employees.forEach((employee) => {
          this.employeeCompetencies.set(employee.id!, employee.competencies);
        });

        const plans = pagePlanEmployeeProjectDTO.page?.content ?? [];
        this.plans = plans ?? [];
        this.transformedPlans = this.transformPlans(plans);
        this.employees = [...pagePlanEmployeeProjectDTO.employeeNames?? []];
        this.projects = [...pagePlanEmployeeProjectDTO.projectNames ?? []];
        this.collectionSize = pagePlanEmployeeProjectDTO.page?.totalElements ?? 0;
        this.selectedRowEmployee = -1;

        this.saveEmployeesAndProjects(initial);

        this.updateCollectionsForFilters();
      });
  }

  private saveEmployeesAndProjects(initial: boolean) {
    if (initial) {
      this.originalProjects = this.projects;
      this.originalEmployees = this.employees;
      return
    }

    this.projects.forEach(project => {
      if (!this.originalProjects.includes(project)) {
        this.originalProjects.push(project);
      }
    });

    this.employees.forEach(employee => {
      if (!this.originalEmployees.includes(employee)) {
        this.originalEmployees.push(employee);
      }
    });
  }

  private updateCollectionsForFilters() {
    this.originalProjects.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
    this.originalEmployees.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    const updatedProjects = [...this.originalProjects];
    this.originalProjects = [];
    this.cdr.detectChanges();
    this.originalProjects = updatedProjects;
    this.cdr.detectChanges();

    const updatedEmployees = [...this.originalEmployees];
    this.originalEmployees = [];
    this.cdr.detectChanges();
    this.originalEmployees = updatedEmployees;
    this.cdr.detectChanges();
  }

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshPlans();
  }

  refreshPlans() {
    this.getPlansForCurrentCompany(this.selectedProjects.value ?? [], this.selectedEmployees.value ?? [])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((pagePlanEmployeeProjectDTO) => {
        const plans = pagePlanEmployeeProjectDTO.page?.content ?? [];
        this.plans = plans ?? [];
        this.transformedPlans = this.transformPlans(plans);
        this.employees = [...pagePlanEmployeeProjectDTO.employeeNames ?? []];
        this.projects = [...pagePlanEmployeeProjectDTO.projectNames ?? []];
        this.collectionSize = pagePlanEmployeeProjectDTO.page?.totalElements ?? 0;
        this.selectedRowEmployee = -1;
      });
  }

  toggleDisplayMode() {
    this.page = 1;
    this.refreshPlans();
    this.competencyManagerService.clearProjectCompetency();
  }

  searchProjects() {
    this.selectedEmployees.valueChanges
      .pipe(
        tap(() => (this.search = false)),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.competencyManagerService.clearProjectCompetency();
        }),
        switchMap((text) =>
          this.getPlansForCurrentCompany(this.selectedProjects.value ?? [], this.selectedEmployees.value ?? []).pipe(
            showLoadingSpinner(this.spinnerService)
          )))
      .subscribe((pagePlanEmployeeProjectDTO) => {
        const plans = pagePlanEmployeeProjectDTO.page?.content ?? [];
        this.plans = plans ?? [];
        this.transformedPlans = this.transformPlans(plans);
        this.employees = [...pagePlanEmployeeProjectDTO.employeeNames ?? []];
        this.projects = [...pagePlanEmployeeProjectDTO.projectNames ?? []];
        this.collectionSize = pagePlanEmployeeProjectDTO.page?.totalElements ?? 0;
        this.selectedRowEmployee = -1;
        this.search = true;
      });

    this.selectedProjects.valueChanges
      .pipe(
        tap(() => (this.search = false)),
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.competencyManagerService.clearProjectCompetency();
        }),
        switchMap((text) =>
          this.getPlansForCurrentCompany(this.selectedProjects.value ?? [], this.selectedEmployees.value ?? []).pipe(
            showLoadingSpinner(this.spinnerService)
          )))
      .subscribe((pagePlanEmployeeProjectDTO) => {
        const plans = pagePlanEmployeeProjectDTO.page?.content ?? [];
        this.plans = plans ?? [];
        this.transformedPlans = this.transformPlans(plans);
        this.employees = [...pagePlanEmployeeProjectDTO.employeeNames ?? []];
        this.projects = [...pagePlanEmployeeProjectDTO.projectNames ?? []];
        this.collectionSize = pagePlanEmployeeProjectDTO.page?.totalElements ?? 0;
        this.selectedRowEmployee = -1;
        this.search = true;
      });
  }

  columnSearch() {
    this.selectedProjects.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.refreshPlans();
      });

    this.selectedEmployees.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.refreshPlans();
      });
  }

  selectRow(employeeId: number, employeeName: string, projectId: number, planId: number) {
    if (this.createProjectMode) {
      return;
    }
    if (this.selectedRowEmployee === employeeId &&
      this.selectedRowProject === projectId &&
      this.selectedPlanId === planId) {
      this.selectedRowEmployee = -1;
      this.selectedRowEmployeeName = "";
      this.selectedRowProject = -1;
      this.selectedPlanId = -1;
    } else {
      this.selectedRowEmployee = employeeId;
      this.selectedRowEmployeeName = employeeName;
      this.selectedRowProject = projectId;
      this.selectedPlanId = planId;
    }
  }

  openDeleteConfirmationModal(id: number) {
    this.confirmationModalService
      .openConfirmationModal(
        "project_assignments.confirm_delete",
        "project_assignments.delete_dialog_message"
      )
      .then(
        (result) => {
          if (result) {
            this.deletePlan(id);
          }
        },
        () => { }
      );
  }

  deletePlan(id: number) {
    this.planService.deletePlan(id).subscribe({
      next: () => {
        this.refreshPlans();
      },
    });
  }

}
