import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DisplayAndEditEmployeeCompetencyDto } from 'src/shared/models/display-and-edit-employee-competency.model';
import { DisplayAndEditProjectCompetencyDto } from 'src/shared/models/display-and-edit-project-competency.model';
import { CompetencyManagerService } from 'src/shared/services/competency-manager.service';
import { SideToolbarService } from 'src/shared/services/side-toolbar.service';

@Component({
  selector: 'app-competency',
  templateUrl: './competency.component.html',
  styleUrls: ['./competency.component.scss']
})
export class CompetencyComponent implements OnInit, OnDestroy {
  projectCompetencyId: number | null = null;
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService,
    private sideToolbarService: SideToolbarService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.sideToolbarService.closeSideToolbar();
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          if (data.employeeId) {
            this.sideToolbarService.openSideToolbar();
          }
        }
      )
    );

    this.subscriptions.add(
      this.competencyManagerService.projectCompetency.subscribe(
        (data: DisplayAndEditProjectCompetencyDto) => {
          if (data.projectId && this.projectCompetencyId !== data.projectId) {
            this.sideToolbarService.openSideToolbar();
            this.projectCompetencyId = data.projectId;
          }
        }
      )
    );

    this.subscriptions.add(
      this.sideToolbarService.isOpened.subscribe((isOpened) => {
        this.sidebarStateChanged(isOpened);
      })
    );
  }

  sidebarStateChanged(isOpened: boolean): void {
    if (!isOpened) {
      this.competencyManagerService.clearBothCompetencies();
      this.projectCompetencyId = null;
    }
  }
}
