import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { map, Observable } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  CompetencyService,
} from "src/shared/api/generated";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "[app-tr-competency-management]",
  templateUrl: "./tr-competency-management.component.html",
  styleUrls: ["./tr-competency-management.component.scss"],
})
export class TrCompetencyManagementComponent implements OnInit {
  @Input() competency: CompetencyDTO = {} as CompetencyDTO;
  @Input() competencyGroups: CompetencyGroupDTO[] = [];

  @Output() competencyDeleted = new EventEmitter<void>();
  @Output() competencyAlreadyExists = new EventEmitter<void>();

  editedCompetency: CompetencyDTO = {} as CompetencyDTO;
  editedCell: string = "";

  constructor(
    private competencyService: CompetencyService,
    private confirmationModalService: ConfirmationModalService
  ) {}

  ngOnInit(): void {
    this.resetEditedCompetency();
  }

  handleCompetencyUpdate() {
    if (this.editedCompetency.name === "") {
      this.setEditStateFalse();
      return;
    }

    this.updateCompetency({
      ...this.editedCompetency,
      name: this.editedCompetency.name.trim(),
    });
  }

  private updateCompetency(competency: CompetencyDTO): void {
    this.competencyService
      .updateCompetency({
        id: competency.id,
        name: competency.name,
        competencyGroupId: competency.competencyGroup.id,
      })
      .subscribe({
        next: (savedCompetency) => {
          this.competency = savedCompetency;
          this.setEditStateFalse();
        },
        error: (error) => this.handleCompetencySaveError(error),
      });
  }

  private handleCompetencySaveError(error: HttpErrorResponse): void {
    if (error.status === HttpStatusCode.Conflict) {
      this.competencyAlreadyExists.emit();
    }
  }

  onDeleteCompetency(competencyId: number) {
    this.hasAssignments(competencyId).subscribe((hasAssignments) => {
      if (!hasAssignments) {
        this.deleteCompetency(competencyId);
        return;
      }

      this.confirmationModalService.openConfirmationModal(
        "competency.delete_error",
        "competency.delete_dialog_message",
        true
      );
    });
  }

  hasAssignments(competencyId: number): Observable<boolean> {
    return this.competencyService
      .getNumberOfUsagesOfCompetency(competencyId)
      .pipe(map((numberOfAssignments: number) => numberOfAssignments > 0));
  }

  deleteCompetency(competencyId: number) {
    this.competencyService.deleteCompetency(competencyId).subscribe({
      next: () => {
        this.competencyDeleted.emit();
      },
      error: (error) => {
        if (error.status === HttpStatusCode.BadRequest) {
          this.confirmationModalService.openConfirmationModal(
            "competency.delete_error",
            "competency.delete_dialog_message",
            true
          );
        }
      },
    });
  }

  setEditStateFalse() {
    this.editedCell = "";
    this.resetEditedCompetency();
  }

  setEditStateTrue(key: string) {
    this.editedCell = key;
  }

  resetEditedCompetency() {
    this.editedCompetency = JSON.parse(JSON.stringify(this.competency));
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
