<div class="d-flex flex-column justify-content-between h-100">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="d-flex flex-row justify-content-between table-actions">
      <div class="flex-grow-1 d-flex flex-row align-items-center search">
        <fa-icon
          class="ms-auto remove-icon"
          [icon]="['fas', 'magnifying-glass']"
        ></fa-icon>
        <input
          class="form-control form-control-sm flex-grow-1"
          type="text"
          placeholder="{{ 'home.looking_for_project.search' | translate }}"
          [formControl]="searchText"
        />
        <div class="btn-group btn-group-sm ms-2" btnRadioGroup>
          <label
            class="btn btn-outline-primary display-mode-button"
            [class.active]="displayedLookingForResources === 'active'"
          >
            <input
              type="radio"
              class="btn-check"
              name="employeeStatus"
              autocomplete="off"
              [(ngModel)]="displayedLookingForResources"
              value="active"
              (change)="toggleDisplayMode()"
            />
            {{ "home.looking_for_resources.display_mode.active" | translate }}
          </label>
          <label
            class="btn btn-outline-primary display-mode-button"
            [class.active]="displayedLookingForResources === 'all'"
          >
            <input
              type="radio"
              class="btn-check"
              name="employeeStatus"
              autocomplete="off"
              [(ngModel)]="displayedLookingForResources"
              value="all"
              (change)="toggleDisplayMode()"
            />
            {{ "home.looking_for_resources.display_mode.all" | translate }}
          </label>
        </div>
      </div>
      <button
        class="btn primary-btn ms-2"
        (click)="openAddProjectDialog()"
        [disabled]="!dataLoaded"
      >
        {{ "home.looking_for_project.add" | translate }}
      </button>
    </div>
    <div
      *ngIf="
        !lookingForResourceEntities.length && !searchText.value?.trim()?.length
      "
      class="image-container"
    >
      <img src="..\..\assets\img\squirrel_sleep.png" />
    </div>
    <div
      *ngIf="
        lookingForResourceEntities.length || searchText.value?.trim()?.length
      "
      class="table-container"
    >
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              sortable="project.name"
              (sort)="onSort($event)"
              class="table-header text-center name"
            >
              {{ "home.looking_for_resources.name" | translate }}
            </th>
            <th
              scope="col"
              sortable="assignee.name"
              (sort)="onSort($event)"
              class="table-header text-center assignee"
            >
              {{ "home.looking_for_resources.assignee" | translate }}
            </th>
            <th scope="col" class="table-header text-center status">
              {{ "home.looking_for_resources.status.label" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "home.looking_for_project.comment" | translate }}
            </th>
            <th scope="col" class="table-header text-center project-state">
              {{ "home.looking_for_resources.state.label" | translate }}
            </th>
            <th
              scope="col"
              sortable="project.start"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_resources.project_start" | translate }}
            </th>
            <th
              scope="col"
              sortable="project.end"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_resources.project_end" | translate }}
            </th>
            <th
              scope="col"
              sortable="start"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_resources.request_start" | translate }}
            </th>
            <th
              scope="col"
              sortable="end"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "home.looking_for_resources.request_end" | translate }}
            </th>
            <th
              scope="col"
              sortable="fte"
              (sort)="onSort($event)"
              class="table-header text-center fte"
            >
              {{ "home.looking_for_resources.fte" | translate }}
            </th>
            <th scope="col" class="table-header text-center competencies">
              <span
                ngbTooltip="{{
                  'home.looking_for_resources.competencies_tooltip' | translate
                }}"
                placement="bottom"
                container="body"
                [positionTarget]="target"
              >
                {{ "home.looking_for_resources.competencies" | translate }}
                <span #target
                  ><fa-icon [icon]="['fas', 'circle-info']" size="lg"></fa-icon
                ></span>
              </span>
            </th>
            <th scope="col" class="table-header text-center members">
              {{ "home.looking_for_resources.members" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for (lookingForResourceEntity of lookingForResourceEntities; track
          lookingForResourceEntity.id) {
          <tr
            app-tr-looking-for-resource
            [lookingForResource]="lookingForResourceEntity"
            [users]="users"
            [searchTerm]="searchText.value?.trim()"
            [search]="search"
            [allCompetencies]="competencies"
            (onCommentSaved)="refreshLookingForResources()"
            (onProjectMembersModified)="refreshLookingForResources()"
            (click)="selectRow(lookingForResourceEntity.id!)"
            [ngClass]="{
              selected: selectedRow === lookingForResourceEntity.id
            }"
          ></tr>
          }
        </tbody>
      </table>
    </div>
    <div
      *ngIf="
        lookingForResourceEntities.length || searchText.value?.trim()?.length
      "
      class="d-flex justify-content-between align-items-top p-2"
    >
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshLookingForResources()"
        class="pages"
      >
      </ngb-pagination>
      <div class="information">
        {{ lookingForResourceEntities.length }} /
        {{ collectionSize }}
      </div>
    </div>
  </div>
</div>
