<td (dblclick)="setEditStateTrue('name')">
  <input
    id="name"
    *ngIf="this.editedCell === 'name' && this.employeeWithUserInfo.employeeId"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEmployeeWithUserInfoUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleEmployeeWithUserInfoUpdate()"
    [(ngModel)]="editEmployeeWithUserInfo.name"
    (click)="inputClicked($event)"
  />
  <input
    id="name"
    *ngIf="!this.employeeWithUserInfo.employeeId"
    type="text"
    [(ngModel)]="employeeWithUserInfo.name"
  />
  <label
    class="text-overflow-dotted w-100"
    [ngClass]="{
      hidden:
        this.editedCell === 'name' || !this.employeeWithUserInfo.employeeId
    }"
    [ngbTooltip]="employeeWithUserInfo.name"
    [openDelay]="300"
    placement="top"
    container="body"
    >{{ employeeWithUserInfo.name }}</label
  >
</td>
<td (dblclick)="setEditStateTrue('email')">
  <input
    id="email"
    *ngIf="this.editedCell === 'email' && this.employeeWithUserInfo.employeeId"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEmployeeWithUserInfoUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleEmployeeWithUserInfoUpdate()"
    [(ngModel)]="editEmployeeWithUserInfo.email"
    (click)="inputClicked($event)"
  />
  <input
    id="email"
    *ngIf="!this.employeeWithUserInfo.employeeId"
    type="text"
    [(ngModel)]="employeeWithUserInfo.email"
  />
  <label
    class="text-overflow-dotted w-100"
    [ngClass]="{
      hidden:
        this.editedCell === 'email' || !this.employeeWithUserInfo.employeeId
    }"
    [ngbTooltip]="employeeWithUserInfo.email"
    [openDelay]="300"
    placement="top"
    container="body"
    >{{ employeeWithUserInfo.email }}</label
  >
</td>
<td class="text-center">
  <select
    id="company"
    *ngIf="this.employeeWithUserInfo.employeeId"
    (change)="handleEmployeeWithUserInfoUpdate()"
    [(ngModel)]="editEmployeeWithUserInfo.companyId"
    [disabled]="this.isEditingDisabled()"
    (click)="inputClicked($event)"
  >
    <option *ngFor="let company of companies" [value]="company.id">
      {{ company.name }}
    </option>
  </select>
  <select
    id="company"
    *ngIf="!this.employeeWithUserInfo.employeeId"
    [(ngModel)]="employeeWithUserInfo.companyId"
  >
    <option *ngFor="let company of companies" [value]="company.id">
      {{ company.name }}
    </option>
  </select>
</td>

<td class="text-center">
  <select
    id="employmentType"
    *ngIf="this.employeeWithUserInfo.employeeId"
    [(ngModel)]="editEmployeeWithUserInfo.internal"
    [disabled]="this.isEditingDisabled()"
    (change)="handleEmployeeWithUserInfoUpdate()"
    (click)="inputClicked($event)"
  >
    <option value="true">
      {{ "settings.user_management.internal" | translate }}
    </option>
    <option value="false">
      {{ "settings.user_management.external" | translate }}
    </option>
  </select>
  <select
    id="employmentType"
    *ngIf="!this.employeeWithUserInfo.employeeId"
    [(ngModel)]="employeeWithUserInfo.internal"
  >
    <option value="true">
      {{ "settings.user_management.internal" | translate }}
    </option>
    <option value="false">
      {{ "settings.user_management.external" | translate }}
    </option>
  </select>
</td>
<td class="text-center">
  <select
    id="status"
    *ngIf="this.employeeWithUserInfo.employeeId"
    [(ngModel)]="editEmployeeWithUserInfo.active"
    [disabled]="this.isEditingDisabled()"
    (change)="handleEmployeeWithUserInfoUpdate()"
    (click)="inputClicked($event)"
  >
    <option value="true">
      {{ "settings.user_management.active" | translate }}
    </option>
    <option value="false">
      {{ "settings.user_management.inactive" | translate }}
    </option>
  </select>
  <select
    id="status"
    *ngIf="!this.employeeWithUserInfo.employeeId"
    [(ngModel)]="employeeWithUserInfo.active"
  >
    <option value="true">
      {{ "settings.user_management.active" | translate }}
    </option>
    <option value="false">
      {{ "settings.user_management.inactive" | translate }}
    </option>
  </select>
</td>
<td class="text-center">
  <input
    id="planned"
    class="form-check-input"
    type="checkbox"
    role="switch"
    *ngIf="this.employeeWithUserInfo.employeeId"
    [(ngModel)]="editEmployeeWithUserInfo.planned"
    [disabled]="this.isEditingDisabled()"
    (change)="handleEmployeeWithUserInfoUpdate()"
    (click)="inputClicked($event)"
  />
  <input
    id="planned"
    class="form-check-input"
    type="checkbox"
    role="switch"
    *ngIf="!this.employeeWithUserInfo.employeeId"
    [(ngModel)]="employeeWithUserInfo.planned"
  />
</td>
<td class="text-center">
  <div class="form-check form-switch centered-switch">
    <input
      id="isUser"
      class="form-check-input"
      type="checkbox"
      role="switch"
      [(ngModel)]="isUser"
      [disabled]="this.isEditingDisabled() || this.createMode"
      (change)="manageUser($event)"
      (click)="inputClicked($event)"
    />
  </div>
</td>
<td class="text-center">
  <div class="form-check form-switch centered-switch">
    <input
      id="isAdmin"
      class="form-check-input"
      type="checkbox"
      role="switch"
      [disabled]="
        !isUser ||
        this.editEmployeeWithUserInfo.sales ||
        this.isEditingDisabled()
      "
      [(ngModel)]="editEmployeeWithUserInfo.admin"
      (change)="setAdmin()"
      (click)="inputClicked($event)"
    />
  </div>
</td>
<td class="text-center">
  <div class="form-check form-switch centered-switch">
    <input
      id="isSales"
      class="form-check-input"
      type="checkbox"
      role="switch"
      [disabled]="
        !isUser ||
        this.editEmployeeWithUserInfo.admin ||
        this.isEditingDisabled()
      "
      [(ngModel)]="editEmployeeWithUserInfo.sales"
      (change)="setSales()"
      (click)="inputClicked($event)"
    />
  </div>
</td>
<td class="text-center">
  <select
    id="global"
    [disabled]="!isUser || this.isEditingDisabled()"
    (click)="inputClicked($event)"
    [(ngModel)]="visibleCompanyId"
    (change)="setVisibleCompanies()"
  >
    <option *ngFor="let company of companies" [value]="company.id">
      {{ company.name }}
    </option>
    <option value="-1">
      {{ "settings.user_management.all_companies" | translate }}
    </option>
  </select>
</td>
