<div class="basic-container">
  <div class="main-container">
    <div class="company-container">
      <div class="title-container">
        <label class="title">
          {{ "search_sidebar.companies" | translate }}
        </label>
      </div>
      <app-company-multi-select-dropdown
        (shareCheckedList)="onCompanySelectionChanged($event)"
      ></app-company-multi-select-dropdown>
    </div>
    <div class="competency-container">
      <div class="command-container">
        <label class="title">
          {{ "search_sidebar.competencies" | translate }}
        </label>
        <div class="commands">
          <button class="search-button" [disabled]="isSearchButtonDisabled()">
            <fa-icon
              class="command"
              ngbTooltip="{{ 'search_sidebar.search' | translate }}"
              placement="left"
              container="body"
              [icon]="['fas', 'search']"
              (click)="searchWithConditions()"
            >
            </fa-icon>
          </button>
          <fa-icon
            class="command"
            ngbTooltip="{{ 'search_sidebar.add_competency' | translate }}"
            placement="left"
            container="body"
            [icon]="['fas', 'plus']"
            (click)="addNewCompetencyInput()"
          >
          </fa-icon>
          <fa-icon
            class="command remove-icon"
            ngbTooltip="{{ 'search_sidebar.clear_all' | translate }}"
            placement="left"
            container="body"
            [icon]="['fas', 'rotate-left']"
            (click)="clearAllCompetencyConditions()"
          ></fa-icon>
        </div>
      </div>
      <div class="attribute-container">
        <app-competency-search-input
          *ngFor="let competencyInput of competencyInputs; index as i"
          class="input"
          [competencies]="filteredCompetencies"
          (removedEvent)="removeCompetencyInput(i)"
          (changed)="updateCompetencyAt($event, i)"
        ></app-competency-search-input>
      </div>
    </div>
  </div>
</div>
