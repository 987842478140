import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, distinctUntilChanged, filter, forkJoin, map, merge, Observable, OperatorFunction, Subject } from "rxjs";
import { CompetencyDTO, CompetencyService, EmployeeCompetencyService, EmployeeSelectDTO, EmployeeService, PlanCreateDTO, PlanService } from "src/shared/api/generated";
import { errorMessage } from "src/shared/constant/error-message.constant";
import { CompetencyPillCommand } from "src/shared/models/competency-pill-command.model";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-add-member",
  templateUrl: "./add-member.component.html",
  styleUrl: "./add-member.component.scss",
  providers: [
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: "yyyy/MM/dd" },
    },
  ],
})
export class AddMemberComponent implements OnInit {
  @Output() memberAddCancelled: EventEmitter<void> = new EventEmitter<void>();
  @Output() memberAddConfirmed: EventEmitter<void> = new EventEmitter<void>();
  @Input() selectedProjectId: number = -1;

  addMemberForm: FormGroup = new FormGroup({
    selectedEmployee: new FormControl<EmployeeSelectDTO | null>(
      null,
      Validators.required
    ),
    from: new FormControl<string>("", Validators.required),
    to: new FormControl<string>("", Validators.required),
    description: new FormControl<string | null>(null),
    percentage: new FormControl<number>(100, [
      Validators.required,
      Validators.min(1),
      Validators.max(100),
    ]),
  });

  employeeCompetencies: CompetencyDTO[] = [];
  projectCompetencies: CompetencyDTO[] = [];
  competencies: CompetencyDTO[] = [];
  employees: EmployeeSelectDTO[] = [];

  error: boolean = false;
  errorMessage: string = "";

  employeeFocus$ = new Subject<string>();
  employeeClick$ = new Subject<string>();
  employeeInstance: NgbTypeahead = {} as NgbTypeahead;

  constructor(
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
    private competencyService: CompetencyService,
    private employeeService: EmployeeService,
    private employeeCompetencyService: EmployeeCompetencyService,
    private planService: PlanService
  ) {}

  deleteCompetencyFromList = (selectedCompetency: CompetencyDTO) => {
    this.competencies = this.competencies.filter(
      (competency) => competency.id !== selectedCompetency.id
    );
  };
  commands: CompetencyPillCommand[] = [
    {
      icon: "trash",
      function: this.deleteCompetencyFromList,
    },
  ];

  ngOnInit(): void {
    this.fetchData();
  }

  fetchCompetencies(selectItem: any) {
    this.competencyService
      .getAllCompetenciesByEmployeeId(selectItem.item.id)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(
        (employeeCompetencies) =>
          (this.employeeCompetencies = employeeCompetencies)
      );
    this.competencies = [];
  }

  cancelAdding() {
    this.memberAddCancelled.emit();
  }

  searchEmployee: OperatorFunction<string, EmployeeSelectDTO[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.employeeClick$.pipe(
      filter(() => !this.employeeInstance.isPopupOpen())
    );
    const inputFocus$ = this.employeeFocus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ""
          ? []
          : this.employees.filter((v) => new RegExp(term, "miu").test(v.name!))
        ).slice(0, 10)
      )
    );
  };
  inputFormatter = (x: EmployeeSelectDTO) => x.name!;

  fetchData() {
    forkJoin([
      this.employeeService.getAllEmployeeSelectDtos(),
      this.competencyService.getAllCompetenciesByProjectId(
        this.selectedProjectId
      ),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe(([allEmployees, projectCompetencies]) => {
        this.employees = allEmployees;
        this.projectCompetencies = projectCompetencies;
      });
  }

  checkAndAddNewCompetency({ competency, level }: any): void {
    if (level) {
      this.saveEmployeeCompetency(competency, level);
    } else {
      this.addNewCompetency(competency);
    }
  }

  saveEmployeeCompetency(competency: CompetencyDTO, level: number): void {
    this.employeeCompetencyService
      .createEmployeeCompetency({
        employeeId: this.selectedEmployee.id!,
        competencyId: competency.id!,
        level: level,
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: () => {
          this.employeeCompetencies.push(competency);
          this.addNewCompetency(competency);
        },
      });
  }

  addNewCompetency(competency: CompetencyDTO): void {
    this.competencies.push(competency);
  }

  isDisabled(): boolean {
    return (
      !this.selectedEmployee ||
      this.selectedEmployee?.id! < 0 ||
      this.addMemberForm.invalid
    );
  }

  saveNewPlan() {
    if (this.isDisabled()) {
      this.addMemberForm.markAllAsTouched();
      return;
    }
    const { from, to, percentage, description } = this.addMemberForm.value;
    const newPlan = {
      from,
      to,
      percentage,
      description,
      employeeId: this.selectedEmployee.id!,
      projectId: this.selectedProjectId,
      competencyIdList: this.competencies.map((competency) => competency.id!),
    } as PlanCreateDTO;
    this.planService.createPlan(newPlan).subscribe({
      next: (_) => {
        this.memberAddConfirmed.emit();
      },
      error: (error) => {
        if (error.error.message === errorMessage.planOverlaps)
          this.errorMessage = this.translate.instant(
            "plan.create_modal.error.plans_overlap_on_employee"
          );
        this.error = true;
      },
    });
  }

  closeAlert() {
    this.error = false;
  }

  validatePercentageField(): void {
    const control = this.addMemberForm.get("percentage");
    if (!control || !control.value) return;

    if (control.value > 100) {
      control.setValue(100);
    } else if (control.value < 1) {
      control.setValue(1);
    }
  }

  get selectedEmployee() {
    return this.addMemberForm.get("selectedEmployee")?.value;
  }
}
