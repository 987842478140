import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SideToolbarService {
  private isOpenedSubject = new BehaviorSubject<boolean>(false);
  private _isOpened = this.isOpenedSubject.asObservable();
  
  public get isOpened() {
    return this._isOpened;
  }

  public set isOpened(value) {
    this._isOpened = value;
  }

  openSideToolbar() {
    this.isOpenedSubject.next(true);
  }

  closeSideToolbar() {
    this.isOpenedSubject.next(false);
  }

  toggleSidebar() {
    const newState = !this.isOpenedSubject.value;
    this.isOpenedSubject.next(newState);
  }
}
