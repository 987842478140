<td (dblclick)="setEditStateTrue('code')">
  <input
    id="code"
    class="w-100"
    *ngIf="this.editedCell === 'code' && this.education.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEducationUpdate()"
    (keydown.escape)="setEditStateFalse($event)"
    (focusout)="handleEducationUpdate()"
    [(ngModel)]="editedEducation.code"
    (click)="inputClicked($event)"
  />
  <input
    id="code"
    *ngIf="!this.education.id"
    class="w-100"
    type="text"
    [(ngModel)]="education.code"
  />
  <label id="code" *ngIf="this.editedCell !== 'code' && this.education.id">
    {{ education.code }}
  </label>
</td>
<td (dblclick)="setEditStateTrue('name')">
  <input
    id="name"
    class="w-100"
    *ngIf="this.editedCell === 'name' && this.education.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEducationUpdate()"
    (keydown.escape)="setEditStateFalse($event)"
    (focusout)="handleEducationUpdate()"
    [(ngModel)]="editedEducation.name"
    (click)="inputClicked($event)"
  />
  <input
    id="name"
    *ngIf="!this.education.id"
    class="w-100"
    type="text"
    [(ngModel)]="education.name"
  />
  <label
    id="name"
    [ngClass]="{ hidden: this.editedCell === 'name' || !this.education.id }"
  >
    {{ education.name }}
  </label>
</td>
<td (dblclick)="setEditStateTrue('start')">
  <input
    id="startDate"
    class="w-100"
    *ngIf="this.editedCell === 'start' && this.education.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEducationUpdate()"
    (keydown.escape)="setEditStateFalse($event)"
    (focusout)="handleEducationUpdate()"
    [disabled]="isEditingDisabled()"
    [(ngModel)]="editedEducation.start"
    (click)="inputClicked($event)"
  />
  <input
    id="startDate"
    *ngIf="!this.education.id"
    class="w-100"
    type="date"
    [(ngModel)]="education.start"
  />
  <label
    id="startDate"
    [ngClass]="{ hidden: this.editedCell === 'start' || !this.education.id }"
  >
    {{ education.start }}
  </label>
</td>
<td (dblclick)="setEditStateTrue('end')">
  <input
    id="endDate"
    class="w-100"
    *ngIf="this.editedCell === 'end' && this.education.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEducationUpdate()"
    (keydown.escape)="setEditStateFalse($event)"
    (focusout)="handleEducationUpdate()"
    [disabled]="isEditingDisabled()"
    [(ngModel)]="editedEducation.end"
    (click)="inputClicked($event)"
  />
  <input
    id="endDate"
    *ngIf="!this.education.id"
    class="w-100"
    type="date"
    [(ngModel)]="education.end"
  />
  <label
    id="endDate"
    [ngClass]="{ hidden: this.editedCell === 'end' || !this.education.id }"
  >
    {{ education.end }}
  </label>
</td>
<td (dblclick)="setEditStateTrue('valid_until')">
  <input
    id="validUntil"
    class="w-100"
    *ngIf="this.editedCell === 'valid_until' && this.education.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEducationUpdate()"
    (keydown.escape)="setEditStateFalse($event)"
    [disabled]="isEditingDisabled()"
    (focusout)="handleEducationUpdate()"
    [(ngModel)]="editedEducation.validUntil"
    (click)="inputClicked($event)"
  />
  <input
    id="validUntil"
    class="w-100"
    *ngIf="!this.education.id"
    type="date"
    [(ngModel)]="education.validUntil"
  />
  <label
    id="validUntil"
    [ngClass]="{
      hidden: this.editedCell === 'valid_until' || !this.education.id
    }"
  >
    {{ education.validUntil }}
  </label>
</td>
<td class="text-center">
  <fa-icon
    class="remove-icon fs-6"
    [icon]="['fas', 'trash']"
    (click)="deleteEducation()"
    [ngClass]="{ 'disabled-icon': createMode }"
  ></fa-icon>
</td>
