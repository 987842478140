<div class="d-flex w-100 h-100 base-container">
  <div class="content mw-100 mh-100 d-flex flex-grow-1 pt-3 flex-column">
    <div class="title-container">
      <img
        src="..\..\assets\img\squirrel_standing_tie.png"
        class="title-squirrel-image"
      />
      <h1 class="title">{{ "profile_export.title" | translate }}</h1>
    </div>
    <div class="flex-grow-1 d-flex flex-row mh-100 mw-100 content-container">
      <div
        class="d-flex flex-column mb-3 w-50 me-1 mh-100 p-3 rounded-3 shadow main-container"
      >
        <app-basket [profileExportQueue]="profileExportQueue"></app-basket>
      </div>
      <div
        class="d-flex flex-column mb-3 w-50 ms-1 mh-100 p-3 rounded-3 bordered-container overflow-auto"
      >
        <div class="h-100">
          <app-profile-export-queue
            (profileExportQueueChanged)="profileExportQueueChanged($event)"
          ></app-profile-export-queue>
        </div>
      </div>
    </div>
  </div>
</div>
