<td>
  <input
    id="employee-name"
    *ngIf="!this.plan.employeeId"
    type="text"
    [(ngModel)]="plan.employeeName"
  />
  <label
    class="text-overflow-dotted w-100"
    [ngClass]="{ hidden: !this.plan.projectId }"
    [ngbTooltip]="plan.employeeName"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ plan.employeeName }}
  </label>
</td>

<td>
  <input
    id="project-name"
    *ngIf="!this.plan.projectId"
    type="text"
    [(ngModel)]="plan.projectName"
  />
  <label
    class="text-overflow-dotted w-100"
    [ngClass]="{ hidden: !this.plan.projectId }"
    [ngbTooltip]="plan.projectName"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ plan.projectName }}
  </label>
</td>

<td
  data-testid="start-cell"
  class="text-center"
>
  <input
    id="start-date"
    *ngIf="!this.plan.projectId"
    type="date"
    [(ngModel)]="plan.startDate"
  />
  <label
    class="base-label text-overflow-dotted"
    [ngClass]="{ hidden: !this.plan.projectId }"
    [ngbTooltip]="plan.startDate"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ plan.startDate }}
  </label>
</td>

<td
  data-testid="end-cell"
  class="text-center"
>
  <input
    id="end-date"
    *ngIf="!this.plan.projectId"
    type="date"
    [(ngModel)]="plan.endDate"
  />
  <label
    class="base-label text-overflow-dotted"
    [ngClass]="{ hidden: !this.plan.projectId }"
    [ngbTooltip]="plan.endDate"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ plan.endDate }}
  </label>
</td>

<td>
  <textarea
    id="description"
    *ngIf="!this.plan.employeeId"
    [(ngModel)]="plan.description"
  ></textarea>
  <label
    class="base-label text-overflow-dotted w-100"
    [ngClass]="{ hidden: !this.plan.employeeId}"
    [ngbTooltip]="plan.description"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ plan.description }}
  </label>
</td>

<td class="text-center">
  <input
    id="plan-fte"
    *ngIf="!this.plan.employeeId"
    type="number"
    step="0.25"
    min="0"
    [(ngModel)]="plan.fte"
  />
  <label
    class="base-label text-overflow-dotted"
    [ngClass]="{ hidden: !this.plan.employeeId }"
  >
    {{ plan.fte }}
  </label>
</td>

<td class="text-center">
  <div
    data-testid="project-competency"
    (click)="selectCompetencyCell($event)"
    [ngClass]="{
      selection: plan.projectId && selectedCellProjectId === plan.projectId && plan.employeeId && selectedCellEmployeeId === plan.employeeId
    }"
    class="competency-number"
  >
    {{ plan.competencies!.length }}
  </div>
</td>