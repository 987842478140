<script src="../../dist/vis.js"></script>
<div class="d-flex flex-column justify-content-between h-100">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="flex-grow-1 d-flex flex-row align-items-center search">
      <fa-icon
        class="ms-auto remove-icon"
        [icon]="['fas', 'magnifying-glass']"
      ></fa-icon>
      <input
        data-testid="search-table"
        class="form-control form-control-sm flex-grow-1"
        type="text"
        placeholder="{{ 'employee.search' | translate }}"
        [formControl]="searchText"
      />
    </div>
    <div class="timeline-container" #timeline id="”timeline”">
      <div class="d-flex flex-row toggle-collapse" (click)="toggleEmployees()">
        <div *ngIf="allEmployeesExpanded">
          <fa-icon [icon]="['fas', 'chevron-down']"></fa-icon>
          <label class="ps-2 collapse-all-label">{{
            "plan.collapse_all" | translate
          }}</label>
        </div>
        <div *ngIf="!allEmployeesExpanded">
          <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
          <label class="ps-2 collapse-all-label">{{
            "plan.expand_all" | translate
          }}</label>
        </div>
      </div>
      <ngx-spinner
        name="plan-spinner"
        bdColor="rgba(255,255,255,0)"
        color="#000000"
        [fullScreen]="false"
        size="large"
        [disableAnimation]="true"
        type="ball-fussion"
      ></ngx-spinner>
    </div>
  </div>
</div>