import { Component } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyService, CompanyDTO } from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";

@Component({
  selector: 'app-project-assignments',
  templateUrl: './project-assignments.component.html',
  styleUrl: './project-assignments.component.scss'
})
export class ProjectAssignmentsComponent {
  visibleCompanies: CompanyDTO[] = [];
  competencySidebarDisabled = false;

  constructor(
    private companyService: CompanyService,
    private spinnerService: NgxSpinnerService,
    private competencymanagerService: CompetencyManagerService
  ) {}

  ngOnInit(): void {
    this.refreshCompanies();
    this.competencymanagerService.clearEmployeeProjectCompetency();
    this.competencymanagerService.clearBothCompetencies();
    this.competencymanagerService.employeeProjectCompetency.subscribe((data) => {
      if (!data.projectId) {
        this.competencySidebarDisabled = true;
      } else {
        this.competencySidebarDisabled = false;
      }
    });
  }

  refreshCompanies() {
    this.companyService.getVisibleCompanies()
    .pipe(showLoadingSpinner(this.spinnerService))
    .subscribe((companies) => {
      this.visibleCompanies = [...companies].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    });
  }
}
