import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { finalize, Subscription } from "rxjs";
import {
  LookingForProjectDTO,
  LookingForProjectService,
  UserSelectDTO,
} from "src/shared/api/generated";
import { errorMessage } from "src/shared/constant/error-message.constant";
import { DisplayAndEditEmployeeCompetencyDto } from "src/shared/models/display-and-edit-employee-competency.model";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { convertEmployeeCompetencies } from "src/shared/util/convert-employee-competencies";
import { isDeepEqual } from "src/shared/util/is-deep-equal";

import { ReadOnlyProfileModalComponent } from "../../read-only-profile-modal/read-only-profile-modal.component";
import { LookingForCommentModalComponent } from "../looking-for-comment-modal/looking-for-comment-modal.component";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "[app-tr-looking-for-project]",
  templateUrl: "./tr-looking-for-project.component.html",
  styleUrls: ["./tr-looking-for-project.component.scss"],
})
export class TrLookingForProjectComponent implements OnInit, OnDestroy {
  @Input() lookingForProject: LookingForProjectDTO = {} as LookingForProjectDTO;
  @Input() users: UserSelectDTO[] = [];
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<number, string> = new Map<number, string>();

  @Output() onCommentSaved = new EventEmitter<void>();
  @Output() onLookingForProjectUpdated = new EventEmitter<LookingForProjectDTO>();

  editLookingForProject: LookingForProjectDTO = {} as LookingForProjectDTO;
  selectedCellEmployeeId: number | null = null;
  editedCell: string = "";
  averageOfTop3Competencies: number = 0;
  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService,
    private lookingForProjectService: LookingForProjectService,
    private modalService: NgbModal,
    private confirmationModalService: ConfirmationModalService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.resetEditedLookingForProject();
    this.averageOfTop3Competencies =
      Math.round(this.lookingForProject.averageOfTop3Competencies! * 100) / 100;
    this.subscriptions.add(
      this.competencyManagerService.employeeCompetency.subscribe(
        (data: DisplayAndEditEmployeeCompetencyDto) => {
          this.selectedCellEmployeeId = data.employeeId;
        }
      )
    );
  }

  setEditStateFalse(): void {
    this.editedCell = "";
    this.resetEditedLookingForProject();
  }

  setEditStateTrue(key: string): void {
    this.editedCell = key;
  }

  handleLookingForProjectUpdate(): void {
    if (isDeepEqual(this.editLookingForProject, this.lookingForProject)) return;

    this.updateLookingForProject(this.editLookingForProject);
    this.setEditStateFalse();
  }

  private updateLookingForProject(
    lookingForProject: LookingForProjectDTO
  ): void {
    this.lookingForProjectService
      .updateLookingForProject(lookingForProject)
      .pipe(finalize(() => this.resetEditedLookingForProject()))
      .subscribe({
        next: (savedLookingForProject) => {
          this.lookingForProject = savedLookingForProject;
          this.onLookingForProjectUpdated.emit(savedLookingForProject);
        },
        error: (error) => this.handleLookingForProjectSaveError(error),
      });
  }

  private handleLookingForProjectSaveError(error: HttpErrorResponse): void {
    if (error.error.message === errorMessage.lookingForProjectAlreadyExists) {
      this.confirmationModalService.openConfirmationModal(
        "home.looking_for_project.error.title",
        "home.looking_for_project.error.already_has_active_entry",
        true
      );
    }
  }

  resetEditedLookingForProject(): void {
    this.editLookingForProject = JSON.parse(
      JSON.stringify(this.lookingForProject)
    );
  }

  selectCompetencyCell($event: MouseEvent): void {
    this.inputClicked($event);
    if (this.lookingForProject.employeeId === this.selectedCellEmployeeId) {
      this.competencyManagerService.clearEmployeeCompetency();
      return;
    }
    this.competencyManagerService.setEmployeeCompetency(
      true,
      this.lookingForProject.employeeId,
      this.lookingForProject.employeeName,
      this.lookingForProject.competencies
    );
  }

  isSearchResult(): boolean {
    if (!this.search || !this.searchTerm) {
      return false;
    }
    return (
      convertEmployeeCompetencies(this.lookingForProject.competencies).filter(
        (competency) => {
          return !!this.allCompetencies
            .get(competency.id)
            ?.toLowerCase()
            .includes(this.searchTerm?.toLowerCase() ?? "");
        }
      ).length !== 0
    );
  }

  inputClicked($event: MouseEvent): void {
    $event.stopPropagation();
  }

  openReadOnlyProfile(id: number) {
    const modalRef = this.modalService.open(ReadOnlyProfileModalComponent, {
      windowClass: "profile-modal-window",
      scrollable: true,
    });
    modalRef.componentInstance.employeeId = id;
  }

  openCommentEditModal($event: MouseEvent): void {
    this.inputClicked($event);
    const modalRef = this.modalService.open(LookingForCommentModalComponent, {
      windowClass: "custom-modal-window",
    });
    modalRef.componentInstance.lookingForEntity = { ...this.lookingForProject };
    modalRef.componentInstance.entityName = this.lookingForProject.employeeName;
    modalRef.componentInstance.mode = "looking-for-project";
    modalRef.closed.subscribe((result) => {
      if (result) this.onCommentSaved.emit();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
