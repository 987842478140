export * from './basket.service';
import { BasketService } from './basket.service';
export * from './company.service';
import { CompanyService } from './company.service';
export * from './competency.service';
import { CompetencyService } from './competency.service';
export * from './competencyGroup.service';
import { CompetencyGroupService } from './competencyGroup.service';
export * from './education.service';
import { EducationService } from './education.service';
export * from './employee.service';
import { EmployeeService } from './employee.service';
export * from './employeeCompetency.service';
import { EmployeeCompetencyService } from './employeeCompetency.service';
export * from './importCompetency.service';
import { ImportCompetencyService } from './importCompetency.service';
export * from './lookingForProject.service';
import { LookingForProjectService } from './lookingForProject.service';
export * from './lookingForResource.service';
import { LookingForResourceService } from './lookingForResource.service';
export * from './pdfExport.service';
import { PdfExportService } from './pdfExport.service';
export * from './plan.service';
import { PlanService } from './plan.service';
export * from './position.service';
import { PositionService } from './position.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './projectCompetency.service';
import { ProjectCompetencyService } from './projectCompetency.service';
export * from './readOnlyProfile.service';
import { ReadOnlyProfileService } from './readOnlyProfile.service';
export * from './smartSearch.service';
import { SmartSearchService } from './smartSearch.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './userConfigController.service';
import { UserConfigControllerService } from './userConfigController.service';
export const APIS = [BasketService, CompanyService, CompetencyService, CompetencyGroupService, EducationService, EmployeeService, EmployeeCompetencyService, ImportCompetencyService, LookingForProjectService, LookingForResourceService, PdfExportService, PlanService, PositionService, ProfileService, ProjectService, ProjectCompetencyService, ReadOnlyProfileService, SmartSearchService, UserService, UserConfigControllerService];
