<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    (click)="activeModal.close(false)"
  ></button>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div *ngIf="!error" class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary secondary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "confirmation_modal.cancel" | translate }}
  </button>
  <button
    type="button"
    class="btn btn-danger primary-btn"
    (click)="activeModal.close(true)"
  >
    {{ "confirmation_modal.confirm" | translate }}
  </button>
</div>
<div *ngIf="error" class="modal-footer">
  <button
    type="button"
    class="btn primary-btn"
    (click)="activeModal.close(false)"
  >
    {{ "confirmation_modal.ok" | translate }}
  </button>
</div>
