import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { map, Observable } from "rxjs";
import {
  PositionDTO,
  PositionService,
} from "src/shared/api/generated";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";

@Component({
  selector: "[app-tr-role-management]",
  templateUrl: "./tr-role-management.component.html",
  styleUrls: ["./tr-role-management.component.scss"],
})
export class TrRoleManagementComponent implements OnInit {
  @Input() position: PositionDTO = {} as PositionDTO;

  @Output() positionDeleted = new EventEmitter<void>();
  @Output() positionAlreadyExists = new EventEmitter<void>();

  editedPosition: PositionDTO = {} as PositionDTO;
  editedCell: string = "";

  constructor(
    private positionService: PositionService,
    private confirmationModalService: ConfirmationModalService
  ) { }

  ngOnInit(): void {
    this.resetEditedPosition();
  }

  handlePositionUpdate() {
    if (this.editedPosition.type === "") {
      this.setEditStateFalse();
      return;
    }

    this.updatePosition({
      ...this.editedPosition,
      type: this.editedPosition.type.trim(),
    });
  }

  private updatePosition(position: PositionDTO): void {
    this.positionService
      .updatePosition({
        id: position.id,
        description: position.description,
        type: position.type,
      })
      .subscribe({
        next: (savedPosition) => {
          this.position = savedPosition;
          this.setEditStateFalse();
        },
        error: (error) => this.handlePositionSaveError(error),
      });
  }

  private handlePositionSaveError(error: HttpErrorResponse): void {
    if (error.status === HttpStatusCode.BadRequest) {
      this.positionAlreadyExists.emit();
    }
  }

  onDeletePosition(positionId: number) {
    this.hasAssignments(positionId).subscribe((hasAssignments) => {
      if (!hasAssignments) {
        this.deletePosition(positionId);
        return;
      }

      this.confirmationModalService.openConfirmationModal(
        "settings.role_management.delete_error",
        "settings.role_management.delete_dialog_message",
        true
      );
    });
  }

  hasAssignments(positionId: number): Observable<boolean> {
    return this.positionService
      .getNumberOfUsagesOfPosition(positionId)
      .pipe(map((numberOfAssignments: number) => numberOfAssignments > 0));
  }

  deletePosition(positionId: number) {
    this.positionService.deletePosition(positionId).subscribe({
      next: () => {
        this.positionDeleted.emit();
      },
      error: (error) => {
        if (error.status === HttpStatusCode.BadRequest) {
          this.confirmationModalService.openConfirmationModal(
            "settings.role_management.delete_error",
            "settings.role_management.delete_dialog_message",
            true
          );
        } else {
          console.error("Could not delete the position", error);
        }
      },
    });
  }

  setEditStateFalse() {
    this.editedCell = "";
    this.resetEditedPosition();
  }

  setEditStateTrue(key: string) {
    this.editedCell = key;
  }

  resetEditedPosition() {
    this.editedPosition = JSON.parse(JSON.stringify(this.position));
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
