import { NgxSpinnerService } from "ngx-spinner";
import { catchError, defer, distinctUntilChanged, forkJoin, map, merge, Observable, of, share, startWith, takeUntil, timeout, timer } from "rxjs";

export function showLoadingSpinner<T>(spinnerService: NgxSpinnerService) {
  return (source$: Observable<T>): Observable<T> => {
    return defer(() => {
      const result$ = source$.pipe(timeout(20000), share());

      const showLoadingIndicator$ = merge(
        timer(250).pipe(
          map(() => true),
          takeUntil(result$)
        ),

        forkJoin([result$, timer(750)]).pipe(
          map(() => false),
          catchError(() => of(false))
        )
      ).pipe(startWith(false), distinctUntilChanged());

      showLoadingIndicator$.subscribe((isLoading: boolean) => {
        if (isLoading) {
          spinnerService.show();
        } else {
          spinnerService.hide();
        }
      });

      return result$;
    });
  };
}
