import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BasketService } from "src/shared/api/generated";

@Component({
  selector: "app-create-basket-modal",
  templateUrl: "./create-basket-modal.component.html",
  styleUrls: ["./create-basket-modal.component.scss"],
})
export class CreateBasketModalComponent {
  basketNameForm = new FormControl<string>("", Validators.required);

  constructor(
    private basketService: BasketService,
    public activeModal: NgbActiveModal
  ) {}

  saveBasket(): void {
    if (!this.basketNameForm.value) {
      this.basketNameForm.markAllAsTouched();
      return;
    }

    this.basketService
      .createBasket({ name: this.basketNameForm.value })
      .subscribe((newBasket) => this.activeModal.close(newBasket));
  }

  validateName(): void {
    if (!this.basketNameForm.value) return;
    this.basketNameForm.setValue(this.basketNameForm.value.trim());
  }
}
