import { Component, Input, OnDestroy } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import {
  PlanMemberDTO,
  PlanService,
  ProjectService,
} from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-project-members-modal",
  templateUrl: "./project-members-modal.component.html",
  styleUrls: ["./project-members-modal.component.scss"],
})
export class ProjectMembersModalComponent implements OnDestroy {
  @Input() projectId: number = -1;
  @Input() projectName: string = "";
  @Input() projectMembers: PlanMemberDTO[] = [] as PlanMemberDTO[];
  @Input() allCompetencies: Map<number, string> = new Map();
  @Input() addMode: boolean = false;

  projectMembersModified: boolean = false;
  editedMemberPlan: PlanMemberDTO = {} as PlanMemberDTO;

  constructor(
    private projectService: ProjectService,
    private planService: PlanService,
    private spinnerService: NgxSpinnerService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnDestroy(): void {
    this.cancelEditing();
  }

  convertToNumber(num: string): number {
    return parseInt(num);
  }

  refreshMembers(): void {
    this.planService
      .getMembersByProjectId(this.projectId)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (members) => {
          this.projectMembers = members;
        },
      });
  }

  saveMemberPlan(): void {
    this.projectService
      .modifyMemberPlanDescription(
        this.editedMemberPlan.planId!,
        this.editedMemberPlan.description
      )
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (updatedMember) => {
          this.projectMembers.find(
            (c) => c.planId === updatedMember.planId
          )!.description = updatedMember.description;
          this.resetEditedMemberPlan();
          this.projectMembersModified = true;
        },
      });
  }

  setEditedMemberPlan(memberPlan: PlanMemberDTO): void {
    this.editedMemberPlan = JSON.parse(JSON.stringify(memberPlan));
  }

  cancelEditing(): void {
    this.resetEditedMemberPlan();
  }

  resetEditedMemberPlan(): void {
    this.editedMemberPlan = {} as PlanMemberDTO;
  }

  openAddMemberDialog(): void {
    this.addMode = true;
  }

  onMemberAddCancelled(): void {
    this.addMode = false;
  }

  onMemberAddConfirmed(): void {
    this.addMode = false;
    this.projectMembersModified = true;
    this.refreshMembers();
  }
}
