<app-base-modal
  [activeModal]="activeModal"
  [title]="'basket.create_basket_modal.create_basket' | translate"
>
  <div class="content">
    <form class="form">
      <div class="form-row">
        <label for="basketName" class="row-label">{{
          "basket.create_basket_modal.basket_name" | translate
        }}</label>
        <div class="wrapper">
          <input
            id="basketName"
            type="text"
            [formControl]="basketNameForm"
            (blur)="validateName()"
          />
          <span
            *ngIf="
              basketNameForm?.hasError('required') && basketNameForm?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
    </form>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveBasket()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
