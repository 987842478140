import { Component, OnDestroy, ViewChild } from "@angular/core";
import { NgbAccordionDirective, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription, forkJoin } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  CompetencyGroupService,
  CompetencyService,
  ProjectCompetencyDTO,
  ProjectCompetencyService,
} from "src/shared/api/generated";
import { DisplayAndEditProjectCompetencyDto } from "src/shared/models/display-and-edit-project-competency.model";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import { PermissionService } from "src/shared/services/permission.service";
import { ProjectCompetencyModalComponent } from "../competency-modal/project-competency-modal/project-competency-modal.component";
@Component({
  selector: "app-project-competency",
  templateUrl: "./project-competency.component.html",
  styleUrls: ["./project-competency.component.scss"],
})
export class ProjectCompetencyComponent implements OnDestroy {
  @ViewChild("projectCompetencyAccordion")
  projectCompetencyAccordion!: NgbAccordionDirective;
  searchText: string = "";
  errorFetchingCompetencies: boolean = false;
  editedProjectCompetency: DisplayAndEditProjectCompetencyDto =
    {} as DisplayAndEditProjectCompetencyDto;
  editProjectCompetenciesMode: boolean = false;
  levelEditedProjectCompetency: ProjectCompetencyDTO | null = null;
  competencyGroups: CompetencyGroupDTO[] = [];
  selectableCompetencyTypes: CompetencyGroupDTO[] = [];

  public competencies: CompetencyDTO[] = [];
  public projectCompetencies: {
    id: number;
    competency: CompetencyDTO;
    level: number;
  }[] = [];
  public filteredProjectCompetencies: {
    id: number;
    competency: CompetencyDTO;
    level: number;
  }[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(
    private modalService: NgbModal,
    private competencyService: CompetencyService,
    private competencyManagerService: CompetencyManagerService,
    private competencyGroupService: CompetencyGroupService,
    private projectCompetencyService: ProjectCompetencyService,
    private confirmationModalService: ConfirmationModalService,
    private spinnerService: NgxSpinnerService,
    public permissionService: PermissionService,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.subscriptions.add(
      this.competencyManagerService.projectCompetency.subscribe(
        (data: DisplayAndEditProjectCompetencyDto) => {
          if (this.editedProjectCompetency.projectId !== data.projectId) {
            this.clearEditing();
          }
          this.editedProjectCompetency = data;
          if (data.projectId) {
            this.filterCompetencyArrays(this.competencies);
            this.editProjectCompetenciesMode = true;
          } else {
            this.editProjectCompetenciesMode = false;
          }
        }
      )
    );
  }

  initialLoad() {
    forkJoin([
      this.getRootCompetencyGroups(),
      this.getSelectableCompetencyGroups(),
      this.competencyService.getAllCompetencies(),
    ])
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: ([rootGroups, selectableGroups, competencies]) => {
          this.competencyGroups = rootGroups;
          this.selectableCompetencyTypes = selectableGroups;
          this.filterCompetencyArrays(competencies);
        },
        error: () => (this.errorFetchingCompetencies = true),
      });
  }

  getRootCompetencyGroups() {
    return this.competencyGroupService.getAllRootCompetencyGroups();
  }

  getSelectableCompetencyGroups() {
    return this.competencyGroupService.getAllSelectableCompetencyGroups();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSearchInput() {
    const searchTerm = this.searchText.trim().toLowerCase();
    if (searchTerm === "") {
      this.filteredProjectCompetencies = this.projectCompetencies;
    } else {
      this.filteredProjectCompetencies = this.projectCompetencies.filter(
        (competency) =>
          competency.competency.name.toLowerCase().includes(searchTerm)
      );
      this.expandCategoriesWithSearchResult();
    }
  }

  collapseAllCategories() {
    this.projectCompetencyAccordion.collapseAll();
  }

  expandCategoriesWithSearchResult() {
    for (let type of this.competencyGroups) {
      this.expandProjectCompetencyCategories(type);
    }
  }

  private expandProjectCompetencyCategories(type: CompetencyGroupDTO) {
    this.expandCompetencyCategoriesWithSearchResult(
      type,
      this.filteredProjectCompetencies.map((c) => c.competency),
      this.projectCompetencyAccordion
    );
  }

  expandCompetencyCategoriesWithSearchResult(
    type: CompetencyGroupDTO,
    competencies: CompetencyDTO[],
    accordion: NgbAccordionDirective
  ) {
    const filteredCompetenciesNumber =
      this.getNumberOfCompetenciesInCategoryFromArray(competencies, type.id);
    if (filteredCompetenciesNumber !== 0) {
      accordion.expand(type.name);
    } else {
      accordion.collapse(type.name);
    }
  }

  public getCompetencies(): void {
    this.competencyService
      .getAllCompetencies()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (response) => {
          this.filterCompetencyArrays(response);
        },
        error: () => {
          this.errorFetchingCompetencies = true;
        },
      });
  }

  filterCompetencyArrays(competencyArray: CompetencyDTO[]) {
    this.competencies = [...competencyArray].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    if (this.editedProjectCompetency.projectId) {
      this.projectCompetencies =
        this.editedProjectCompetency.competencyList.map((obj) => ({
          id: obj.id!,
          competency: this.competencies.find((c) => c.id === obj.competencyId)!,
          level: obj.level,
        }));
      this.filteredProjectCompetencies = this.projectCompetencies;
    }
    this.onSearchInput();
    this.errorFetchingCompetencies = false;
  }

  deleteEditedCompetency(competency: {
    id: number;
    competency: CompetencyDTO;
    level: number;
  }) {
    if (
      this.editedProjectCompetency.projectId &&
      !this.levelEditedProjectCompetency
    ) {
      this.deleteProjectCompetency(competency);
    }
  }

  deleteProjectCompetency(competency: {
    id: number;
    competency: CompetencyDTO;
    level: number;
  }) {
    this.projectCompetencyService
      .deleteProjectCompetency(competency.id)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((_) => {
        this.removeFromEditedProjectCompetencyList(competency);
        this.refreshEditedProjectCompetency();
        this.filterCompetencyArrays(this.competencies);
      });
  }

  getRootGroupName(id: number) {
    return this.competencyGroups.find((c) => c.id === id)!.name;
  }

  onStartEditingLevel(competency: {
    id: number;
    competency: CompetencyDTO;
    level: number;
  }) {
    if (
      this.editedProjectCompetency.displayOnly ||
      this.levelEditedProjectCompetency
    ) {
      return;
    }
    this.levelEditedProjectCompetency = {
      ...competency,
      competencyId: competency.competency.id!,
      projectId: this.editedProjectCompetency.projectId!,
    };
  }

  onCancelLevelEditing() {
    if (this.levelEditedProjectCompetency) {
      this.refreshEditedProjectCompetency();
    }
    this.levelEditedProjectCompetency = null;
  }

  onSaveLevelEditing() {
    this.saveEditedProjectCompetency();
  }

  openLevelEditingErrorModal(error: any) {
    if (error.error.message === "Level cannot be more than 5") {
      this.confirmationModalService.openConfirmationModal(
        "project.error.title",
        "project.error.competency_more_than_5",
        true
      );
    } else if (error.error.message === "Level cannot be less than 1") {
      this.confirmationModalService.openConfirmationModal(
        "project.error.title",
        "project.error.competency_less_than_1",
        true
      );
    }
  }

  saveEditedProjectCompetency() {
    this.projectCompetencyService
      .updateProjectCompetency(this.levelEditedProjectCompetency!)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (updatedCompetency: ProjectCompetencyDTO) => {
          this.editedProjectCompetency.competencyList.find(
            (c) => c.id === updatedCompetency.id
          )!.level = updatedCompetency.level;
          this.refreshEditedProjectCompetency();
          this.levelEditedProjectCompetency = null;
        },
        error: (error) => {
          this.openLevelEditingErrorModal(error);
        },
      });
  }

  private removeFromEditedProjectCompetencyList(competency: {
    id: number;
    competency: CompetencyDTO;
    level: number;
  }) {
    this.editedProjectCompetency.competencyList =
      this.editedProjectCompetency.competencyList
        .filter((c) => c.id !== competency.id)
        .sort((a, b) =>
          this.getCompetencyName(a.competencyId).localeCompare(
            this.getCompetencyName(b.competencyId)
          )
        );
  }

  getCompetencyName(id: number): string {
    return (
      this.competencies.find((competency) => competency.id === id)?.name ?? ""
    );
  }

  refreshEditedProjectCompetency() {
    this.competencyManagerService.setProjectCompetency(
      this.editedProjectCompetency.displayOnly,
      this.editedProjectCompetency.projectId!,
      this.editedProjectCompetency.projectName!,
      this.editedProjectCompetency.competencyList
    );
  }

  clearEditing() {
    this.onCancelLevelEditing();
  }

  getNumberOfCompetenciesInCategoryFromArray(
    array: CompetencyDTO[],
    typeId: number
  ): number {
    return array
      .filter((c) => c.competencyGroup.rootGroupId === typeId)
      .sort((a, b) => a.name.localeCompare(b.name)).length;
  }

  getProjectCompetenciesInCategoryFromArray(
    array: {
      id: number;
      competency: CompetencyDTO;
      level: number;
    }[],
    typeId: number
  ): {
    id: number;
    competency: CompetencyDTO;
    level: number;
  }[] {
    return array
      .filter((c) => c.competency.competencyGroup.rootGroupId === typeId)
      .sort((a, b) => a.competency.name.localeCompare(b.competency.name));
  }

  openAssignCompetencyModal(): void {
    const modalRef = this.modalService.open(ProjectCompetencyModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
      keyboard: false,
      backdrop: "static",
    });
    modalRef.componentInstance.allCompetencies = this.competencies;
    modalRef.componentInstance.assignedCompetencies =
      this.projectCompetencies.map((competency) => {
        return { level: competency.level, id: competency.competency.id };
      });
    modalRef.componentInstance.competencyGroups = this.competencyGroups;
    modalRef.componentInstance.selectableCompetencyGroups =
      this.selectableCompetencyTypes;
    modalRef.componentInstance.projectId =
      this.editedProjectCompetency.projectId;
    modalRef.closed.subscribe(
      (result: {
        isNewCompetency: boolean;
        isNewAssignedCompetency: boolean;
        competencies: ProjectCompetencyDTO[] | null;
      }) => {
        if (result.isNewCompetency) {
          this.getCompetencies();
        }
        if (result.isNewAssignedCompetency && result.competencies) {
          this.editedProjectCompetency.competencyList = [
            ...this.editedProjectCompetency.competencyList,
            ...result.competencies,
          ];
          this.refreshEditedProjectCompetency();
        }
      }
    );
  }
}
