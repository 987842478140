<div
  class="d-flex flex-column h-100 shadow rounded-3 company-separated-display-container"
>
  <ul
    ngbNav
    #nav="ngbNav"
    [activeId]="getActiveTab()"
    [animation]="animation"
    class="nav-tabs rounded-top-3"
    [destroyOnHide]="true"
  >
    <li *ngFor="let company of companies; let i = index" [ngbNavItem]="i">
      <button
        ngbNavLink
        (dblclick)="setCompanyNameEditable(company)"
        class="nav-link"
      >
        <input
          *ngIf="
            currentlyEditedCompany !== null &&
            company.id === currentlyEditedCompany!.id
          "
          #name
          type="text"
          [readonly]="company.id !== currentlyEditedCompany!.id"
          appPrefixFocusAndSelect
          [value]="company.name"
          (keydown.enter)="saveCompany(company.id, name.value)"
          (keydown.escape)="cancelEditing()"
          (focusout)="saveCompany(company.id, name.value)"
          class="company-name-input"
        />
        <label
          *ngIf="
            currentlyEditedCompany === null ||
            company.id !== currentlyEditedCompany!.id
          "
          class="company-name-label text-overflow-dotted"
        >
          {{ company.name }}
        </label>
      </button>
      <ng-template ngbNavContent>
        <ng-container *ngTemplateOutlet="getTemplate(i)"></ng-container>
      </ng-template>
    </li>
    <li
      class="nav-item"
      role="presentation"
      *ngIf="permissionService.checkAdmin() && permissionService.checkGlobal()"
    >
      <button
        type="button"
        class="nav-link"
        (click)="add()"
        [disabled]="editingState"
      >
        <fa-icon [icon]="['fas', 'plus']" class="nav-btn"></fa-icon>
      </button>
    </li>
  </ul>
  <div
    [ngbNavOutlet]="nav"
    class="mh-100 p-3 rounded-bottom-3 flex-grow-1 bordered-container"
  ></div>
</div>
