/**
 * RA Documentation
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface LookingForResourceCreateDTO { 
    status: LookingForResourceCreateDTO.StatusEnum;
    assigneeId: number;
    projectId: number;
    requestStart?: string;
    requestEnd?: string;
    fte: number;
    comment?: string;
    toAfterFrom?: boolean;
}
export namespace LookingForResourceCreateDTO {
    export type StatusEnum = 'WAITING' | 'IN_PROGRESS' | 'DONE';
    export const StatusEnum = {
        Waiting: 'WAITING' as StatusEnum,
        InProgress: 'IN_PROGRESS' as StatusEnum,
        Done: 'DONE' as StatusEnum
    };
}


