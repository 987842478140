import { DragDropModule } from "@angular/cdk/drag-drop";
import { CdkMenuModule } from "@angular/cdk/menu";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import {
  FaIconLibrary,
  FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";
import { faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import {
  faComment as faCommentRegular,
  faFile as faFileRegular,
  faEye as faEyeRegular,
  faEyeSlash as faEyeSlashRegular,
} from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faArrowRightFromBracket,
  faBan,
  faBarsStaggered,
  faBasketShopping,
  faCalendarDays,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleInfo,
  faCirclePlus,
  faCircleUser,
  faComment,
  faCopy,
  faEarthAmericas,
  faEye as faEyeSolid,
  faEyeSlash as faEyeSlashSolid,
  faFile,
  faGear,
  faHouseChimney,
  faMagnifyingGlass,
  faMinus,
  faPen,
  faPlus,
  faRotateLeft,
  faTrash,
  faUserTie,
  faXmark,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerModule } from "ngx-spinner";
import { environment } from "src/environments/environment";
import {
  ApiModule,
  Configuration,
  ConfigurationParameters,
} from "src/shared/api/generated";
import { FocusOnShowDirective } from "src/shared/directives/autofocus";
import { CompanyMarker } from "src/shared/directives/company-marker";
import { NgbdSortableHeader } from "src/shared/directives/ngbd-sortable-header.directive";
import { RequestInterceptorService } from "src/shared/interceptor/auth-interceptor.service";
import { TruncatePipe } from "src/shared/pipes/string-truncate.pipe";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ApplicationComponent } from "./application/application.component";
import { BaseModalComponent } from "./application/base-modal/base-modal.component";
import { BasketFloatingButtonComponent } from "./application/basket-floating-button/basket-floating-button.component";
import { CompanySeparatedDisplayComponent } from "./application/company-separated-display/company-separated-display.component";
import { AddOrEditCompetencyModalComponent } from "./application/competency/add-or-edit-competency-modal/add-or-edit-competency-modal.component";
import { CompetencyPillContainerComponent } from "./application/competency/competency-pill-container/competency-pill-container.component";
import { CompetencyPillComponent } from "./application/competency/competency-pill-container/competency-pill/competency-pill.component";
import { NewCompetencyPillComponent } from "./application/competency/competency-pill-container/new-competency-pill/new-competency-pill.component";
import { CompetencyComponent } from "./application/competency/competency.component";
import { EmployeeCompetencyComponent } from "./application/competency/employee-competency/employee-competency.component";
import { ProjectCompetencyComponent } from "./application/competency/project-competency/project-competency.component";
import { ConfirmationModalComponent } from "./application/confirmation-modal/confirmation-modal.component";
import { EducationModalComponent } from "./application/employee/education-modal/education-modal.component";
import { TableEducationComponent } from "./application/employee/education-modal/table-education/table-education.component";
import { TrEducationComponent } from "./application/employee/education-modal/tr-education/tr-education.component";
import { EmployeeProjectsModalComponent } from "./application/employee/employee-projects-modal/employee-projects-modal.component";
import { EmployeeComponent } from "./application/employee/employee.component";
import { TableEmployeeComponent } from "./application/employee/table-employee/table-employee.component";
import { TrEmployeeComponent } from "./application/employee/tr-employee/tr-employee.component";
import { CreateLookingForProjectModalComponent } from "./application/home/create-looking-for-project-modal/create-looking-for-project-modal.component";
import { CreateLookingForResourceModalComponent } from "./application/home/create-looking-for-resource-modal/create-looking-for-resource-modal.component";
import { HomeComponent } from "./application/home/home.component";
import { LookingForCommentModalComponent } from "./application/home/looking-for-comment-modal/looking-for-comment-modal.component";
import { TableLookingForProjectComponent } from "./application/home/table-looking-for-project/table-looking-for-project.component";
import { TableLookingForResourceComponent } from "./application/home/table-looking-for-resource/table-looking-for-resource.component";
import { TrLookingForProjectComponent } from "./application/home/tr-looking-for-project/tr-looking-for-project.component";
import { TrLookingForResourceComponent } from "./application/home/tr-looking-for-resource/tr-looking-for-resource.component";
import { CreatePlanModalComponent } from "./application/plan/create-plan-modal/create-plan-modal.component";
import { ModifyPlanModalComponent } from "./application/plan/modify-plan-modal/modify-plan-modal.component";
import { PlanTimelineComponent } from "./application/plan/plan-timeline/plan-timeline.component";
import { PlanComponent } from "./application/plan/plan.component";
import { BasketComponent } from "./application/profile-export/basket/basket.component";
import { CreateBasketModalComponent } from "./application/profile-export/basket/create-basket-modal/create-basket-modal.component";
import { ModifyProfileModalComponent } from "./application/profile-export/basket/modify-profile-modal/modify-profile-modal.component";
import { TableBasketEmployeeComponent } from "./application/profile-export/basket/table-basket-employee/table-basket-employee.component";
import { TrBasketEmployeeComponent } from "./application/profile-export/basket/tr-basket-employee/tr-basket-employee.component";
import { ProfileExportQueueComponent } from "./application/profile-export/profile-export-queue/profile-export-queue.component";
import { TableProfileExportQueueComponent } from "./application/profile-export/profile-export-queue/table-profile-export-queue/table-profile-export-queue.component";
import { TrProfileExportQueueComponent } from "./application/profile-export/profile-export-queue/tr-profile-export-queue/tr-profile-export-queue.component";
import { ProfileExportComponent } from "./application/profile-export/profile-export.component";
import { AddMemberComponent } from "./application/project-members-modal/add-member/add-member.component";
import { ProjectMembersModalComponent } from "./application/project-members-modal/project-members-modal.component";
import { ProjectComponent } from "./application/project/project.component";
import { TableProjectComponent } from "./application/project/table-project/table-project.component";
import { TrProjectComponent } from "./application/project/tr-project/tr-project.component";
import { ReadOnlyProfileModalComponent } from "./application/read-only-profile-modal/read-only-profile-modal.component";
import { CompanyMultiSelectDropdownComponent } from "./application/settings/company-multi-select-dropdown/company-multi-select-dropdown.component";
import { CompetencyImportComponent } from "./application/settings/competency-import/competency-import.component";
import { SettingsComponent } from "./application/settings/settings.component";
import { TableCompetencyManagementComponent } from "./application/settings/table-competency-management/table-competency-management.component";
import { TableUserManagementComponent } from "./application/settings/table-user-management/table-user-management.component";
import { TrCompetencyManagementComponent } from "./application/settings/tr-competency-management/tr-competency-management.component";
import { TrUserManagementComponent } from "./application/settings/tr-user-management/tr-user-management.component";
import { SideToolbarComponent } from "./application/side-toolbar/side-toolbar.component";
import { SidebarComponent } from "./application/sidebar/sidebar.component";
import { CompetencySearchInputComponent } from "./application/smart-search/search-sidebar/competency-search-input/competency-search-input.component";
import { SearchSidebarComponent } from "./application/smart-search/search-sidebar/search-sidebar.component";
import { SmartSearchComponent } from "./application/smart-search/smart-search.component";
import { TableSmartSearchResultComponent } from "./application/smart-search/table-smart-search-result/table-smart-search-result.component";
import { AuthenticationComponent } from "./authentication/authentication.component";
import { CompetencyModalComponent } from "./application/competency/competency-modal/competency-modal.component";
import { EmployeeCompetencyModalComponent } from "./application/competency/competency-modal/employee-competency-modal/employee-competency-modal.component";
import { ProjectCompetencyModalComponent } from "./application/competency/competency-modal/project-competency-modal/project-competency-modal.component";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.apiUrl,
  };
  return new Configuration(params);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PlanComponent,
    EmployeeComponent,
    ProjectComponent,
    SidebarComponent,
    SideToolbarComponent,
    AuthenticationComponent,
    ApplicationComponent,
    CompetencyComponent,
    ConfirmationModalComponent,
    CompanySeparatedDisplayComponent,
    CompanyMarker,
    FocusOnShowDirective,
    TrEmployeeComponent,
    TableEmployeeComponent,
    TrProjectComponent,
    TableProjectComponent,
    NgbdSortableHeader,
    TableLookingForResourceComponent,
    TrLookingForResourceComponent,
    TableLookingForProjectComponent,
    TrLookingForProjectComponent,
    CreateLookingForProjectModalComponent,
    CreateLookingForResourceModalComponent,
    PlanTimelineComponent,
    CreatePlanModalComponent,
    ModifyPlanModalComponent,
    ProjectMembersModalComponent,
    ProjectCompetencyComponent,
    EmployeeCompetencyComponent,
    SmartSearchComponent,
    SearchSidebarComponent,
    CompetencySearchInputComponent,
    TableSmartSearchResultComponent,
    SettingsComponent,
    TableUserManagementComponent,
    TrUserManagementComponent,
    ProfileExportComponent,
    AddOrEditCompetencyModalComponent,
    BasketFloatingButtonComponent,
    CreateBasketModalComponent,
    BasketComponent,
    TableBasketEmployeeComponent,
    TrBasketEmployeeComponent,
    ModifyProfileModalComponent,
    ProfileExportQueueComponent,
    TableProfileExportQueueComponent,
    TrProfileExportQueueComponent,
    CompanyMultiSelectDropdownComponent,
    CompetencyImportComponent,
    ReadOnlyProfileModalComponent,
    LookingForCommentModalComponent,
    EducationModalComponent,
    TableEducationComponent,
    TrEducationComponent,
    TableCompetencyManagementComponent,
    TrCompetencyManagementComponent,
    CompetencyPillContainerComponent,
    CompetencyPillComponent,
    NewCompetencyPillComponent,
    TruncatePipe,
    EmployeeProjectsModalComponent,
    AddMemberComponent,
    BaseModalComponent,
    CompetencyModalComponent,
    EmployeeCompetencyModalComponent,
    ProjectCompetencyModalComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    AppRoutingModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: "en",
    }),
    FontAwesomeModule,
    ApiModule.forRoot(apiConfigFactory),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    CdkMenuModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faCircleUser,
      faHouseChimney,
      faCalendarDays,
      faUserTie,
      faBarsStaggered,
      faGoogle,
      faMicrosoft,
      faChevronLeft,
      faChevronRight,
      faChevronDown,
      faPlus,
      faMinus,
      faTrash,
      faCheck,
      faXmark,
      faFile,
      faCircleInfo,
      faCircleExclamation,
      faMagnifyingGlass,
      faCirclePlus,
      faPen,
      faBan,
      faGear,
      faArrowRightFromBracket,
      faAddressCard,
      faRotateLeft,
      faBasketShopping,
      faEyeSolid,
      faEyeSlashSolid,
      faEyeRegular,
      faEyeSlashRegular,
      faComment,
      faCommentRegular,
      faFileRegular,
      faEarthAmericas,
      faCopy
    );
  }
}
