import { HttpErrorResponse, HttpStatusCode } from "@angular/common/http";
import { Component, Input } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { catchError, Observable, throwError } from "rxjs";
import {
  CompetencyGroupDTO,
  CompetencyService,
  CreateOrUpdateCompetencyDTO,
} from "src/shared/api/generated";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";

@Component({
  selector: "app-add-or-edit-competency-modal",
  templateUrl: "./add-or-edit-competency-modal.component.html",
  styleUrls: ["./add-or-edit-competency-modal.component.scss"],
})
export class AddOrEditCompetencyModalComponent {
  @Input() competency!: CreateOrUpdateCompetencyDTO;
  @Input() competencyGroups: CompetencyGroupDTO[] = [];
  @Input() createMode: boolean = false;

  error: boolean = false;
  errorMessage: string = "";
  competencyForm!: FormGroup;

  ngOnInit(): void {
    this.competencyForm = new FormGroup({
      id: new FormControl<number | undefined>(this.competency.id),
      name: new FormControl<string>(this.competency.name, Validators.required),
      competencyGroupId: new FormControl<number>(
        this.competency.competencyGroupId,
        Validators.required
      ),
    });
  }

  constructor(
    public activeModal: NgbActiveModal,
    private competencyService: CompetencyService,
    private competencyManagerService: CompetencyManagerService,
    private translate: TranslateService
  ) {}

  closeAlert(): void {
    this.error = false;
    this.errorMessage = "";
  }

  saveCompetency(): void {
    if (this.competencyForm.invalid) {
      this.competencyForm.markAllAsTouched();
      return;
    }
    const competencyToSave: CreateOrUpdateCompetencyDTO =
      this.competencyForm.value;
    const competencyObservable = this.createMode
      ? this.competencyService.createCompetency(competencyToSave)
      : this.competencyService.updateCompetency(competencyToSave);

    competencyObservable
      .pipe(catchError((error) => this.handleCompetencySaveError(error)))
      .subscribe((competency) => {
        this.competencyManagerService.setCompetencyCreated();
        this.activeModal.close(competency);
      });
  }

  trimText(value: string): void {
    this.competencyForm
      .get("name")
      ?.setValue(value.trim(), { emitEvent: false });
  }

  private handleCompetencySaveError(
    error: HttpErrorResponse
  ): Observable<never> {
    if (error.status === HttpStatusCode.Conflict) {
      this.error = true;
      this.errorMessage = this.translate.instant(
        "competency.competency_add_modal.already_exists"
      );
      return throwError(
        () =>
          new Error(
            this.translate.instant(
              "competency.competency_add_modal.already_exists"
            )
          )
      );
    }
    return throwError(() => error.error);
  }
}
