<div class="d-flex flex-column justify-content-between h-100">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="d-flex flex-row justify-content-between table-actions">
      <div class="flex-grow-1 d-flex flex-row align-items-center search">
        <ngx-select-dropdown *ngIf="originalProjects.length"
            data-testid="employee"
            [config]="employeeSelectConfig"
            [options]="originalEmployees"
            [formControl]="selectedEmployees"
            [multiple]="true"
            (change)="employeeSelectChange()"
            class="select"
          ></ngx-select-dropdown>

          <ngx-select-dropdown *ngIf="originalProjects.length"
          data-testid="project"
          [config]="projectSelectConfig"
          [options]="originalProjects"
          [formControl]="selectedProjects"
          [multiple]="true"
          (change)="projectSelectChange()"
          class="select"
          ></ngx-select-dropdown>
      </div>
      <div>
        <button
          *ngIf="!createProjectMode && !cloneProjectMode"
          class="btn primary-btn ms-2"
          (click)="addEmptyProject()"
        >
          {{ "project_assignments.add" | translate }}
        </button>
        <button
          *ngIf="isAdmin"
          [disabled]="selectedPlanId === -1"
          class="btn btn-secondary delete-row-btn ms-2"
          (click)="openDeleteConfirmationModal(selectedPlanId)"
          ngbTooltip="{{ 'project_assignments.delete' | translate }}"
          container="body"
          placement="top"
        >
          <fa-icon [icon]="['fas', 'trash']"></fa-icon>
        </button>
      </div>
    </div>
    <div *ngIf="!originalProjects.length" class="image-container">
      <img src="..\..\assets\img\squirrel_sleep.png" />
    </div>
    <div *ngIf="originalProjects.length" class="table-container">
      <table class="table table-light table-hover table-striped table-bordered align-middle scrollable-table">
        <thead>
          <tr>
            <th scope="col" sortable="name" (sort)="onSort($event)" class="table-header text-center">
              {{ "project_assignments.employee_name" | translate }}
            </th>
            <th scope="col" sortable="name" (sort)="onSort($event)" class="table-header text-center">
              {{ "project_assignments.project_name" | translate }}
            </th>
            <th scope="col" sortable="start" (sort)="onSort($event)" class="table-header text-center">
              {{ "project_assignments.start" | translate }}
            </th>
            <th scope="col" sortable="end" (sort)="onSort($event)" class="table-header text-center">
              {{ "project_assignments.end" | translate }}
            </th>
            <th scope="col" sortable="description" (sort)="onSort($event)" class="table-header text-center">
              {{ "project_assignments.description" | translate }}
            </th>
            <th scope="col" sortable="fte" (sort)="onSort($event)" class="table-header text-center">
              {{ "project_assignments.fte" | translate }}
            </th>
            <th scope="col" sortable="competencies" (sort)="onSort($event)" class="table-header text-center">
              {{ "project_assignments.competencies" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for (plan of transformedPlans; track plan.employeeId + '-' + plan.projectId + '-' + plan.planId) {
          <tr app-tr-project-assignments [plan]="plan" [allCompetencies]="competencies" [searchTerm]="" [search]="search"
            (onMembersSaved)="refreshPlans()" (click)="selectRow(plan.employeeId!, plan.employeeName!, plan.projectId!, plan.planId!)"
            (dblclick)="openEditItemDialog(plan)" [ngClass]="{
              selected: selectedRowEmployee === plan.employeeId && selectedRowProject === plan.projectId && selectedPlanId === plan.planId
            }"></tr>
          }
        </tbody>
      </table>
    </div>
    <div *ngIf="originalProjects.length"
      class="d-flex justify-content-between align-items-top p-2">
      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
        (pageChange)="refreshPlans()" class="pages">
      </ngb-pagination>
      <div class="information">
        {{ transformedPlans.slice(0, pageSize).length }} /
        {{ collectionSize }}
      </div>
    </div>
  </div>
</div>