<td (dblclick)="setEditStateTrue('name')">
  <input
    id="project-name"
    *ngIf="this.editedCell === 'name' && this.project.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleProjectUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleProjectUpdate()"
    [(ngModel)]="editProject.name"
    (click)="inputClicked($event)"
  />
  <input
    id="project-name"
    *ngIf="!this.project.id"
    type="text"
    [(ngModel)]="project.name"
  />
  <label
    class="text-overflow-dotted w-100"
    [ngClass]="{ hidden: this.editedCell === 'name' || !this.project.id }"
    [ngbTooltip]="project.name"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ project.name }}
  </label>
</td>
<td (dblclick)="setEditStateTrue('alternative_name')">
  <input
    id="alternative-project-name"
    *ngIf="this.editedCell === 'alternative_name' && this.project.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleProjectUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleProjectUpdate()"
    [(ngModel)]="editProject.alternativeName"
    (click)="inputClicked($event)"
  />
  <input
    id="alternative-project-name"
    *ngIf="!this.project.id"
    type="text"
    [(ngModel)]="project.alternativeName"
  />
  <label
    class="text-overflow-dotted w-100"
    [ngClass]="{
      hidden: this.editedCell === 'alternative_name' || !this.project.id
    }"
    ngbTooltip="{{ project.alternativeName }}"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ project.alternativeName }}
  </label>
</td>
<td (dblclick)="setEditStateTrue('description')">
  <textarea
    id="description"
    *ngIf="this.editedCell === 'description' && this.project.id"
    appPrefixFocusAndSelect
    (keydown.enter)="handleProjectUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleProjectUpdate()"
    [(ngModel)]="editProject.description"
    (click)="inputClicked($event)"
  ></textarea>
  <textarea
    id="description"
    *ngIf="!this.project.id"
    [(ngModel)]="project.description"
  ></textarea>
  <label
    class="base-label text-overflow-dotted w-100"
    [ngClass]="{
      hidden: this.editedCell === 'description' || !this.project.id
    }"
    [ngbTooltip]="project.description"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ project.description }}
  </label>
</td>
<td
  data-testid="start-cell"
  (dblclick)="setEditStateTrue('start')"
  class="text-center"
>
  <input
    id="start-date"
    *ngIf="this.editedCell === 'start' && this.project.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="handleProjectUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleProjectUpdate()"
    [(ngModel)]="editProject.start"
    (click)="inputClicked($event)"
  />
  <input
    id="start-date"
    *ngIf="!this.project.id"
    type="date"
    [(ngModel)]="project.start"
  />
  <label
    class="base-label text-overflow-dotted"
    [ngClass]="{ hidden: this.editedCell === 'start' || !this.project.id }"
    [ngbTooltip]="project.start"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ project.start }}
  </label>
</td>
<td
  data-testid="end-cell"
  (dblclick)="setEditStateTrue('end')"
  class="text-center"
>
  <input
    id="end-date"
    *ngIf="this.editedCell === 'end' && this.project.id"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="handleProjectUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleProjectUpdate()"
    [(ngModel)]="editProject.end"
    (click)="inputClicked($event)"
  />
  <input
    id="end-date"
    *ngIf="!this.project.id"
    type="date"
    [(ngModel)]="project.end"
  />
  <label
    class="base-label text-overflow-dotted"
    [ngClass]="{ hidden: this.editedCell === 'end' || !this.project.id }"
    [ngbTooltip]="project.end"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ project.end }}
  </label>
</td>
<td class="text-center">
  <input
    id="internal-project"
    *ngIf="this.project.id"
    class="form-check-input"
    type="checkbox"
    role="switch"
    (change)="handleProjectUpdate()"
    [(ngModel)]="editProject.internal"
    [disabled]="this.isEditingDisabled()"
    (click)="inputClicked($event)"
  />
  <input
    id="internal-project"
    *ngIf="!this.project.id"
    class="form-check-input"
    type="checkbox"
    role="switch"
    [(ngModel)]="project.internal"
  />
</td>
<td>
  <select
    id="project-state"
    *ngIf="this.project.id"
    (change)="handleProjectUpdate()"
    [(ngModel)]="editProject.projectState"
    [disabled]="this.isEditingDisabled() || this.isProjectStateSelectDisabled()"
    (click)="inputClicked($event)"
  >
    <option value="ACTIVE">
      {{ "project.state.ACTIVE" | translate }}
    </option>
    <option value="PLANNED">
      {{ "project.state.PLANNED" | translate }}
    </option>
    <option value="CLOSED">
      {{ "project.state.CLOSED" | translate }}
    </option>
    <option value="PROSPECT">
      {{ "project.state.PROSPECT" | translate }}
    </option>
  </select>
  <select
    id="project-state"
    *ngIf="!this.project.id"
    [(ngModel)]="project.projectState"
  >
    <option value="ACTIVE">
      {{ "project.state.ACTIVE" | translate }}
    </option>
    <option value="PLANNED">
      {{ "project.state.PLANNED" | translate }}
    </option>
    <option value="CLOSED">
      {{ "project.state.CLOSED" | translate }}
    </option>
    <option value="PROSPECT">
      {{ "project.state.PROSPECT" | translate }}
    </option>
  </select>
</td>
<td (dblclick)="setEditStateTrue('fte')" class="text-center">
  <input
    id="project-fte"
    *ngIf="this.editedCell === 'fte' && this.project.id"
    type="number"
    step="0.25"
    min="0"
    appPrefixFocusAndSelect
    (keydown.enter)="handleProjectUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleProjectUpdate()"
    [(ngModel)]="editProject.fte"
    (click)="inputClicked($event)"
  />
  <input
    id="project-fte"
    *ngIf="!this.project.id"
    type="number"
    step="0.25"
    min="0"
    [(ngModel)]="project.fte"
  />
  <label
    class="base-label text-overflow-dotted"
    [ngClass]="{ hidden: this.editedCell === 'fte' || !this.project.id }"
  >
    {{ project.fte }}
  </label>
</td>

<td class="text-center">
  <div
    data-testid="project-competency"
    (click)="selectCompetencyCell($event)"
    [ngClass]="{
      selection: project.id && selectedCellProjectId === project.id,
      'search-find': isCompetencySearchResult(),
      'disabled-icon': createMode || cloneMode
    }"
    class="competency-number"
  >
    {{ competencies.length }}
  </div>
</td>

<td class="text-center members">
  <div
    data-testid="project-members"
    (click)="selectMemberCell($event)"
    [ngClass]="{
      selection: project.id && selectedMemberCellProjectId === project.id,
      'search-find': isMemberSearchResult(),
      'disabled-icon': createMode || cloneMode
    }"
    class="members-number"
  >
    {{ project.members?.length }}
  </div>
</td>
