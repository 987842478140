<div class="main-container">
  <div *ngIf="editProjectCompetenciesMode; else selectProject" class="d-flex flex-column h-100">
    <div class="labels">
      <div class="text-overflow-dotted">
        {{ editedProjectCompetency.projectName }}
      </div>
    </div>
    <div class="search-bar pt-3 px-2">
      <input
        type="search"
        class="form-control"
        placeholder="{{ 'competency.search_competency' | translate }}"
        #searchInput
        data-testid="competency-search-input"
        [(ngModel)]="searchText"
        (input)="onSearchInput()"
      />
      <button
        *ngIf="!editedProjectCompetency.displayOnly"
        class="btn primary-btn ms-2"
        (click)="openAssignCompetencyModal()"
      >
        {{ "competency.assign_competency" | translate }}
      </button>
    </div>
    <ng-container *ngIf="!errorFetchingCompetencies; else errorMessage">
      <div class="competency-container">
        <div class="full-height py-2 px-2">
          <div class="competency-list full-height scrollable-list">
            <div
              id="project-competency-accordion"
              ngbAccordion
              #projectCompetencyAccordion="ngbAccordion"
              class="w-100"
            >
              <div
                ngbAccordionItem="{{ type.name }}"
                *ngFor="let type of competencyGroups"
              >
                <h2 ngbAccordionHeader>
                  <button ngbAccordionButton>
                    <div class="category-header">
                      <div>
                        {{ type.name }}
                      </div>
                      <span
                        *ngIf="type.name == 'Language'"
                        ngbTooltip="{{
                          'competency.language_level_tooltip' | translate
                        }}"
                        placement="bottom"
                        container="body"
                        class="ms-1"
                        ><fa-icon [icon]="['fas', 'circle-info']"></fa-icon
                      ></span>
                      <div class="ms-auto me-4">
                        {{
                          getProjectCompetenciesInCategoryFromArray(
                            filteredProjectCompetencies,
                            type.id
                          ).length
                        }}
                      </div>
                    </div>
                  </button>
                </h2>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <div>
                        <div
                          class="competency-item"
                          *ngFor="
                            let competency of getProjectCompetenciesInCategoryFromArray(
                              filteredProjectCompetencies,
                              type.id
                            )
                          "
                        >
                          <div class="d-flex flex-row align-items-center w-100">
                            <span
                              class="text-overflow-dotted competency-name"
                              >{{
                                competency.competency.name
                              }}</span
                            >
                            <ng-container
                              *ngIf="
                                levelEditedProjectCompetency?.id !==
                                  competency.id && competency.id;
                                else levelEditMode
                              "
                              ><span class="ms-auto competency-level">
                                <label>{{ competency.level }}</label>
                                <fa-icon
                                  *ngIf="!editedProjectCompetency.displayOnly"
                                  (click)="onStartEditingLevel(competency)"
                                  class="edit-level-icon"
                                  [icon]="['fas', 'pen']"
                                ></fa-icon>
                              </span>
                              <fa-icon
                                *ngIf="
                                  !editedProjectCompetency.displayOnly &&
                                  levelEditedProjectCompetency?.id !==
                                    competency.id &&
                                  competency.id
                                "
                                class="add-remove-icon ms-3"
                                [icon]="['fas', 'xmark']"
                                (click)="deleteEditedCompetency(competency)"
                              ></fa-icon
                            ></ng-container>
                            <ng-template #levelEditMode>
                              <span class="ms-auto competency-level">
                                <input
                                  class="level-input-box"
                                  type="number"
                                  min="1"
                                  max="5"
                                  step="1"
                                  [(ngModel)]="
                                    levelEditedProjectCompetency!.level
                                  "
                                  (keydown.enter)="onSaveLevelEditing()"
                                  (keydown.escape)="onCancelLevelEditing()"
                                  (blur)="onSaveLevelEditing()"
                                  (click)="$event.stopPropagation()"
                                />
                              </span>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #errorMessage>
      <div class="error-message pt-5 px-3">
        {{ "competency.errorFetchingCompetencies" | translate }}
      </div>
    </ng-template>
  </div>
  <ng-template #selectProject>
    <div class="error-message pt-5 px-3">
      {{ "competency.select_project" | translate }}
    </div>
  </ng-template>
</div>
