<app-base-modal
  [title]="'employee.education_modal.title' | translate"
  [secondaryTitle]="employeeName"
  [activeModal]="activeModal"
  [result]="educationModified"
>
  <div class="content">
    <app-table-education
      [employeeId]="employeeId"
      (educationSaved)="onEducationSaved()"
      (educationSaveFailed)="onEducationSaveFailed($event)"
    ></app-table-education>
  </div>
  <div *ngIf="error" class="error">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
      {{ errorMessage }}
    </ngb-alert>
  </div>
  <button
    modal-actions
    class="btn secondary-btn"
    (click)="closeEducationModal()"
  >
    {{ "button.close" | translate }}
  </button>
</app-base-modal>
