import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs";
import {
  CompanyDTO,
  CompanyService,
  EmployeeService,
  EmployeeUserInfoDTO,
  UserService,
} from "src/shared/api/generated";
import { errorMessage } from "src/shared/constant/error-message.constant";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-table-user-management",
  templateUrl: "./table-user-management.component.html",
  styleUrls: ["./table-user-management.component.scss"],
})
export class TableUserManagementComponent implements OnInit {
  employeesWithUserInfo: EmployeeUserInfoDTO[] = [];
  companies: CompanyDTO[] = [];
  selectedCompanies: CompanyDTO[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "name", direction: "asc" };
  selectedRow: number = -1;
  searchText = new FormControl("");

  showErrorToast: boolean = false;
  errorMessageKey: string = "";

  createEmployeeMode: boolean = false;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private employeeService: EmployeeService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.initialLoad();
    this.searchEmployeesWithUserInfo();
  }

  getEmployeesWithUserInfoBySearchTerm(searchTerm: string = "") {
    if (this.createEmployeeMode) {
      this.cancelCreateEmployee();
    }
    return this.userService.findAllEmployeesWithUserInfo(
      this.selectedCompanies.map((c) => c.id!),
      searchTerm.trim(),
      this.pageSize,
      this.page - 1,
      this.sorting.column,
      this.sorting.direction
    );
  }

  initialLoad() {
    this.fetchCompanies()
      .pipe(
        switchMap((companies) => {
          this.companies = companies;
          this.selectedCompanies = companies;
          return this.getEmployeesWithUserInfoBySearchTerm();
        }),
        showLoadingSpinner(this.spinnerService)
      )
      .subscribe((employees) => {
        this.selectedRow = -1;
        this.employeesWithUserInfo = employees.content ?? [];
        this.collectionSize = employees.totalElements ?? 0;
      });
  }

  addEmptyEmployee() {
    this.employeesWithUserInfo.unshift({
      userId: -1,
      admin: false,
      global: false,
      sales: false,
      visibleCompanies: [],
      employeeId: undefined,
      name: "",
      email: "",
      companyId: this.companies[0].id!,
      internal: true,
      active: true,
      planned: true,
    });
    this.collectionSize++;
    this.createEmployeeMode = true;
  }

  cancelCreateEmployee(): void {
    this.collectionSize--;
    this.createEmployeeMode = false;
    this.employeesWithUserInfo = this.employeesWithUserInfo.filter(
      (employee) => employee.employeeId
    );
  }

  isCreateDisabled(): boolean {
    let createdEmployee = this.employeesWithUserInfo.find(
      (employee) => !employee.employeeId
    );
    return (
      createdEmployee?.name.trim() === "" ||
      createdEmployee?.email.trim() === ""
    );
  }

  createEmployee() {
    const employeeToCreate = this.employeesWithUserInfo.find(
      (employee) => !employee.employeeId
    )!;
    this.employeeService
      .createEmployeeFromSettings({
        ...employeeToCreate,
        name: employeeToCreate.name.trim(),
        email: employeeToCreate.email.trim(),
      })
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (createdEmployee) => {
          let index = this.employeesWithUserInfo.findIndex(
            (employee) => !employee.employeeId
          );
          this.employeesWithUserInfo[index] = { ...createdEmployee };
          this.createEmployeeMode = false;
        },
        error: (error) => this.handleEmployeeSaveError(error),
      });
  }

  handleEmployeeSaveError(error: HttpErrorResponse): void {
    if (error.error.message === errorMessage.employeeAlreadyExists) {
      this.errorMessageKey = "employee.error.duplicate_email";
    }
    if (error.error.message === errorMessage.invalidEmailFormat) {
      this.errorMessageKey = "employee.error.invalid_email_format";
    }
    this.showErrorToast = true;
  }

  searchEmployeesWithUserInfo() {
    this.searchText.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((text) =>
          this.getEmployeesWithUserInfoBySearchTerm(text ?? "").pipe(
            showLoadingSpinner(this.spinnerService)
          )
        )
      )
      .subscribe((employeePage) => {
        this.selectedRow = -1;
        this.employeesWithUserInfo = employeePage.content ?? [];
        this.collectionSize = employeePage.totalElements ?? 0;
      });
  }

  refreshEmployeesWithUserInfo() {
    return this.getEmployeesWithUserInfoBySearchTerm(
      this.searchText.value ?? ""
    )
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((employeePage) => {
        this.selectedRow = -1;
        this.employeesWithUserInfo = employeePage.content ?? [];
        this.collectionSize = employeePage.totalElements ?? 0;
      });
  }

  fetchCompanies() {
    return this.companyService.getAllCompanies();
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshEmployeesWithUserInfo();
  }

  selectRow(id: number) {
    if (this.createEmployeeMode) {
      return;
    }
    if (this.selectedRow === id) {
      this.selectedRow = -1;
    } else {
      this.selectedRow = id;
    }
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }

  companySelectionChanged(selectedCompanies: CompanyDTO[]) {
    if (
      JSON.stringify(this.selectedCompanies.map((c) => c.id)) ===
      JSON.stringify(selectedCompanies.map((c) => c.id))
    ) {
      return;
    }
    this.page = 1;
    this.selectedCompanies = selectedCompanies;
    this.refreshEmployeesWithUserInfo();
  }
}
