import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  EmployeeCompetencyDTO,
  EmployeeCompetencyService,
} from "src/shared/api/generated";

interface SelectedCompetency {
  level: number;
  id: number;
}

@Component({
  selector: "app-employee-competency-modal",
  templateUrl: "./employee-competency-modal.component.html",
  styleUrl: "./employee-competency-modal.component.scss",
})
export class EmployeeCompetencyModalComponent {
  @Input() employeeId: number = -1;
  @Input() allCompetencies: CompetencyDTO[] = [];
  @Input() assignedCompetencies: SelectedCompetency[] = [];
  @Input() selectableCompetencyGroups: CompetencyGroupDTO[] = [];
  @Input() competencyGroups: CompetencyGroupDTO[] = [];
  filterAction: Function = (
    available: CompetencyDTO[],
    selected: CompetencyDTO[] | SelectedCompetency[]
  ) => this.filter(available, selected);
  saveAction: Function = (competencies: any[]) =>
    this.saveCompetencies(competencies);

  constructor(private readonly api: EmployeeCompetencyService) {}

  filter(
    available: CompetencyDTO[],
    selected: CompetencyDTO[] | SelectedCompetency[]
  ): CompetencyDTO[] {
    return available.filter(
      (availableCompetency) =>
        !selected.filter(
          (selectedCompetency) =>
            selectedCompetency.id === availableCompetency.id
        ).length
    );
  }

  saveCompetencies(competencies: any[]): Observable<EmployeeCompetencyDTO[]> {
    const body: EmployeeCompetencyDTO[] = competencies.map((competency) => {
      return {
        employeeId: this.employeeId,
        competencyId: competency.competencyId,
        level: competency.level,
      };
    });
    return this.api.createMultipleEmployeeCompetency(body);
  }
}
