export const USER_CONFIG_KEY = 'userConfig';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum Language {
  EN = 'en',
  HU = 'hu',
}