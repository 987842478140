<app-base-modal
  [title]="translate.instant('competency.competency_assign_modal.title')"
  [activeModal]="activeModal"
  [closeAction]="closeAction"
>
  <div class="content">
    <div class="search-bar py-3 px-2">
      <input
        type="search"
        class="form-control"
        placeholder="{{ 'competency.search_competency' | translate }}"
        #searchInput
        data-testid="competency-search-input"
        [(ngModel)]="searchText"
        (input)="onSearchInput()"
      />
      <button
        class="btn primary-btn ms-2"
        (click)="newCompetency(searchInput.value)"
        [disabled]="isAddButtonDisabled()"
      >
        {{ "competency.add_competency" | translate }}
      </button>
    </div>
    <div
      class="competency-container d-flex flex-row flex-fill justify-content-between align-items-stretch"
    >
      <div class="competency-list">
        <div
          id="non-employee-competency-accordion"
          ngbAccordion
          #competencyAccordion="ngbAccordion"
          class="w-100"
        >
          <div
            ngbAccordionItem="{{ type.name }}"
            *ngFor="let type of competencyGroups"
          >
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>
                <div class="category-header">
                  <div>
                    {{ type.name }}
                  </div>
                  <div class="ms-auto me-4">
                    {{
                      getCompetenciesInCategoryFromArray(
                        filteredCompetencies,
                        type.id
                      ).length
                    }}
                  </div>
                </div>
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div>
                    <div
                      class="competency-item"
                      *ngFor="
                        let competency of getCompetenciesInCategoryFromArray(
                          filteredCompetencies,
                          type.id
                        )
                      "
                    >
                      <div class="d-flex flex-row align-items-center w-100">
                        <span class="text-overflow-dotted competency-name">
                          <fa-icon
                            class="add-remove-icon"
                            [icon]="['fas', 'plus']"
                            (click)="addCompetency(competency)"
                          ></fa-icon>
                          {{ competency.name }}</span
                        >
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="competency-list" [formGroup]="selectedCompetencyForm">
        <table
          class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
        >
          <thead>
            <tr>
              <th scope="col" class="table-header text-center"></th>
              <th scope="col" class="table-header text-center">
                {{
                  "competency.competency_assign_modal.competency_header"
                    | translate
                }}
              </th>
              <th *ngIf="isLevelShown" scope="col" class="table-header text-center">
                {{
                  "competency.competency_assign_modal.level_header" | translate
                }}
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="competencies">
              <ng-container
                *ngFor="
                  let competencyForm of competencies.controls;
                  let i = index
                "
              >
                <tr [formGroupName]="i">
                  <td class="text-center">
                    <fa-icon
                      class="add-remove-icon"
                      [icon]="['fas', 'minus']"
                      (click)="removeCompetency(i)"
                    ></fa-icon>
                  </td>
                  <td>
                    {{ competencyForm.get("competencyName")!.value }}
                    <span
                      *ngIf="competencyForm.hasError('wrongLevel')"
                      ngbTooltip="{{
                        'competency.competency_assign_modal.level_error'
                          | translate
                      }}"
                      placement="bottom"
                      container="body"
                      class="ms-1 warning-tooltip"
                    >
                      <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
                    </span>
                    <span
                      *ngIf="competencyForm.hasError('isDuplicate')"
                      ngbTooltip="{{
                        'competency.competency_assign_modal.duplicate_competency'
                          | translate
                      }}"
                      placement="bottom"
                      container="body"
                      class="ms-1 warning-tooltip"
                    >
                      <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
                    </span>
                    <span
                      *ngIf="competencyForm.hasError('notUnique')"
                      ngbTooltip="{{
                        'competency.competency_assign_modal.existing_competency'
                          | translate
                      }}"
                      placement="bottom"
                      container="body"
                      class="ms-1 warning-tooltip"
                    >
                      <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
                    </span>
                  </td>
                  <td *ngIf="isLevelShown" class="text-center">
                    <input
                      class="level-input-box"
                      type="number"
                      min="1"
                      max="5"
                      step="1"
                      formControlName="level"
                      (change)="checkValidity()"
                    />
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="onClose()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveCompetency()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
