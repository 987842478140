<div class="d-flex flex-column justify-content-between h-100">
  <div class="d-flex flex-column gap-20" style="min-height: 0">
    <div class="d-flex flex-row justify-content-between table-actions">
      <div class="flex-grow-1 d-flex flex-row align-items-center search">
        <fa-icon
          class="ms-auto remove-icon"
          [icon]="['fas', 'magnifying-glass']"
        ></fa-icon>
        <input
          class="form-control form-control-sm flex-grow-1"
          type="text"
          placeholder="{{ 'settings.user_management.search' | translate }}"
          [formControl]="searchText"
        />
      </div>
      <app-company-multi-select-dropdown
        [disabled]="createEmployeeMode"
        (shareCheckedList)="companySelectionChanged($event)"
      ></app-company-multi-select-dropdown>
      <div>
        <button
          *ngIf="!createEmployeeMode"
          class="btn primary-btn ms-2"
          (click)="addEmptyEmployee()"
        >
          {{ "settings.user_management.add" | translate }}
        </button>
        <button
          *ngIf="createEmployeeMode"
          class="btn secondary-btn ms-2"
          (click)="cancelCreateEmployee()"
        >
          {{ "button.cancel" | translate }}
        </button>
        <button
          *ngIf="createEmployeeMode"
          class="btn primary-btn ms-2"
          [disabled]="isCreateDisabled()"
          (click)="createEmployee()"
        >
          {{ "button.save" | translate }}
        </button>
      </div>
    </div>

    <div class="table-container">
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              sortable="name"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "settings.user_management.name" | translate }}
            </th>
            <th
              scope="col"
              sortable="email"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "settings.user_management.email" | translate }}
            </th>
            <th scope="col" class="table-header text-center company">
              {{ "settings.user_management.company" | translate }}
            </th>
            <th
              scope="col"
              sortable="internal"
              (sort)="onSort($event)"
              class="table-header text-center internal"
            >
              {{ "settings.user_management.employment_type" | translate }}
            </th>
            <th
              scope="col"
              sortable="active"
              (sort)="onSort($event)"
              class="table-header text-center status"
            >
              {{ "settings.user_management.status" | translate }}
            </th>
            <th
              scope="col"
              sortable="planned"
              (sort)="onSort($event)"
              class="table-header text-center planned"
            >
              {{ "settings.user_management.planned" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "settings.user_management.user" | translate }}
            </th>
            <th scope="col" class="table-header text-center admin">
              {{ "settings.user_management.admin" | translate }}
            </th>
            <th scope="col" class="table-header text-center sales">
              {{ "settings.user_management.sales" | translate }}
            </th>
            <th
              scope="col"
              class="table-header text-center accessible-companies"
            >
              {{ "settings.user_management.accessible_companies" | translate }}
            </th>
          </tr>
        </thead>
        <tbody>
          @for (employeeWithUserInfo of employeesWithUserInfo; track
          employeeWithUserInfo.employeeId) {
          <tr
            app-tr-user-management
            [employeeWithUserInfo]="employeeWithUserInfo"
            [companies]="companies"
            [createMode]="createEmployeeMode"
            (onUserSaveError)="handleEmployeeSaveError($event)"
            (click)="selectRow(employeeWithUserInfo.employeeId!)"
            [ngClass]="{
              selected: selectedRow === employeeWithUserInfo.employeeId
            }"
          ></tr>
          }
        </tbody>
      </table>
    </div>

    <div
      *ngIf="employeesWithUserInfo.length || searchText.value?.trim()?.length"
      class="d-flex justify-content-between align-items-top p-2"
    >
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshEmployeesWithUserInfo()"
        class="pages"
      >
      </ngb-pagination>
      <div class="information">
        {{ employeesWithUserInfo.slice(0, pageSize).length }} /
        {{ collectionSize }}
      </div>
    </div>
  </div>
</div>

<ngb-toast
  *ngIf="showErrorToast"
  class="error-toast bg-danger text-light"
  [autohide]="true"
  (hidden)="showErrorToast = false"
  [delay]="4000"
  >{{ errorMessageKey | translate }}
</ngb-toast>
