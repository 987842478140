import { Component, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs";
import {
  PositionDTO,
  PositionService,
} from "src/shared/api/generated";
import {
  NgbdSortableHeader,
  SortEvent,
} from "src/shared/directives/ngbd-sortable-header.directive";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { AddOrEditPositionModalComponent } from "./add-or-edit-position-modal/add-or-edit-position-modal.component";

@Component({
  selector: "app-table-role-management",
  templateUrl: "./table-role-management.component.html",
  styleUrls: ["./table-role-management.component.scss"],
})
export class TableRoleManagementComponent implements OnInit {
  positions: PositionDTO[] = [];
  page = 1;
  pageSize = 15;
  collectionSize = 0;
  sorting: SortEvent = { column: "type", direction: "asc" };
  searchText = new FormControl("");

  showErrorToast: boolean = false;

  constructor(
    private positionservice: PositionService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.initialLoad();
    this.searchPositions();
  }

  refreshPositions() {
    this.getPositions(this.searchText.value ?? "")
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((positionsPage) => {
        this.positions = positionsPage.content ?? [];
        this.collectionSize = positionsPage.totalElements ?? 0;
      });
  }

  initialLoad() {
    this.getPositions()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((positionsPage) => {
        this.positions = positionsPage.content ?? [];
        this.collectionSize = positionsPage.totalElements ?? 0;
      });
  }


  searchPositions() {
    this.searchText.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((text) =>
          this.getPositions(text ?? "").pipe(
            showLoadingSpinner(this.spinnerService)
          )
        )
      )
      .subscribe((positionPage) => {
        this.positions = positionPage.content ?? [];
        this.collectionSize = positionPage.totalElements ?? 0;
      });
  }

  getPositions(searchTerm: string = "") {
    return this.positionservice.getAllPagedRoles(
      searchTerm.trim(),
      this.pageSize,
      this.page - 1,
      this.sorting.column,
      this.sorting.direction
    );
  }

  openCreateModal() {
    const modalRef = this.modalService.open(AddOrEditPositionModalComponent);
    modalRef.componentInstance.position = {
      id: undefined,
      type: "",
    };
    modalRef.componentInstance.createMode = true;
    modalRef.closed.subscribe((result) => {
      if (result) {
        this.refreshPositions();
      }
    });
  }

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> =
    new QueryList();

  onSort({ column, direction }: SortEvent) {
    this.headers.forEach((header) => {
      if (header.sortable !== column) {
        header.direction = "";
      }
    });

    this.sorting = { column: column, direction: direction };
    this.refreshPositions();
  }
}
