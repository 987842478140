import { Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  CompetencyCondition,
  CompetencyDTO,
  EmployeeSearchDTO,
} from "src/shared/api/generated";

import { ReadOnlyProfileModalComponent } from "../../read-only-profile-modal/read-only-profile-modal.component";

@Component({
  selector: "app-table-smart-search-result",
  templateUrl: "./table-smart-search-result.component.html",
  styleUrls: ["./table-smart-search-result.component.scss"],
})
export class TableSmartSearchResultComponent {
  @Input() headers: CompetencyDTO[] = [];
  @Input() results: EmployeeSearchDTO[] = [];
  @Input() page: number = 1;
  @Input() pageSize: number = 10;
  @Input() collectionSize: number = 0;
  @Input() conditions: CompetencyCondition[] = [];
  @Input() heatmap: boolean = false;
  @Output() refresh = new EventEmitter<number>();
  @Output() employeeSelectionChanged = new EventEmitter<number[]>();

  selectedRows: number[] = [];

  constructor(public modalService: NgbModal) {}

  getLevel(employee: EmployeeSearchDTO, competencyId: number) {
    return employee.relativeCompetencyMap?.[competencyId];
  }

  refreshResults() {
    this.refresh.emit(this.page);
  }

  emptyEmployeeSelection() {
    this.selectedRows = [];
    this.employeeSelectionChanged.emit(this.selectedRows);
  }

  selectRow(id: number) {
    if (this.selectedRows.includes(id)) {
      this.selectedRows = this.selectedRows.filter((rowId) => rowId !== id);
      this.employeeSelectionChanged.emit(this.selectedRows);
    } else {
      this.selectedRows.push(id);
      this.employeeSelectionChanged.emit(this.selectedRows);
    }
  }

  isInBound(employee: EmployeeSearchDTO, competencyId: number) {
    if (!this.heatmap) {
      return false;
    }
    let competencyCondition = this.conditions.find(
      (condition) => condition.competencyId === competencyId
    );
    return (
      competencyCondition?.lowerBound! <=
        employee.relativeCompetencyMap?.[competencyId]! &&
      employee.relativeCompetencyMap?.[competencyId]! <=
        competencyCondition?.upperBound!
    );
  }

  openReadOnlyProfile(id: number) {
    const modalRef = this.modalService.open(ReadOnlyProfileModalComponent, {
      windowClass: "profile-modal-window",
      scrollable: true,
    });
    modalRef.componentInstance.employeeId = id;
  }
}
