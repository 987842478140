import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Language, Theme } from "src/shared/constant/user-config.constants";
import { ConfigurationService } from "src/shared/services/configuration.service";
import { PermissionService } from "src/shared/services/permission.service";
import { UserConfigStoreService } from "src/shared/services/user-config-store.service";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent {
  language : string = Language.EN;
  theme = Theme.LIGHT;
  languages: string[] = [];
  isAdmin: boolean = false;
  isNotSales: boolean = true;

  constructor(
    private userConfigStore: UserConfigStoreService,
    private configService: ConfigurationService,
    public permissionService: PermissionService,
    public translate: TranslateService,
    private router: Router
  ) {
    this.isAdmin = permissionService.checkAdmin();
    this.isNotSales = permissionService.checkNotSalesUser();
    this.languages = this.translate.getLangs();
    this.userConfigStore.userConfigDto$.subscribe((config) => {
      if (config?.jsonConfig) {
        this.language = (config.jsonConfig as any).language;
        this.theme = (config.jsonConfig as any).theme;
      }
    });
  }

  logout() {
    this.configService.clearToken();
    this.router.navigateByUrl("/login");
  }

  changeLanguage(language: string) {
    this.language = language;    
    this.userConfigStore.setLanguage(this.language);
  }

  toggleTheme() {
    this.theme = this.theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;
    this.userConfigStore.setTheme(this.theme);
  }
}
