<div class="form-group">
  <div class="from-row">
    <input
      id="competencyNameInput"
      type="text"
      [formControl]="selectedCompetency"
      [ngbTypeahead]="searchCompetency"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      [editable]="false"
      (focus)="competencyFocus$.next($any($event).target.value)"
      (click)="competencyClick$.next($any($event).target.value)"
      (selectItem)="selectChanged($event)"
      (keyup)="checkEmpty()"
      #competencyInstance="ngbTypeahead"
    />
    <fa-icon
      class="remove-icon ms-2"
      [icon]="['fas', 'trash']"
      (click)="delete()"
    ></fa-icon>
  </div>
  <div class="from-row bounds">
    <div class="bound">
      <label for="competencyMinInput">{{ "search_sidebar.competency_input.min" | translate }}</label>
      <input
        id="competencyMinInput"
        [ngClass]="{ 'bound-error': boundError }"
        type="number"
        min="1"
        max="5"
        [(ngModel)]="lowerBound"
        (change)="inputChanged()"
      />
    </div>
    <div class="bound">
      <label for="competencyMaxInput">{{ "search_sidebar.competency_input.max" | translate }}</label>
      <input
        id="competencyMaxInput"
        [ngClass]="{ 'bound-error': boundError }"
        type="number"
        min="1"
        max="5"
        [(ngModel)]="upperBound"
        (change)="inputChanged()"
      />
    </div>
  </div>
</div>
