import { Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  ProjectCompetencyDTO,
  ProjectCompetencyService,
} from "src/shared/api/generated";

interface SelectedCompetency {
  level: number;
  id: number;
}

@Component({
  selector: "app-project-competency-modal",
  templateUrl: "./project-competency-modal.component.html",
  styleUrl: "./project-competency-modal.component.scss",
})
export class ProjectCompetencyModalComponent {
  @Input() projectId: number = -1;
  @Input() allCompetencies: CompetencyDTO[] = [];
  @Input() assignedCompetencies: SelectedCompetency[] = [];
  @Input() selectableCompetencyGroups: CompetencyGroupDTO[] = [];
  @Input() competencyGroups: CompetencyGroupDTO[] = [];
filterAction: Function = (
available: CompetencyDTO[],
selected: CompetencyDTO[] | SelectedCompetency[]
) => this.filter(available, selected);
  saveAction: Function = (competencies: any[]) =>
    this.saveCompetencies(competencies);

  constructor(private readonly api: ProjectCompetencyService) {}

  filter(
    available: CompetencyDTO[],
    selected: CompetencyDTO[] | SelectedCompetency[]
  ): CompetencyDTO[] {
    return available.filter(
      (availableCompetency) =>
        !selected.filter(
          (selectedCompetency) =>
            selectedCompetency.id === availableCompetency.id
        ).length
    );
  }

  saveCompetencies(competencies: any[]): Observable<ProjectCompetencyDTO[]> {
    const body: ProjectCompetencyDTO[] = competencies.map((competency) => {
      return {
        projectId: this.projectId,
        competencyId: competency.competencyId,
        level: competency.level,
      };
    });
    return this.api.createMultipleProjectCompetency(body);
  }
}
