<td>
  <label
    class="text-overflow-dotted"
    [ngbTooltip]="lookingForResource.projectName"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ lookingForResource.projectName }}
  </label>
</td>
<td>
  <select
    id="assigneeSelect"
    [(ngModel)]="editLookingForResource.assigneeId"
    (change)="handleLookingForResourceUpdate()"
    (click)="inputClicked($event)"
  >
    <option *ngFor="let user of users" [value]="user.id">
      {{ user.name }}
    </option>
  </select>
</td>
<td>
  <select
    id="statusSelect"
    [(ngModel)]="editLookingForResource.status"
    (change)="handleLookingForResourceUpdate()"
    (click)="inputClicked($event)"
  >
    <option value="WAITING">
      {{ "home.looking_for_resources.status.waiting" | translate }}
    </option>
    <option value="IN_PROGRESS">
      {{ "home.looking_for_resources.status.in_progress" | translate }}
    </option>
    <option value="DONE">
      {{ "home.looking_for_resources.status.done" | translate }}
    </option>
  </select>
</td>
<td class="text-center">
  <fa-icon
    *ngIf="lookingForResource.comment && lookingForResource.comment !== ''"
    class="comment-icon"
    (click)="openCommentEditModal($event)"
    [icon]="['fas', 'comment']"
  ></fa-icon>
  <fa-icon
    *ngIf="!lookingForResource.comment || lookingForResource.comment === ''"
    class="comment-icon"
    (click)="openCommentEditModal($event)"
    [icon]="['far', 'comment']"
  ></fa-icon>
</td>
<td class="text-center text-overflow-dotted">
  <label
    [ngbTooltip]="projectStateLabel"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ projectStateLabel }}
  </label>
</td>
<td class="text-center text-overflow-dotted">
  <label
    [ngbTooltip]="lookingForResource.projectStart"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ lookingForResource.projectStart }}
  </label>
</td>
<td class="text-center text-overflow-dotted">
  <label
    [ngbTooltip]="lookingForResource.projectEnd"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ lookingForResource.projectEnd }}
  </label>
</td>
<td
  (dblclick)="setEditStateTrue('requestStart')"
  class="text-center text-overflow-dotted"
>
  <input
    id="requestStart"
    *ngIf="this.editedCell === 'requestStart'"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="handleLookingForResourceUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleLookingForResourceUpdate()"
    [(ngModel)]="editLookingForResource.requestStart"
    (click)="inputClicked($event)"
  />
  <label
    id="requestStartLabel"
    [ngClass]="{ hidden: this.editedCell === 'requestStart' }"
    [ngbTooltip]="lookingForResource.requestStart"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ lookingForResource.requestStart }}
  </label>
</td>
<td
  (dblclick)="setEditStateTrue('requestEnd')"
  class="text-center text-overflow-dotted"
>
  <input
    id="requestEnd"
    *ngIf="this.editedCell === 'requestEnd'"
    type="date"
    appPrefixFocusAndSelect
    (keydown.enter)="handleLookingForResourceUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleLookingForResourceUpdate()"
    [(ngModel)]="editLookingForResource.requestEnd"
    (click)="inputClicked($event)"
  />
  <label
    id="requestEndLabel"
    [ngClass]="{ hidden: this.editedCell === 'requestEnd' }"
    [ngbTooltip]="lookingForResource.requestEnd"
    [openDelay]="300"
    placement="top"
    container="body"
  >
    {{ lookingForResource.requestEnd }}
  </label>
</td>
<td
  (dblclick)="setEditStateTrue('fte')"
  class="text-center text-overflow-dotted"
>
  <input
    id="fte"
    *ngIf="this.editedCell === 'fte'"
    type="number"
    step="0.25"
    min="0"
    appPrefixFocusAndSelect
    (keydown.enter)="handleLookingForResourceUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleLookingForResourceUpdate()"
    [(ngModel)]="editLookingForResource.fte"
    (click)="inputClicked($event)"
  />
  <label id="fteLabel" *ngIf="this.editedCell !== 'fte'">
    {{ lookingForResource.fte }}
  </label>
</td>
<td class="text-center">
  <div
    (click)="selectCompetencyCell($event)"
    [ngClass]="{
      selection: selectedCellProjectId === lookingForResource.projectId,
      'search-find': isCompetencySearchResult()
    }"
    class="competency-number"
  >
    {{
      lookingForResource.competencies.length
        ? lookingForResource.competencies.length
        : 0
    }}
  </div>
</td>
<td class="text-center">
  <div
    (click)="selectMemberCell($event)"
    [ngClass]="{
      selection: selectedMemberCellProjectId === lookingForResource.projectId,
      'search-find': isMemberSearchResult()
    }"
    class="members-number"
  >
    {{ lookingForResource.members.length }}
  </div>
</td>
