import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "calculateAverage",
})
export class CalculateAveragePipe implements PipeTransform {
  transform(num: number | undefined, zeros: number): number {
    return Math.round(num! * Math.pow(10, zeros)) / Math.pow(10, zeros);
  }
}
