import {
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Subscription } from "rxjs";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";

import { EmployeeProject } from "src/shared/models/employee-project.model";
import { DisplayAndEditEmployeeProjectCompetencyDto } from "src/shared/models/display-and-edit-employee-project-competency.model";

@Component({
  selector: "[app-tr-project-assignments]",
  templateUrl: "./tr-project-assignments.component.html",
  styleUrls: ["./tr-project-assignments.component.scss"],
})
export class TrProjectAssignments implements OnInit, OnDestroy {
  @Input() plan: EmployeeProject = {} as EmployeeProject;
  @Input() searchTerm: string | undefined = "";
  @Input() search: boolean = false;
  @Input() allCompetencies: Map<string, { id: number; level: number; }[]> = new Map<string, { id: number; level: number; }[]>();

  selectedCellProjectId: number | null = null;
  selectedCellEmployeeId: number | null = null;
  competencies: { id: number; level: number; }[] = [];

  subscriptions: Subscription = new Subscription();

  constructor(
    private competencyManagerService: CompetencyManagerService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.competencyManagerService.employeeProjectCompetency.subscribe(
        (data: DisplayAndEditEmployeeProjectCompetencyDto) => {
          this.selectedCellProjectId = data.projectId;
          this.selectedCellEmployeeId = data.employeeId;
          if (data.projectId === this.plan.projectId && data.employeeId === this.plan.employeeId) {
            const key = `${data.employeeId!}-${data.projectId!}`;
            this.competencies = this.allCompetencies.get(key) || [];
          }
        }
      )
    );
    this.competencies = [];
  }

  selectCompetencyCell($event: MouseEvent): void {
    this.inputClicked($event);
    if (this.plan.projectId === this.selectedCellProjectId && this.plan.employeeId === this.selectedCellEmployeeId) {
      this.competencyManagerService.clearProjectCompetency();
      this.competencyManagerService.clearEmployeeCompetency();
      return;
    }
    this.competencyManagerService.setEmployeeProjectCompetency(
      true,
      this.plan.employeeId!,
      this.plan.employeeName!,
      this.plan.projectId!,
      this.plan.projectName!,
      this.plan.competencies || []
    );
  }

  isEditingDisabled(): boolean {
    return !!this.plan.employeeId;
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}