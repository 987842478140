<div class="d-flex flex-column justify-content-between h-100 position-relative">
  <div class="d-flex flex-column main-container" style="min-height: 0">
    <div class="d-flex flex-row justify-content-between table-actions">
      <div class="flex-grow-1 d-flex flex-row align-items-center search">
        <fa-icon
          class="ms-auto remove-icon"
          [icon]="['fas', 'magnifying-glass']"
        ></fa-icon>
        <input
          data-testid="search-table"
          class="form-control form-control-sm flex-grow-1"
          type="text"
          placeholder="{{ 'employee.search' | translate }}"
          [formControl]="searchText"
        />
      </div>
      <div>
        <button
          *ngIf="!createEmployeeMode"
          class="btn primary-btn ms-2"
          (click)="addEmptyEmployee()"
        >
          {{ "employee.add" | translate }}
        </button>
        <button
          *ngIf="createEmployeeMode"
          class="btn secondary-btn ms-2"
          (click)="cancelCreateEmployee()"
        >
          {{ "button.cancel" | translate }}
        </button>
        <button
          *ngIf="createEmployeeMode"
          class="btn primary-btn ms-2"
          [disabled]="isCreateDisabled()"
          (click)="handleEmployeeCreate()"
        >
          {{ "button.save" | translate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="!employees.length && !searchText.value?.trim()?.length"
      class="image-container"
    >
      <img src="..\..\assets\img\squirrel_sleep.png" />
    </div>
    <div
      *ngIf="employees.length || searchText.value?.trim()?.length"
      class="table-container"
    >
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th
              scope="col"
              sortable="name"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "employee.name" | translate }}
            </th>
            <th
              scope="col"
              sortable="email"
              (sort)="onSort($event)"
              class="table-header text-center"
            >
              {{ "employee.email" | translate }}
            </th>
            <th
              scope="col"
              sortable="internal"
              (sort)="onSort($event)"
              class="table-header text-center internal"
            >
              {{ "employee.employment_type" | translate }}
            </th>
            <th scope="col" class="table-header text-center comment">
              {{ "employee.comment" | translate }}
            </th>
            <th scope="col" class="table-header text-center seniority">
              {{ "employee.seniority.title" | translate }}
            </th>
            <th scope="col" class="table-header text-center position">
              {{ "employee.role" | translate }}
            </th>
            <th scope="col" class="table-header text-center profile">
              {{ "employee.cv" | translate }}
            </th>
            <th scope="col" class="table-header text-center education">
              {{ "employee.education" | translate }}
            </th>
            <th scope="col" class="table-header text-center projects">
              {{ "employee.projects" | translate }}
            </th>
            <th scope="col" class="table-header text-center competencies">
              <span
                ngbTooltip="{{ 'employee.competencies_tooltip' | translate }}"
                placement="bottom"
                container="body"
                [positionTarget]="target"
              >
                {{ "employee.competencies" | translate }}
                <span #target
                  ><fa-icon [icon]="['fas', 'circle-info']" size="lg"></fa-icon
                ></span>
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          @for (employee of employees; track employee.id) {
          <tr
            app-tr-employee
            [employee]="employee"
            [positions]="positions"
            [searchTerm]="searchText.value?.trim()"
            [search]="search"
            [allCompetencies]="competencies"
            [createMode]="createEmployeeMode"
            (onEmployeeSaveError)="handleEmployeeSaveError($event)"
            (onEducationSaved)="refreshEmployees()"
            (click)="selectRow(employee.id!)"
            [ngClass]="{
              selected: selectedRows.includes(employee.id!)
            }"
          ></tr>
          }
        </tbody>
      </table>
    </div>
    <div
      *ngIf="employees.length || searchText.value?.trim()?.length"
      class="d-flex justify-content-between align-items-top p-2"
    >
      <ngb-pagination
        [collectionSize]="collectionSize"
        [(page)]="page"
        [pageSize]="pageSize"
        (pageChange)="refreshEmployees()"
        class="pages"
      >
      </ngb-pagination>
      <div class="information">
        {{ employees.slice(0, pageSize).length }} /
        {{ collectionSize }}
      </div>
    </div>
  </div>
  <app-basket-floating-button
    [employeeIds]="selectedRows"
  ></app-basket-floating-button>
</div>

<ngb-toast
  *ngIf="showErrorToast"
  class="error-toast bg-danger text-light"
  [autohide]="true"
  (hidden)="showErrorToast = false"
  [delay]="4000"
  >{{ errorMessageKey | translate }}
</ngb-toast>
