import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { finalize } from "rxjs";
import { EducationDTO, EducationService } from "src/shared/api/generated";
import { isDeepEqual } from "src/shared/util/is-deep-equal";

@Component({
  selector: "[app-tr-education]",
  templateUrl: "./tr-education.component.html",
  styleUrls: ["./tr-education.component.scss"],
})
export class TrEducationComponent implements OnInit {
  @Input() education: EducationDTO = {} as EducationDTO;
  @Input() createMode: boolean = false;

  @Output() educationSaved = new EventEmitter<void>();
  @Output() educationSaveFailed = new EventEmitter<string>();
  @Output() educationDeleted = new EventEmitter<void>();

  editedEducation: EducationDTO = {} as EducationDTO;
  editedCell: string = "";

  ngOnInit(): void {
    this.resetEditedEducation();
  }

  constructor(private educationService: EducationService) {}

  setEditStateTrue(key: string) {
    if (this.createMode) {
      return;
    }

    this.editedCell = key;
  }

  resetEditedEducation() {
    this.editedEducation = JSON.parse(JSON.stringify(this.education));
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }

  isEditingDisabled(): boolean {
    if (this.education.id && this.createMode) {
      return true;
    }
    return false;
  }

  setEditStateFalse(event?: Event) {
    event?.stopPropagation();

    if (this.createMode) {
      return;
    }
    this.resetEditedEducation();
    this.editedCell = "";
  }

  handleEducationUpdate() {
    if (this.createMode) return;

    if (
      isDeepEqual(this.editedEducation, this.education) ||
      this.editedEducation.name.trim() === ""
    ) {
      this.setEditStateFalse();
      return;
    }

    this.saveEducation({
      ...this.editedEducation,
      code: this.editedEducation.code?.trim(),
      name: this.editedEducation.name.trim(),
    });
    this.setEditStateFalse();
  }

  private saveEducation(education: EducationDTO): void {
    this.educationService
      .updateEducation(education)
      .pipe(finalize(() => this.resetEditedEducation()))
      .subscribe({
        next: (savedEducation) => {
          this.education = savedEducation;
          this.educationSaved.emit();
        },
        error: (error) => {
          this.educationSaveFailed.emit(error.error.message);
        },
      });
  }

  deleteEducation() {
    if (!this.education.id || this.createMode) {
      return;
    }
    this.educationService.deleteEducation(this.education.id).subscribe({
      next: () => {
        this.educationDeleted.emit();
      },
    });
  }
}
