import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { catchError, throwError } from "rxjs";
import {
  LookingForProjectDTO,
  LookingForProjectService,
  LookingForResourceDTO,
  LookingForResourceService,
} from "src/shared/api/generated";
import { isDeepEqual } from "src/shared/util/is-deep-equal";

@Component({
  selector: "app-looking-for-comment-modal",
  templateUrl: "./looking-for-comment-modal.component.html",
  styleUrls: ["./looking-for-comment-modal.component.scss"],
})
export class LookingForCommentModalComponent {
  @Input() lookingForEntity: LookingForProjectDTO & LookingForResourceDTO =
    {} as LookingForProjectDTO & LookingForResourceDTO;
  @Input() mode: string = "looking-for-project";
  @Input() entityName: string = "";

  editLookingForEntity: LookingForProjectDTO & LookingForResourceDTO =
    {} as LookingForProjectDTO & LookingForResourceDTO;
  editMode: boolean = false;

  constructor(
    public lookingForProjectService: LookingForProjectService,
    public lookingForResourceService: LookingForResourceService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.resetLookingForEntity();
  }

  saveLookingForProject(): void {
    this.lookingForProjectService
      .updateLookingForProject({
        id: this.editLookingForEntity.id,
        assigneeId: this.editLookingForEntity.assigneeId,
        comment: this.editLookingForEntity.comment,
        status: this.editLookingForEntity.status,
        freeFrom: this.editLookingForEntity.freeFrom,
      })
      .pipe(
        catchError((error) => {
          return throwError(() => error.error);
        })
      )
      .subscribe((response) => this.activeModal.close(response));
  }

  saveLookingForResource(): void {
    this.lookingForResourceService
      .updateLookingForResource({
        id: this.editLookingForEntity.id,
        assigneeId: this.editLookingForEntity.assigneeId,
        comment: this.editLookingForEntity.comment,
        status: this.editLookingForEntity.status,
        requestStart: this.editLookingForEntity.requestStart,
        requestEnd: this.editLookingForEntity.requestEnd,
        fte: this.editLookingForEntity.fte,
      })
      .pipe(
        catchError((error) => {
          return throwError(() => error.error);
        })
      )
      .subscribe((response) => this.activeModal.close(response));
  }

  saveLookingForEntity(): void {
    if (this.mode === "looking-for-project") {
      this.saveLookingForProject();
    } else {
      this.saveLookingForResource();
    }
  }

  setEditMode() {
    this.editMode = !this.editMode;
  }

  isDisabled() {
    return isDeepEqual(this.lookingForEntity, this.editLookingForEntity);
  }

  resetLookingForEntity() {
    this.editLookingForEntity = JSON.parse(
      JSON.stringify(this.lookingForEntity)
    );
  }
}
