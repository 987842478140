<nav class="sidebar">
  <ul class="nav flex-column nav-container">
    <li class="nav-item">
      <div class="nav-icon">
        <a class="nav-link">
          <fa-icon class="profile" [icon]="['fas', 'circle-user']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.home' | translate }}"
        triggers="hover"
      >
        <a class="nav-link" routerLink="/home" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'house-chimney']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.plan' | translate }}"
        triggers="hover"
      >
        <a class="nav-link" routerLink="/plan" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'calendar-days']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.project_assignments' | translate }}"
        triggers="hover"
      >
        <a class="nav-link" routerLink="/project-assignments" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'briefcase']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.employees' | translate }}"
        triggers="hover"
      >
        <a class="nav-link" routerLink="/employee" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'user-tie']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.projects' | translate }}"
        triggers="hover"
      >
        <a class="nav-link" routerLink="/project" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'bars-staggered']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.smart-search' | translate }}"
        triggers="hover"
      >
        <a
          class="nav-link"
          routerLink="/smart-search"
          routerLinkActive="active"
        >
          <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isNotSales">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.profile_export' | translate }}"
        triggers="hover"
      >
        <a
          class="nav-link"
          routerLink="/profile-export"
          routerLinkActive="active"
        >
          <fa-icon [icon]="['fas', 'address-card']"></fa-icon>
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="isAdmin">
      <div
        class="nav-icon"
        placement="end"
        ngbTooltip="{{ 'sidebar.settings' | translate }}"
        triggers="hover"
      >
        <a class="nav-link" routerLink="/settings" routerLinkActive="active">
          <fa-icon [icon]="['fas', 'gear']"></fa-icon>
        </a>
      </div>
    </li>
  </ul>
  <div class="sidebar-actions">
    <div
      ngbDropdown
      container="body"
      placement="top-start"
      class="d-inline-block language-icon"
    >
      <fa-icon
        ngbDropdownToggle
        role="button"
        ngbTooltip="{{ 'sidebar.language' | translate }}"
        placement="end"
        container="body"
        [icon]="['fas', 'earth-americas']"
      ></fa-icon>
      <ul ngbDropdownMenu>
        <li *ngFor="let lang of languages">
          <a
            ngbDropdownItem
            [class.active]="language === lang"
            (click)="changeLanguage(lang)"
            >{{ "language." + lang | translate }}</a
          >
        </li>
      </ul>
    </div>
    <fa-icon
      class="theme-toggle"
      ngbTooltip="{{ 'sidebar.toggle_theme' | translate }}"
      placement="end"
      [icon]="theme === 'light' ? ['fas', 'moon'] : ['fas', 'sun']"
      [style.transform]="theme === 'light' ? 'none' : 'rotate(22.5deg)'"
      (click)="toggleTheme()"
    ></fa-icon>

    <fa-icon
      class="logout"
      ngbTooltip="{{ 'sidebar.logout' | translate }}"
      placement="end"
      [icon]="['fas', 'arrow-right-from-bracket']"
      (click)="logout()"
    ></fa-icon>
  </div>
</nav>
