import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { NgxSpinnerService } from "ngx-spinner";
import { EmployeeProjectDTO, EmployeeService } from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-employee-projects-modal",
  templateUrl: "./employee-projects-modal.component.html",
  styleUrl: "./employee-projects-modal.component.scss",
})
export class EmployeeProjectsModalComponent implements OnInit {
  @Input() employeeId: number = -1;
  @Input() employeeName: string = "";
  @Input() projectIds: number[] = [];

  projects: EmployeeProjectDTO[] = [];

  constructor(
    private employeeService: EmployeeService,
    private spinnerService: NgxSpinnerService,
    public translate: TranslateService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.employeeService
      .getProjectListByEmployeeAndProjectIds(this.employeeId, this.projectIds)
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe({
        next: (projects) => (this.projects = [...projects]),
      });
  }
}
