import { Component, OnInit } from '@angular/core';
import { CompetencyManagerService } from 'src/shared/services/competency-manager.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  noSelectedProject = false;
  noSelectedEmployee = false;
  constructor(private competencyManagerService: CompetencyManagerService) {}

  ngOnInit(): void {
    this.competencyManagerService.clearBothCompetencies();
    this.competencyManagerService.employeeCompetency.subscribe((data) => {
      if (!data.employeeId) {
        this.noSelectedEmployee = true;
      } else {
        this.noSelectedEmployee = false;
      }
    });
    this.competencyManagerService.projectCompetency.subscribe((data) => {
      if (!data.projectId) {
        this.noSelectedProject = true;
      } else {
        this.noSelectedProject = false;
      }
    });
  }
}
