import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./application/home/home.component";
import { PlanComponent } from "./application/plan/plan.component";
import { ProjectAssignmentsComponent } from "./application/project-assignments/project-assignments.component";
import { EmployeeComponent } from "./application/employee/employee.component";
import { ProjectComponent } from "./application/project/project.component";
import { AuthenticationComponent } from "./authentication/authentication.component";
import { ApplicationComponent } from "./application/application.component";
import { checkAdmin, checkNotSalesUser, loginGuardChild } from "src/shared/guard/auth.guard";
import { SmartSearchComponent } from "./application/smart-search/smart-search.component";
import { SettingsComponent } from "./application/settings/settings.component";
import { ProfileExportComponent } from "./application/profile-export/profile-export.component";

const routes: Routes = [
  { path: "login", component: AuthenticationComponent },
  {
    canActivateChild: [loginGuardChild],
    path: "",
    component: ApplicationComponent,
    children: [
      { path: "home", component: HomeComponent, canActivate: [checkNotSalesUser]},
      { path: "plan", component: PlanComponent, canActivate: [checkNotSalesUser] },
      { path: "project-assignments", component: ProjectAssignmentsComponent, canActivate: [checkNotSalesUser] },
      { path: "employee", component: EmployeeComponent, canActivate: [checkNotSalesUser] },
      { path: "project", component: ProjectComponent, canActivate: [checkNotSalesUser] },
      { path: "smart-search", component: SmartSearchComponent },
      { path: "settings", component: SettingsComponent, canActivate: [checkAdmin] },
      { path: "profile-export", component: ProfileExportComponent, canActivate: [checkNotSalesUser] },
      { path: "**", redirectTo: "/home"},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
