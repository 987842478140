import { HttpErrorResponse } from "@angular/common/http";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { finalize } from "rxjs";
import {
  CompanyDTO,
  EmployeeService,
  EmployeeUserInfoDTO,
  UserService,
} from "src/shared/api/generated";
import { isDeepEqual } from "src/shared/util/is-deep-equal";

@Component({
  selector: "[app-tr-user-management]",
  templateUrl: "./tr-user-management.component.html",
  styleUrls: ["./tr-user-management.component.scss"],
})
export class TrUserManagementComponent {
  @Input() employeeWithUserInfo = {} as EmployeeUserInfoDTO;
  @Input() companies: CompanyDTO[] = [];
  @Input() createMode: boolean = false;

  @Output() onUserSaveError = new EventEmitter<HttpErrorResponse>();

  selectedRow: number = -1;
  isUser: boolean = false;
  visibleCompanyId?: number;

  editEmployeeWithUserInfo = {} as EmployeeUserInfoDTO;
  editedCell: string = "";

  constructor(
    private employeeService: EmployeeService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.isUser = this.employeeWithUserInfo.userId !== -1;
    this.resetEditedEmployeeWithUserInfo();

    if (this.employeeWithUserInfo.userId == -1) {
      this.visibleCompanyId = undefined;
    } else if (this.employeeWithUserInfo.global) {
      this.visibleCompanyId = -1;
    } else {
      this.visibleCompanyId = this.employeeWithUserInfo.visibleCompanies[0].id;
    }
  }

  resetEditedEmployeeWithUserInfo(): void {
    this.editEmployeeWithUserInfo = JSON.parse(
      JSON.stringify(this.employeeWithUserInfo)
    );
  }

  handleEmployeeWithUserInfoUpdate(): void {
    if (
      isDeepEqual(this.editEmployeeWithUserInfo, this.employeeWithUserInfo) ||
      this.editEmployeeWithUserInfo.name.trim() === "" ||
      this.editEmployeeWithUserInfo.email.trim() === ""
    ) {
      this.setEditStateFalse();
      return;
    }

    this.updateEmployeeWithUserInfo({
      ...this.editEmployeeWithUserInfo,
      name: this.editEmployeeWithUserInfo.name.trim(),
      email: this.editEmployeeWithUserInfo.email.trim(),
    });
    this.setEditStateFalse();
  }

  private updateEmployeeWithUserInfo(
    employeeWithUserInfo: EmployeeUserInfoDTO
  ): void {
    this.employeeService
      .updateEmployeeFromSettings(employeeWithUserInfo)
      .pipe(finalize(() => this.resetEditedEmployeeWithUserInfo()))
      .subscribe({
        next: (savedEmployeeWithUserInfo) =>
          (this.employeeWithUserInfo = savedEmployeeWithUserInfo),
        error: (error) => this.onUserSaveError.emit(error),
      });
  }

  setEditStateFalse(): void {
    if (this.createMode) {
      return;
    }
    this.resetEditedEmployeeWithUserInfo();
    this.editedCell = "";
  }

  setEditStateTrue(key: string) {
    if (this.createMode) {
      return;
    }
    this.editedCell = key;
  }

  manageUser($event: any): void {
    if (this.createMode) {
      return;
    }
    if ($event.currentTarget.checked) {
      this.addUser();
    } else {
      this.deleteUser();
    }
  }

  addUser(): void {
    this.userService
      .createUser(this.editEmployeeWithUserInfo.employeeId!)
      .subscribe((user) => {
        this.editEmployeeWithUserInfo.userId = user.id!;
      });
  }

  deleteUser(): void {
    this.userService
      .deleteUser(this.editEmployeeWithUserInfo.userId)
      .subscribe((_) => {
        this.editEmployeeWithUserInfo.admin = false;
        this.editEmployeeWithUserInfo.sales = false;
      });
  }

  setAdmin(): void {
    this.userService
      .setAdmin(
        this.editEmployeeWithUserInfo.userId,
        this.editEmployeeWithUserInfo.admin
      )
      .subscribe((_) => {
        if (this.editEmployeeWithUserInfo.admin) {
          this.editEmployeeWithUserInfo.sales = false;
        }
      });
  }

  setSales(): void {
    this.userService
      .setSales(
        this.editEmployeeWithUserInfo.userId,
        this.editEmployeeWithUserInfo.sales
      )
      .subscribe((_) => {
        if (this.editEmployeeWithUserInfo.sales) {
          this.editEmployeeWithUserInfo.admin = false;
        }
      });
  }

  setVisibleCompanies(): void {
    if (this.createMode) {
      return;
    }
    if (this.visibleCompanyId) {
      this.userService
        .setVisibleCompanies(
          this.editEmployeeWithUserInfo.userId,
          this.visibleCompanyId
        )
        .subscribe();
    }
    this.setEditStateFalse();
  }

  isEditingDisabled(): boolean {
    if (this.employeeWithUserInfo.employeeId && this.createMode) {
      return true;
    }
    return false;
  }

  inputClicked($event: MouseEvent) {
    $event.stopPropagation();
  }
}
