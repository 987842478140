<app-base-modal
  [title]="'home.comment_modal.title' | translate"
  [secondaryTitle]="entityName"
  [activeModal]="activeModal"
>
  <div class="main-container">
    <div class="comment-container">
      <div class="comment-header">
        <label class="comment-label">
          {{ "home.comment_modal.comment" | translate }}
        </label>
        <fa-icon
          *ngIf="lookingForEntity.comment"
          class="edit-icon"
          [icon]="['fas', 'pen']"
          (click)="setEditMode()"
        ></fa-icon>
        <fa-icon
          *ngIf="!lookingForEntity.comment"
          class="edit-icon"
          [icon]="['fas', 'circle-plus']"
          (click)="setEditMode()"
        ></fa-icon>
      </div>
      <textarea
        *ngIf="editMode"
        cols="30"
        rows="5"
        maxlength="255"
        placeholder="{{ 'home.comment_modal.max_length' | translate }}"
        [(ngModel)]="editLookingForEntity.comment"
      ></textarea>
      <p *ngIf="!editMode" class="comment">
        {{ lookingForEntity.comment }}
      </p>
    </div>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveLookingForEntity()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
