import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-base-modal",
  templateUrl: "./base-modal.component.html",
  styleUrl: "./base-modal.component.scss",
})
export class BaseModalComponent {
  @Input({ required: true }) activeModal!: NgbActiveModal;
  @Input() title?: string;
  @Input() secondaryTitle?: string;
  @Input() titleIcon?: IconName;
  @Input() showXButton: boolean = true;
  @Input() result?: any;

  @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  @HostListener("window:keydown.escape")
  closeModal(): void {
    this.onClose.emit();
    this.activeModal.close(this.result);
  }
}
