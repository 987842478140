import { formatDate } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  LOCALE_ID,
  ViewChild,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";
import { debounceTime, distinctUntilChanged, switchMap, tap } from "rxjs";
import {
  CompanyDTO,
  CompetencyDTO,
  EmployeePlanDTO,
  PlanService,
  ProjectPlanDTO,
} from "src/shared/api/generated";
import { ConfirmationModalService } from "src/shared/services/confirmation-modal.service";
import * as uuid from "uuid";
import { DataSet } from "vis-data";
import { Timeline } from "vis-timeline";
import { CreatePlanModalComponent } from "../create-plan-modal/create-plan-modal.component";
import { ModifyPlanModalComponent } from "../modify-plan-modal/modify-plan-modal.component";
import { errorMessage } from "src/shared/constant/error-message.constant";

@Component({
  selector: "app-plan-timeline",
  templateUrl: "./plan-timeline.component.html",
  styleUrls: ["./plan-timeline.component.scss"],
})
export class PlanTimelineComponent implements AfterViewInit {
  @Input() company!: CompanyDTO;
  companies: CompanyDTO[] = [];
  searchText = new FormControl("");
  static readonly SIX_MONTHS_IN_MILLISECONDS = 15778800000;
  static readonly ONE_YEAR_IN_MILLISECONDS = 31556952000;
  static readonly TIMELINE_MIN = new Date("2000-01-01");
  static readonly TIMELINE_MAX = new Date(
    moment.now() + 5 * PlanTimelineComponent.ONE_YEAR_IN_MILLISECONDS
  );
  groups = new DataSet(new Array<any>());
  allEmployeesExpanded: boolean = false;

  currentTimelineStart: Date = new Date(
    moment.now() - PlanTimelineComponent.SIX_MONTHS_IN_MILLISECONDS
  );
  currentTimelineEnd: Date = new Date(
    moment.now() + PlanTimelineComponent.SIX_MONTHS_IN_MILLISECONDS
  );
  currentWindowStart: Date = new Date(
    this.currentTimelineStart.getTime() -
      PlanTimelineComponent.ONE_YEAR_IN_MILLISECONDS
  );
  currentWindowEnd: Date = new Date(
    this.currentTimelineEnd.getTime() +
      PlanTimelineComponent.ONE_YEAR_IN_MILLISECONDS
  );

  timeline!: Timeline;
  init: any = null;

  @ViewChild("timeline", { static: true }) timelineContainer!: ElementRef;

  constructor(
    private planService: PlanService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private confirmationModalService: ConfirmationModalService,
    public translate: TranslateService,
    @Inject(LOCALE_ID) private locale: string
  ) {
    this.getOptions();
  }

  ngAfterViewInit(): void {
    this.timeline = new Timeline(
      this.timelineContainer.nativeElement,
      this.init,
      this.getOptions()
    );
    this.timeline.on("rangechanged", (properties) => {
      this.currentTimelineStart.setTime(properties.start);
      this.currentTimelineEnd.setTime(properties.end);
      this.updateWindowSizeOnMoving();
    });
    this.refreshPlans();
    this.searchPlans();
  }

  dateToString(date: Date) {
    let stringDate = formatDate(date, "yyyy-MM-dd", this.locale);
    return stringDate;
  }

  getPlansForCurrentCompany(searchTerm: string = "") {
    return this.planService.getAllEmployeePlansByCompany(
      this.company.id!,
      this.dateToString(this.currentWindowStart),
      this.dateToString(this.currentWindowEnd),
      searchTerm
    );
  }

  getTimelineIntervalInMilis() {
    return (
      this.currentTimelineEnd.getTime() - this.currentTimelineStart.getTime()
    );
  }

  toggleEmployees() {
    this.allEmployeesExpanded = !this.allEmployeesExpanded;
    if (this.allEmployeesExpanded) {
      this.groups.forEach((e) => {
        e.showNested = this.allEmployeesExpanded;
        e.visible = this.allEmployeesExpanded;
      });
    } else {
      this.groups.forEach((e) => {
        e.showNested = this.allEmployeesExpanded;
      });
    }
    this.timeline.setGroups(this.groups);
  }

  refreshPlans() {
    this.spinnerService.show("plan-spinner");
    let scrollTop: any = null;
    if (
      this.timelineContainer.nativeElement.querySelector(
        ".vis-vertical-scroll"
      ) !== null
    ) {
      scrollTop = this.timelineContainer.nativeElement.querySelector(
        ".vis-vertical-scroll"
      ).scrollTop;
    }
    this.getPlansForCurrentCompany(this.searchText.value ?? "").subscribe(
      (data) => {
        this.processPlanData(data);
        setTimeout(() => {
          if (
            scrollTop != null &&
            this.timelineContainer.nativeElement.querySelector(
              ".vis-vertical-scroll"
            ) !== null
          ) {
            this.timelineContainer.nativeElement.querySelector(
              ".vis-vertical-scroll"
            ).scrollTop = scrollTop;
          }
          this.spinnerService.hide("plan-spinner");
        }, 500);
      }
    );
  }

  private processPlanData(data: EmployeePlanDTO[]) {
    const openGroups = this.groups.getIds({
      filter: (e) => e.showNested === true,
    });
    const timelineData = new DataSet(new Array<any>());
    this.groups = new DataSet(new Array<any>());
    this.processEmployees(data, this.groups, timelineData);
    this.groups.forEach((group) => {
      if (openGroups.includes(group.id)) {
        group.showNested = true;
        group.visible = true;
      } else {
        group.showNested = false;
      }
    });
    this.timeline.setGroups(this.groups);
    this.timeline.setItems(timelineData);
  }

  private processEmployees(
    data: EmployeePlanDTO[],
    groups: DataSet<any, "id">,
    timelineData: DataSet<any, "id">
  ) {
    data.forEach((employee) => {
      let nested: string[] = [];
      let addId = uuid.v4();
      if (employee.projects) {
        this.processProjects(employee, nested, groups, timelineData);
      }
      let employeeId = employee.employeeName! + employee.employeeId;
      if (employee.summaries) {
        this.processSummaries(employee, timelineData, employeeId);
      }
      groups.add({
        id: employeeId,
        content: employee.employeeName,
        nestedGroups: nested,
        subgroupOrder: (a: any, b: any) => a.content.localeCompare(b.content),
        className: "employee",
        showNested: this.allEmployeesExpanded,
      });
      groups.add({
        id: addId,
        content: "\x2b",
        employeeId: employee.employeeId,
        employeeName: employee.employeeName,
        className: "add",
      });
      nested.push(addId);
    });
  }

  private processSummaries(
    employee: EmployeePlanDTO,
    timelineData: DataSet<any, "id">,
    employeeId: string
  ) {
    employee.summaries?.forEach((summaryItem) => {
      timelineData.add({
        id: uuid.v4(),
        group: employeeId,
        percentage: summaryItem.percentage,
        title: summaryItem.percentage?.toString() + "%",
        content: summaryItem.percentage?.toString() + "%",
        start: summaryItem.from,
        end: summaryItem.to,
        className: "summary",
        editable: false,
      });
    });
  }

  private processProjects(
    employee: EmployeePlanDTO,
    nested: string[],
    groups: DataSet<any, "id">,
    timelineData: DataSet<any, "id">
  ) {
    employee.projects?.forEach((project) => {
      let uId = uuid.v4();
      nested.push(uId);
      groups.add({
        id: uId,
        content: project.projectName,
      });

      if (project.plans) {
        this.processPlans(
          project,
          employee.employeeName!,
          employee.employeeId!,
          timelineData,
          uId
        );
      }
    });
  }

  private processPlans(
    project: ProjectPlanDTO,
    employeeName: string,
    employeeId: number,
    timelineData: DataSet<any, "id">,
    uId: string
  ) {
    project.plans?.forEach((plan) => {
      timelineData.add({
        id: uuid.v4(),
        planId: plan.id,
        percentage: plan.percentage,
        projectName: project.projectName,
        projectId: project.projectId,
        employeeName: employeeName,
        employeeId: employeeId,
        description: plan.description,
        competencyList: plan.competencyList,
        group: uId,
        title: plan.percentage?.toString() + "%",
        content: plan.percentage?.toString() + "%",
        start: plan.from,
        end: plan.to,
        className:
          plan.description && plan.description !== ""
            ? "base"
            : "base-without-task",
      });
    });
  }

  updateWindowSizeOnMoving() {
    let refreshNeeded = false;
    if (
      this.currentTimelineStart.getTime() < this.currentWindowStart.getTime() ||
      this.currentTimelineEnd.getTime() > this.currentWindowEnd.getTime()
    ) {
      this.updateWindowStart();
      this.updateWindowEnd();
      refreshNeeded = true;
    }

    if (refreshNeeded) {
      this.refreshPlans();
    }
  }

  updateWindowStart() {
    if (
      this.currentTimelineEnd.getTime() - this.currentTimelineStart.getTime() <
      PlanTimelineComponent.ONE_YEAR_IN_MILLISECONDS
    ) {
      this.currentWindowStart.setTime(
        this.currentTimelineStart.getTime() -
          PlanTimelineComponent.ONE_YEAR_IN_MILLISECONDS
      );
    } else {
      this.currentWindowStart.setTime(
        this.currentTimelineStart.getTime() - this.getTimelineIntervalInMilis()
      );
    }

    if (this.currentWindowStart < PlanTimelineComponent.TIMELINE_MIN) {
      this.currentWindowStart.setTime(
        PlanTimelineComponent.TIMELINE_MIN.getTime()
      );
    }
  }

  updateWindowEnd() {
    if (
      this.currentTimelineEnd.getTime() - this.currentTimelineStart.getTime() <
      PlanTimelineComponent.ONE_YEAR_IN_MILLISECONDS
    ) {
      this.currentWindowEnd.setTime(
        this.currentTimelineEnd.getTime() +
          PlanTimelineComponent.ONE_YEAR_IN_MILLISECONDS
      );
    } else {
      this.currentWindowEnd.setTime(
        this.currentTimelineEnd.getTime() + this.getTimelineIntervalInMilis()
      );
    }

    if (this.currentWindowEnd > PlanTimelineComponent.TIMELINE_MAX) {
      this.currentWindowEnd.setTime(
        PlanTimelineComponent.TIMELINE_MAX.getTime()
      );
    }
  }

  updateWindowSizeOnModification() {
    this.updateWindowStart();
    this.updateWindowEnd();
  }

  getOptions(): any {
    let options = {
      stack: false,
      itemsAlwaysDraggable: true,
      zoomKey: "ctrlKey",
      verticalScroll: true,
      maxHeight: "100%",
      zoomMin: 1000 * 60 * 60 * 24 * 10 * 2,
      editable: {
        add: false,
        updateTime: true,
        updateGroup: false,
        remove: true,
        overrideItems: false,
      },
      margin: {
        axis: 0,
      },
      start: this.currentTimelineStart,
      end: this.currentTimelineEnd,
      min: PlanTimelineComponent.TIMELINE_MIN,
      max: PlanTimelineComponent.TIMELINE_MAX,
      showMajorLabels: true,
      orientation: "top",
      format: {
        minorLabels: {
          day: "D ddd",
        },
      },
      groupTemplate: (group: any) => {
        let container = document.createElement("div");
        let label = document.createElement("span");
        label.innerHTML = group.content;
        container.insertAdjacentElement("afterbegin", label);
        if (group.nestedGroups) {
          return container;
        }
        container.addEventListener("click", () => {
          this.openAddProjectToEmployeeDialog(group);
        });
        return container;
      },

      onMove: (item: any) => {
        this.moveItem(item);
      },

      onUpdate: (item: any) => {
        this.openEditItemDialog(item);
      },

      onRemove: (item: any) => {
        this.removeItem(item);
      },
    };
    return options;
  }

  removeItem(item: any) {
    this.planService
      .isPlanUsedInProfiles(item.planId)
      .subscribe((isPlanUsed) => {
        if (isPlanUsed) {
          this.confirmationModalService
            .openConfirmationModal(
              "plan.confirm_delete",
              "plan.delete_dialog_message"
            )
            .then(
              (result) => {
                if (result) {
                  this.deletePlan(item.planId);
                }
              },
              () => {}
            );
        } else {
          this.deletePlan(item.planId);
        }
      });
  }

  deletePlan(planId: number) {
    this.planService.deletePlan(planId).subscribe((data: any) => {
      this.updateWindowSizeOnModification();
      this.refreshPlans();
    });
  }

  openEditItemDialog(item: any) {
    const modalRef = this.modalService.open(ModifyPlanModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
    });
    modalRef.componentInstance.projectName = item.projectName;
    modalRef.componentInstance.employeeName = item.employeeName;
    modalRef.componentInstance.plan = {
      id: item.planId,
      from: this.dateToString(item.start),
      description: item.description,
      to: this.dateToString(item.end),
      percentage: item.percentage,
      projectId: item.projectId,
      employeeId: item.employeeId,
      competencyList: item.competencyList,
    };
    modalRef.result.then(
      (result) => {
        if (result) {
          this.updateWindowSizeOnModification();
          this.refreshPlans();
        }
      },
      () => {}
    );
  }

  openAddProjectToEmployeeDialog(group: any) {
    if (!group.employeeId) {
      return;
    }
    const modalRef = this.modalService.open(CreatePlanModalComponent, {
      windowClass: "custom-modal-window",
      scrollable: true,
    });
    modalRef.componentInstance.employeeId = group.employeeId;
    modalRef.componentInstance.employeeName = group.employeeName;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.updateWindowSizeOnModification();
          this.refreshPlans();
        }
      },
      () => {}
    );
  }

  moveItem(item: any) {
    this.planService
      .updatePlan({
        id: item.planId,
        from: this.dateToString(item.start),
        to: this.dateToString(item.end),
        description: item.description,
        percentage: item.percentage,
        competencyIdList: item.competencyList.map(
          (competency: CompetencyDTO) => competency.id
        ),
        projectId: item.projectId,
        employeeId: item.employeeId,
      })
      .subscribe({
        next: (result) => {
          this.updateWindowSizeOnModification();
          this.refreshPlans();
        },
        error: (err) => {
          if (err.error.message === "From must be earlier than To") {
            this.confirmationModalService
              .openConfirmationModal(
                "plan.update_error.title",
                "plan.update_error.to_earlier_than_from",
                true
              )
              .then(
                () => {
                  this.refreshPlans();
                },
                () => {
                  this.refreshPlans();
                }
              );
          }
          if (err.error.message === errorMessage.planOverlaps) {
            this.confirmationModalService
              .openConfirmationModal(
                "plan.update_error.title",
                "plan.update_error.plans_overlap_on_employee",
                true
              )
              .then(
                () => {
                  this.refreshPlans();
                },
                () => {
                  this.refreshPlans();
                }
              );
          }
        },
      });
  }

  searchPlans() {
    this.searchText.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.spinnerService.show("plan-spinner");
        }),
        switchMap((text) => this.getPlansForCurrentCompany(text ?? ""))
      )
      .subscribe((data) => {
        this.processPlanData(data);
        this.spinnerService.hide("plan-spinner");
      });
  }
}
