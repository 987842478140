<app-base-modal
  [title]="'home.looking_for_project.create_modal.title' | translate"
  [activeModal]="activeModal"
>
  <div>
    <form class="form" [formGroup]="lookingForProjectForm">
      <div class="form-row">
        <label for="employee" class="row-label">
          {{ "home.looking_for_project.create_modal.employee" | translate }}
        </label>
        <div class="wrapper">
          <input
            id="employee"
            type="text"
            formControlName="employee"
            [ngbTypeahead]="searchEmployee"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            [editable]="false"
            container="body"
            (focus)="employeeFocus$.next($any($event).target.value)"
            (click)="employeeClick$.next($any($event).target.value)"
            #employeeInstance="ngbTypeahead"
          />
          <span
            *ngIf="
              lookingForProjectForm.get('employee')?.hasError('required') &&
              lookingForProjectForm.get('employee')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="assignee" class="row-label">
          {{ "home.looking_for_project.create_modal.assignee" | translate }}
        </label>
        <div class="wrapper">
          <input
            id="assignee"
            type="text"
            formControlName="assignee"
            [ngbTypeahead]="searchAssignee"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            [editable]="false"
            container="body"
            (focus)="assigneeFocus$.next($any($event).target.value)"
            (click)="assigneeClick$.next($any($event).target.value)"
            #assigneeInstance="ngbTypeahead"
          />
          <span
            *ngIf="
              lookingForProjectForm.get('assignee')?.hasError('required') &&
              lookingForProjectForm.get('assignee')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="freeFrom" class="row-label">
          {{ "home.looking_for_project.create_modal.free_from" | translate }}
        </label>
        <div class="wrapper">
          <input id="freeFrom" formControlName="freeFrom" type="date" />
          <span
            *ngIf="
              lookingForProjectForm.get('freeFrom')?.hasError('required') &&
              lookingForProjectForm.get('freeFrom')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="error" class="error mt-4">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
      {{ errorMessage }}
    </ngb-alert>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveNewEmployee()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
