<app-base-modal
  [activeModal]="activeModal"
  [title]="'profile_export.profile_modify_modal.title' | translate"
  [secondaryTitle]="profile.employeeName"
>
  <div class="content">
    <div class="section mb-2" id="position">
      <div class="d-flex flex-row justify-content-between">
        <label class="section-title">
          {{ "profile_export.profile_modify_modal.position_title" | translate }}
        </label>
      </div>
      <div class="position-item d-flex flex-row justify-content-between">
        {{ profile.employeePosition }}
      </div>
    </div>
    <div class="section mb-2" id="seniority">
      <div
        class="seniority-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">
          {{
            "profile_export.profile_modify_modal.seniority.title" | translate
          }}
        </label>
      </div>
      <div
        class="seniority-item d-flex flex-row justify-content-between"
        [ngClass]="!profile.showSeniority ? 'not-visible' : ''"
      >
        <select [(ngModel)]="profile.seniority" class="seniority-select">
          <option [ngValue]="null">---</option>
          <option
            *ngFor="let seniority of seniorities | keyvalue"
            [value]="seniority.value"
          >
            {{
              "profile_export.profile_modify_modal.seniority." + seniority.value
                | translate
            }}
          </option>
        </select>
        <fa-icon
          *ngIf="!profile.showSeniority"
          class="change-visibility-icon"
          [icon]="['far', 'eye-slash']"
          [ngbTooltip]="
            'profile_export.profile_modify_modal.show_tooltip' | translate
          "
          placement="left"
          container="body"
          (click)="changeShowSeniority()"
        ></fa-icon>
        <fa-icon
          *ngIf="profile.showSeniority"
          class="change-visibility-icon"
          [icon]="['far', 'eye']"
          [ngbTooltip]="
            'profile_export.profile_modify_modal.hide_tooltip' | translate
          "
          placement="left"
          container="body"
          (click)="changeShowSeniority()"
        ></fa-icon>
      </div>
    </div>
    <div class="section mb-2" id="experience">
      <div
        class="experience-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">
          {{
            "profile_export.profile_modify_modal.experience_title" | translate
          }}
        </label>
        <fa-icon
          class="edit-icon"
          [ngClass]="editExternalProjectMode ? 'disabled-icon' : ''"
          [ngbTooltip]="'profile_export.profile_modify_modal.add' | translate"
          placement="left"
          container="body"
          [icon]="['fas', 'plus']"
          (click)="addExternalProject()"
        ></fa-icon>
      </div>
      <div
        class="experience-item"
        *ngFor="let internalProject of profile.internalProjects"
      >
        <div
          class="project-title-row d-flex flex-row"
          [ngClass]="!internalProject.visible ? 'not-visible' : ''"
        >
          <label class="project-title">
            {{ internalProject.project?.year }}
          </label>
          <label class="project-title">
            {{ internalProject.project?.projectName }}
          </label>
          <fa-icon
            *ngIf="!internalProject.visible"
            class="change-visibility-icon"
            [icon]="['far', 'eye-slash']"
            [ngbTooltip]="
              'profile_export.profile_modify_modal.show_tooltip' | translate
            "
            placement="left"
            container="body"
            (click)="changeProjectVisibility(internalProject)"
          ></fa-icon>
          <fa-icon
            *ngIf="internalProject.visible"
            class="change-visibility-icon"
            [icon]="['far', 'eye']"
            [ngbTooltip]="
              'profile_export.profile_modify_modal.hide_tooltip' | translate
            "
            placement="left"
            container="body"
            (click)="changeProjectVisibility(internalProject)"
          ></fa-icon>
        </div>
        <ul
          *ngIf="internalProject.project?.tasks?.length"
          [ngClass]="!internalProject.visible ? 'not-visible' : ''"
        >
          <li *ngFor="let task of getTasksByInternalProject(internalProject)">
            {{ task }}
          </li>
        </ul>
        <div [ngClass]="!internalProject.visible ? 'not-visible' : ''">
          <app-competency-pill-container
            [competencies]="
              internalProject.project?.profileProjectCompetencies!
            "
            [commands]="commands"
            [isDragActive]="true"
          ></app-competency-pill-container>
        </div>
      </div>
      <div
        class="experience-item"
        *ngFor="let externalProject of profile.externalProjects; let i = index"
      >
        <ng-container
          *ngIf="editedExternalProjectIndex != i; else editExternalProjectMode"
        >
          <div class="project-title-row d-flex flex-row">
            <label class="project-title">{{ externalProject.year }}</label>
            <label class="project-title">
              {{ externalProject.projectName }}
            </label>
            <div class="modification-icons">
              <fa-icon
                class="edit-icon"
                [icon]="['fas', 'pen']"
                [ngbTooltip]="
                  'profile_export.profile_modify_modal.edit' | translate
                "
                placement="left"
                container="body"
                (click)="startEditingExternalProject(i)"
              ></fa-icon>
              <fa-icon
                class="remove-icon"
                [icon]="['fas', 'trash']"
                (click)="removeExternalProject(externalProject)"
              ></fa-icon>
            </div>
          </div>
          <ul *ngIf="externalProject.task">
            <li>{{ externalProject.task }}</li>
          </ul>
        </ng-container>
        <ng-template #editExternalProjectMode>
          <div class="project-title-row d-flex flex-row">
            <input
              type="text"
              maxlength="255"
              class="edit-year-input"
              [(ngModel)]="editedExternalProject.year"
              placeholder="{{
                'profile_export.profile_modify_modal.project_year' | translate
              }}"
            />
            <input
              type="text"
              maxlength="255"
              [(ngModel)]="editedExternalProject.projectName"
              placeholder="{{
                'profile_export.profile_modify_modal.project_name' | translate
              }}"
            />
            <div class="modification-icons">
              <fa-icon
                class="save-edit-icon"
                [icon]="['fas', 'check']"
                (click)="saveProjectEdit()"
              ></fa-icon>
              <fa-icon
                class="cancel-edit-icon"
                [icon]="['fas', 'xmark']"
                (click)="cancelProjectEdit()"
              ></fa-icon>
            </div>
          </div>
          <div class="edit-task-row">
            <input
              type="text"
              maxlength="255"
              class="edit-task-input"
              [(ngModel)]="editedExternalProject.task"
              placeholder="{{
                'profile_export.profile_modify_modal.project_task' | translate
              }}"
            />
          </div>
        </ng-template>
      </div>
    </div>
    <div class="section mb-2" id="competencies">
      <label class="section-title">
        {{
          "profile_export.profile_modify_modal.competencies_title" | translate
        }}
      </label>
      <div class="hide-competencies-container">
        <input
          class="level-input-box mx-1"
          type="number"
          min="1"
          max="5"
          step="1"
          [(ngModel)]="minimalShownLevel"
          (keydown.enter)="onMinimalShownLevelSave()"
          (click)="$event.stopPropagation()"
        />
        <button
          type="button"
          class="btn btn-sm secondary-btn"
          (click)="onMinimalShownLevelSave()"
        >
          {{
            "profile_export.profile_modify_modal.hide_competencies" | translate
          }}
        </button>
      </div>
      <div class="competency-container">
        <div
          class="competency-category-container d-flex flex-row justify-content-between"
        >
          <label class="competency-category-title">
            {{
              "profile_export.profile_modify_modal.competency_technological"
                | translate
            }}
          </label>
          <select
            *ngIf="technologicalCompetencies.length"
            [(ngModel)]="technologicalCompetencies[0].showCompetencyAs"
            (change)="changeTechnologicalCompetencyDisplayMode($event)"
          >
            <option
              *ngFor="let displayMode of competencyDisplayModes"
              [value]="displayMode"
            >
              {{
                "profile_export.profile_modify_modal.technological_competency_display_mode" +
                  "." +
                  displayMode | translate
              }}
            </option>
          </select>
        </div>
        <div
          class="competency-item d-flex flex-row"
          *ngFor="let competency of technologicalCompetencies"
          [ngClass]="!competency.visible ? 'not-visible' : ''"
        >
          <ng-container
            *ngIf="
              competency.id !== levelEditedProfileCompetency?.id;
              else levelEditMode
            "
          >
            <span class="competency-level">
              <label>{{ competency.level }}</label>
              <fa-icon
                class="edit-level-icon"
                [icon]="['fas', 'pen']"
                [ngbTooltip]="
                  'profile_export.profile_modify_modal.edit' | translate
                "
                [disableTooltip]="!competency.visible"
                (click)="onStartEditingLevel(competency)"
              ></fa-icon>
            </span>
          </ng-container>
          <ng-template #levelEditMode>
            <span class="competency-level">
              <input
                class="level-input-box"
                type="number"
                min="1"
                max="5"
                step="1"
                [(ngModel)]="levelEditedProfileCompetency!.level"
              />
              <fa-icon
                class="save-edit-icon mx-2"
                [icon]="['fas', 'check']"
                (click)="onSaveLevelEditing(competency)"
              ></fa-icon>
              <fa-icon
                class="cancel-edit-icon"
                [icon]="['fas', 'xmark']"
                (click)="onCancelLevelEditing()"
              ></fa-icon>
            </span>
          </ng-template>
          <label>{{ competency.competency?.name }}</label>
          <fa-icon
            *ngIf="!competency.visible"
            class="change-visibility-icon"
            [icon]="['far', 'eye-slash']"
            [ngbTooltip]="
              'profile_export.profile_modify_modal.show_tooltip' | translate
            "
            placement="left"
            container="body"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
          <fa-icon
            *ngIf="competency.visible"
            class="change-visibility-icon"
            [icon]="['far', 'eye']"
            [ngbTooltip]="
              'profile_export.profile_modify_modal.hide_tooltip' | translate
            "
            placement="left"
            container="body"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
        </div>
      </div>
      <div class="competency-container">
        <div
          class="competency-category-container d-flex flex-row justify-content-between"
        >
          <div>
            <label class="competency-category-title">
              {{
                "profile_export.profile_modify_modal.competency_language"
                  | translate
              }}
            </label>
            <span
              ngbTooltip="{{
                'profile_export.profile_modify_modal.language_level_tooltip'
                  | translate
              }}"
              placement="bottom"
              container="body"
              class="ms-1"
              ><fa-icon [icon]="['fas', 'circle-info']"></fa-icon
            ></span>
          </div>
          <select
            *ngIf="languageCompetencies.length"
            [(ngModel)]="languageCompetencies[0].showCompetencyAs"
            (change)="changeLanguageCompetencyDisplayMode($event)"
          >
            <option
              *ngFor="let displayMode of competencyDisplayModes"
              [value]="displayMode"
            >
              {{
                "profile_export.profile_modify_modal.language_competency_display_mode" +
                  "." +
                  displayMode | translate
              }}
            </option>
          </select>
        </div>
        <div
          class="competency-item d-flex flex-row"
          *ngFor="let competency of languageCompetencies"
          [ngClass]="!competency.visible ? 'not-visible' : ''"
        >
          <ng-container
            *ngIf="
              competency.id !== levelEditedProfileCompetency?.id;
              else levelEditMode
            "
          >
            <span class="competency-level">
              <label>{{ competency.level }}</label>
              <fa-icon
                class="edit-level-icon"
                [icon]="['fas', 'pen']"
                [ngbTooltip]="
                  'profile_export.profile_modify_modal.edit' | translate
                "
                [disableTooltip]="!competency.visible"
                (click)="onStartEditingLevel(competency)"
              ></fa-icon>
            </span>
          </ng-container>
          <ng-template #levelEditMode>
            <span class="competency-level">
              <input
                class="level-input-box"
                type="number"
                min="1"
                max="5"
                step="1"
                [(ngModel)]="levelEditedProfileCompetency!.level"
              />
              <fa-icon
                class="save-edit-icon mx-2"
                [icon]="['fas', 'check']"
                (click)="onSaveLevelEditing(competency)"
              ></fa-icon>
              <fa-icon
                class="cancel-edit-icon"
                [icon]="['fas', 'xmark']"
                (click)="onCancelLevelEditing()"
              ></fa-icon>
            </span>
          </ng-template>
          <label>{{ competency.competency?.name }}</label>
          <fa-icon
            *ngIf="!competency.visible"
            class="change-visibility-icon"
            [icon]="['far', 'eye-slash']"
            [ngbTooltip]="
              'profile_export.profile_modify_modal.show_tooltip' | translate
            "
            placement="left"
            container="body"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
          <fa-icon
            *ngIf="competency.visible"
            class="change-visibility-icon"
            [icon]="['far', 'eye']"
            [ngbTooltip]="
              'profile_export.profile_modify_modal.hide_tooltip' | translate
            "
            placement="left"
            container="body"
            (click)="changeCompetencyVisibility(competency)"
          ></fa-icon>
        </div>
      </div>
    </div>
    <div class="section mb-2" id="education">
      <div
        class="study-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">
          {{ "profile_export.profile_modify_modal.studies_title" | translate }}
        </label>
      </div>
      <div
        *ngFor="let education of profile.education"
        [ngClass]="!education.visible ? 'not-visible' : ''"
        class="study-item d-flex flex-row justify-content-between"
      >
        <label
          >{{ education.educationDTO?.code }}
          {{ education.educationDTO?.name }}
          <label *ngIf="isEducationExpired(education.educationDTO!)">
            {{ "profile_export.profile_modify_modal.expired" | translate }}
          </label>
        </label>
        <fa-icon
          *ngIf="!education.visible"
          class="change-visibility-icon"
          [icon]="['far', 'eye-slash']"
          [ngbTooltip]="
            'profile_export.profile_modify_modal.show_tooltip' | translate
          "
          placement="left"
          container="body"
          (click)="changeEducationVisibility(education)"
        ></fa-icon>
        <fa-icon
          *ngIf="education.visible"
          class="change-visibility-icon"
          [icon]="['far', 'eye']"
          [ngbTooltip]="
            'profile_export.profile_modify_modal.hide_tooltip' | translate
          "
          placement="left"
          container="body"
          (click)="changeEducationVisibility(education)"
        ></fa-icon>
      </div>
    </div>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveProfile()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
