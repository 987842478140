<app-base-modal
  [title]="'home.looking_for_resources.create_modal.title' | translate"
  [activeModal]="activeModal"
>
  <div>
    <form class="form" [formGroup]="lookingForResourceForm">
      <div class="form-row">
        <label for="project" class="row-label">
          {{ "home.looking_for_resources.create_modal.project" | translate }}
        </label>
        <div class="wrapper">
          <input
            id="project"
            type="text"
            formControlName="project"
            [ngbTypeahead]="searchProject"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            container="body"
            (focus)="projectFocus$.next($any($event).target.value)"
            (click)="projectFocus$.next($any($event).target.value)"
            #projectInstance="ngbTypeahead"
          />
          <span
            *ngIf="
              lookingForResourceForm.get('project')?.hasError('required') &&
              lookingForResourceForm.get('project')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="assignee" class="row-label">
          {{ "home.looking_for_resources.create_modal.assignee" | translate }}
        </label>
        <div class="wrapper">
          <input
            id="assignee"
            type="text"
            formControlName="assignee"
            [ngbTypeahead]="searchAssignee"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            [editable]="false"
            container="body"
            (focus)="assigneeFocus$.next($any($event).target.value)"
            (click)="assigneeClick$.next($any($event).target.value)"
            #assigneeInstance="ngbTypeahead"
          />
          <span
            *ngIf="
              lookingForResourceForm.get('assignee')?.hasError('required') &&
              lookingForResourceForm.get('assignee')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="requestStart" class="row-label">
          {{ "home.looking_for_resources.create_modal.start" | translate }}
        </label>
        <div class="wrapper">
          <input id="requestStart" formControlName="requestStart" type="date" />
        </div>
      </div>
      <div class="form-row">
        <label for="requestEnd" class="row-label">
          {{ "home.looking_for_resources.create_modal.end" | translate }}
        </label>
        <div class="wrapper">
          <input id="requestEnd" formControlName="requestEnd" type="date" />
        </div>
      </div>
      <div class="form-row">
        <label for="fte" class="row-label">
          {{ "home.looking_for_resources.create_modal.fte" | translate }}
        </label>
        <div class="wrapper">
          <input
            id="fte"
            formControlName="fte"
            type="number"
            step="0.25"
            min="0"
          />
          <span
            *ngIf="
              lookingForResourceForm.get('fte')?.hasError('required') &&
              lookingForResourceForm.get('fte')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="error" class="error mt-4">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
      {{ errorMessage }}
    </ngb-alert>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveNewLookingForResource()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
