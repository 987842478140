import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Language } from "src/shared/constant/user-config.constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "resource-app-frontend";

  constructor(
    translate: TranslateService,
  ) {
    translate.setDefaultLang(Language.EN);    
    const availableLanguages = [Language.EN, Language.HU];
    translate.addLangs(availableLanguages);
  }
}
