<td>
  <label class="text-overflow-dotted w-100">
    {{ profile.employeeName }}
  </label>
</td>
<td>
  <label class="text-overflow-dotted w-100">
    {{ profile.employeeEmail }}
  </label>
</td>
<td class="text-center">
  <fa-icon
    class="remove-icon"
    [icon]="['fas', 'trash']"
    (click)="removeProfileFromQueue()"
  ></fa-icon>
</td>
