<div class="d-flex flex-column justify-content-between h-100 position-relative">
  <div class="d-flex flex-column main-container" style="min-height: 30px">
    <div class="d-flex flex-row w-100 justify-content-start table-actions">
      <div>
        <div *ngIf="!createEducationMode" (click)="addEmptyEducation()">
          <fa-icon
            [ngbTooltip]="
              'employee.education_modal.education_table.add' | translate
            "
            class="plus-icon"
            [icon]="['fas', 'plus']"
            size="2xl"
          ></fa-icon>
        </div>
        <button
          *ngIf="createEducationMode"
          class="btn secondary-btn ms-2"
          (click)="cancelCreateEducation()"
        >
          {{ "button.cancel" | translate }}
        </button>
        <button
          *ngIf="createEducationMode"
          class="btn primary-btn ms-2"
          [disabled]="isCreateDisabled()"
          (click)="createEducation()"
        >
          {{ "button.save" | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="educations.length" class="table-container">
      <table
        class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
      >
        <thead>
          <tr>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.code" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.name" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.start" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{ "employee.education_modal.education_table.end" | translate }}
            </th>
            <th scope="col" class="table-header text-center">
              {{
                "employee.education_modal.education_table.valid_until"
                  | translate
              }}
            </th>
            <th scope="col" class="table-header"></th>
          </tr>
        </thead>
        <tbody>
          @for (education of educations; track education.id) {
          <tr
            app-tr-education
            [education]="education"
            [createMode]="createEducationMode"
            (educationSaved)="onEducationSaved()"
            (educationSaveFailed)="onEducationSaveFailed($event)"
            (educationDeleted)="onEducationDeleted()"
          ></tr>
          }
        </tbody>
      </table>
    </div>
  </div>
</div>
