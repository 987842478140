<app-base-modal
  [title]="'employee.projects_modal.title' | translate"
  [secondaryTitle]="employeeName"
  [activeModal]="activeModal"
>
  <div class="main-container">
    <div class="project-container" *ngIf="projects.length !== 0">
      <div ngbAccordion class="accordion pb-3" [closeOthers]="true">
        <div ngbAccordionItem *ngFor="let project of projects">
          <h2 ngbAccordionHeader class="w-100">
            <button ngbAccordionButton class="w-100">
              <span class="project-name pe-1 py-1"
                ><b>{{ project.projectName }}</b>
              </span>
            </button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-template>
                <div class="d-flex flex-column">
                  <ul>
                    <li *ngFor="let task of project.tasks">
                      {{ task }}
                    </li>
                  </ul>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-project-container" *ngIf="projects.length === 0">
      <label class="text-center">
        {{ "employee.projects_modal.empty_projects" | translate }}
      </label>
    </div>
  </div>
  <button modal-actions class="btn secondary-btn" (click)="activeModal.close()">
    {{ "button.close" | translate }}
  </button>
</app-base-modal>
