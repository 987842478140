import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import {
  NgbAccordionDirective,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { of } from "rxjs";
import {
  CompetencyDTO,
  CompetencyGroupDTO,
  ProjectCompetencyDTO,
} from "src/shared/api/generated";
import {
  duplicateCompetencyValidator,
  uniqueCompetencyValidator,
  wrongLevelValidator,
} from "./custom-validator.directive";
import { AddOrEditCompetencyModalComponent } from "../add-or-edit-competency-modal/add-or-edit-competency-modal.component";
import { EmployeeBasketDTO } from "src/shared/api/generated/model/employeeBasketDTO";
import { NgxSpinnerService } from "ngx-spinner";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";

@Component({
  selector: "app-competency-modal",
  templateUrl: "./competency-modal.component.html",
  styleUrl: "./competency-modal.component.scss",
})
export class CompetencyModalComponent implements OnInit {
  @ViewChild("competencyAccordion")
  competencyAccordion!: NgbAccordionDirective;
  @Input() allCompetencies: CompetencyDTO[] = [];
  @Input() assignedCompetencies: any = [];
  @Input() competencyGroups: CompetencyGroupDTO[] = [];
  @Input() selectableCompetencyGroups: CompetencyGroupDTO[] = [];
  @Input() filterAction: Function | null = null;
  @Input() saveAction!: Function;
  @Input() isLevelShown: boolean = true;
  @Output() newCompetencyEvent: EventEmitter<any> = new EventEmitter();
  availableCompetencies: CompetencyDTO[] = [];
  selectedCompetencies: CompetencyDTO[] = [];
  filteredCompetencies: CompetencyDTO[] = [];
  closeAction: Function = () => this.onClose();
  searchText: string = "";
  selectedCompetencyForm!: FormGroup;
  isNewCompetency: boolean = false;

  constructor(
    private readonly spinnerService: NgxSpinnerService,
    private readonly modalService: NgbModal,
    private readonly fb: FormBuilder,
    public translate: TranslateService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.selectedCompetencyForm = this.fb.group({
      competencies: this.fb.array([]),
    });
    if (this.filterAction) {
      this.availableCompetencies = this.filterAction(
        this.allCompetencies,
        this.assignedCompetencies
      );
    } else {
      this.availableCompetencies = this.allCompetencies;
    }
    this.filteredCompetencies = this.availableCompetencies;
  }

  addCompetency(competency: CompetencyDTO): void {
    const competencyForm = this.fb.group(
      {
        competency: competency,
        competencyId: competency.id,
        competencyName: competency.name,
        level: 3,
      },
      {
        validators: [
          uniqueCompetencyValidator(this.assignedCompetencies),
          duplicateCompetencyValidator(this.competencies),
          wrongLevelValidator(),
        ],
      }
    );

    this.competencies.push(competencyForm);
    this.checkValidity();

    this.selectedCompetencies.push(competency);
    if (this.filterAction) {
      this.availableCompetencies = this.filterAction(
        this.availableCompetencies,
        this.selectedCompetencies
      );
      this.filteredCompetencies = this.filterAction(
        this.filteredCompetencies,
        this.selectedCompetencies
      );
    }
  }

  removeCompetency(index: number): void {
    const competency = this.competencies.at(index).getRawValue();
    this.selectedCompetencies.splice(
      this.selectedCompetencies.indexOf(competency.competency),
      this.selectedCompetencies.indexOf(competency.competency)
    );
    if (this.filterAction) {
      this.availableCompetencies = this.filterAction(
        this.allCompetencies,
        this.selectedCompetencies
      );
      this.filteredCompetencies = this.filterAction(
        this.filteredCompetencies,
        this.selectedCompetencies
      );
    }
    this.competencies.removeAt(index);
    this.checkValidity();
  }

  checkValidity(): void {
    this.competencies.controls.forEach((x) => {
      (x as FormGroup).updateValueAndValidity();
    });
  }

  get competencies() {
    return this.selectedCompetencyForm.controls["competencies"] as FormArray;
  }

  onSearchInput() {
    const searchTerm = this.searchText.trim().toLowerCase();
    if (searchTerm === "") {
      this.filteredCompetencies = this.availableCompetencies;
    } else {
      this.filteredCompetencies = this.availableCompetencies.filter(
        (competency) => competency.name.toLowerCase().includes(searchTerm)
      );
      this.expandCategoriesWithSearchResult();
    }
  }

  newCompetency(name: string) {
    const modalRef = this.modalService.open(AddOrEditCompetencyModalComponent);
    modalRef.componentInstance.competency = {
      id: undefined,
      name: name,
      competencyGroupId: this.selectableCompetencyGroups[0].id,
    };
    modalRef.componentInstance.competencyGroups =
      this.selectableCompetencyGroups;
    modalRef.componentInstance.createMode = true;
    modalRef.result.then(
      (result) => {
        if (result) {
          this.allCompetencies.push(result);
          this.availableCompetencies.push(result);
          this.filteredCompetencies.push(result);
          this.searchText = result.name;
          this.expandCategoriesWithSearchResult();
          this.isNewCompetency = true;
        }
      },
      () => {}
    );
  }
  
  isAddButtonDisabled() {
    return (
      this.filteredCompetencies.filter(
        (competency) =>
          competency.name.toLowerCase() === this.searchText.trim().toLowerCase()
      ).length > 0 || this.searchText.trim().toLowerCase() === ""
    );
  }

  expandCategoriesWithSearchResult() {
    for (let type of this.competencyGroups) {
      const filteredCompetenciesNumber =
        this.getCompetenciesInCategoryFromArray(
          this.filteredCompetencies,
          type.id
        ).length;
      if (filteredCompetenciesNumber !== 0) {
        this.competencyAccordion.expand(type.name);
      } else {
        this.competencyAccordion.collapse(type.name);
      }
    }
  }

  getCompetenciesInCategoryFromArray(
    array: CompetencyDTO[],
    typeId: number
  ): CompetencyDTO[] {
    return array
      .filter((c) => c.competencyGroup.rootGroupId === typeId)
      .sort((a, b) => b.id! - a.id! || a.name.localeCompare(b.name));
  }

  onClose() {
    this.activeModal.close({
      isNewCompetency: this.isNewCompetency,
      isNewAssignedCompetency: false,
      competencies: null,
    });
  }

  saveCompetency() {
    if (this.competencies.getRawValue().length === 0) {
      this.activeModal.close({
        isNewCompetency: this.isNewCompetency,
        isNewAssignedCompetency: false,
        competencies: null,
      });
      return;
    }
    if (this.competencies?.invalid) {
      this.selectedCompetencyForm.markAllAsTouched();
    } else {
      this.saveAction(this.competencies.getRawValue())
        .pipe(showLoadingSpinner(this.spinnerService))
        .subscribe((res: EmployeeBasketDTO[] | ProjectCompetencyDTO[]) => {
          this.activeModal.close({
            isNewCompetency: this.isNewCompetency,
            isNewAssignedCompetency: this.competencies.length !== 0,
            competencies: res,
          });
        });
    }
  }
}
