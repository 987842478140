<app-base-modal
  [activeModal]="activeModal"
  [title]="'read_only_profile.title' | translate"
  [secondaryTitle]="profile.employeeName"
>
  <div class="content d-flex flex-column">
    <div class="section mb-2" id="position">
      <div class="d-flex flex-row justify-content-between">
        <label class="section-title">
          {{ "read_only_profile.position_title" | translate }}
        </label>
      </div>
      <div class="position-item d-flex flex-row justify-content-between">
        {{ profile.position }}
      </div>
    </div>
    <div class="section mb-2" *ngIf="profile.seniority">
      <div
        class="section-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">
          {{ "read_only_profile.seniority.title" | translate }}
        </label>
      </div>
      <div class="seniority-item d-flex flex-row justify-content-between">
        {{ "read_only_profile.seniority." + profile.seniority | translate }}
      </div>
    </div>
    <div class="section mb-2">
      <div
        class="section-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">
          {{ "read_only_profile.experience_title" | translate }}
        </label>
      </div>
      <div class="experience-item" *ngFor="let project of getProjects()">
        <div class="project-title-row d-flex flex-row">
          <label class="project-title">{{ project.year }}</label>
          <label class="project-title">{{ project.projectName }}</label>
        </div>
        <label class="project-alternative-name" *ngIf="project.alternativeName">
          {{ project.alternativeName }}
        </label>
        <div class="project-info" *ngIf="project.description">
          <label class="experience-item-section-title">
            {{ "read_only_profile.project_scope" | translate }}
          </label>
          <ul>
            <li *ngIf="project.description">{{ project.description }}</li>
          </ul>
        </div>
        <div class="project-tasks" *ngIf="project.tasks?.length">
          <label class="experience-item-section-title">
            {{ "read_only_profile.project_tasks" | translate }}
          </label>
          <ul>
            <li *ngFor="let task of project.tasks">
              {{ task }}
            </li>
          </ul>
        </div>
        <ng-container *ngIf="getTechnologicalProjectCompetencies(project) as techCompetencies">
          <div class="project-competencies" *ngIf="techCompetencies?.length">
            <label class="experience-item-section-title">
              {{ "read_only_profile.technological_competencies" | translate }}
            </label>
            <app-competency-pill-container
                    [competencies]="techCompetencies"
            ></app-competency-pill-container>
          </div>
        </ng-container>
        <ng-container *ngIf="getLanguageProjectCompetencies(project) as langCompetencies">
          <div class="project-competencies" *ngIf="langCompetencies?.length">
            <label class="experience-item-section-title">
              {{ "read_only_profile.language_competencies" | translate }}
            </label>
            <app-competency-pill-container
                    [competencies]="langCompetencies"
            ></app-competency-pill-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="section mb-2">
      <div
        class="section-title-container d-flex flex-row justify-content-between"
      >
        <label class="section-title">
          {{ "read_only_profile.competencies_title" | translate }}
        </label>
      </div>
      <div class="competency-container">
        <div
          class="competency-category-container d-flex flex-row justify-content-between"
        >
          <label class="competency-category-title">
            {{ "read_only_profile.competency_technological" | translate }}
          </label>
        </div>
        <div
          class="competency-item d-flex flex-row"
          *ngFor="let competency of getTechnologicalCompetencies()"
        >
          <label>{{ competency.level }}</label>
          <label>{{ competency.competencyDTO?.name }}</label>
        </div>
      </div>
      <div class="competency-container">
        <div
          class="competency-category-container d-flex flex-row justify-content-between"
        >
          <div>
            <label class="competency-category-title">
              {{ "read_only_profile.competency_language" | translate }}
            </label>
            <span
              ngbTooltip="{{
                'read_only_profile.language_level_tooltip' | translate
              }}"
              placement="bottom"
              container="body"
              class="ms-1"
              ><fa-icon [icon]="['fas', 'circle-info']"></fa-icon
            ></span>
          </div>
        </div>
        <div
          class="competency-item d-flex flex-row"
          *ngFor="let competency of getLanguageCompetencies()"
        >
          <label>{{ competency.level }}</label>
          <label>{{ competency.competencyDTO?.name }}</label>
        </div>
      </div>
    </div>
  </div>
  <button modal-actions class="btn secondary-btn" (click)="activeModal.close()">
    {{ "button.close" | translate }}
  </button>
</app-base-modal>
