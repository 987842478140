<div class="main-container">
  <div class="table-container">
    <table
      class="table table-light table-hover table-striped table-bordered align-middle scrollable-table"
    >
      <thead>
        <tr>
          <th scope="col" class="table-header text-center">
            {{ "smart_search.table.name" | translate }}
          </th>
          <th scope="col" class="table-header text-center">
            {{ "smart_search.table.company" | translate }}
          </th>
          <th scope="col" class="table-header text-center">
            {{ "smart_search.table.profile" | translate }}
          </th>
          <th
            *ngFor="let header of headers"
            scope="col"
            class="table-header text-center"
          >
            {{ header.name.toUpperCase() }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let employee of results"
          (click)="selectRow(employee.id!)"
          [ngClass]="{
            selected: selectedRows.includes(employee.id!)
          }"
        >
          <td>
            <label class="text-overflow-dotted w-100">{{
              employee.name
            }}</label>
          </td>
          <td>
            <label class="text-overflow-dotted w-100">{{
              employee.companyName
            }}</label>
          </td>
          <td class="text-center">
            <fa-icon
              class="cv-icon"
              [icon]="['fas', 'file']"
              (click)="openReadOnlyProfile(employee.id!)"
            ></fa-icon>
          </td>
          <td
            *ngFor="let competency of headers"
            class="text-center"
            [ngClass]="{
              'in-bound': heatmap && isInBound(employee, competency.id!)
            }"
          >
            {{ getLevel(employee, competency.id!) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    *ngIf="results.length"
    class="d-flex justify-content-between align-items-top p-2"
  >
    <ngb-pagination
      [collectionSize]="collectionSize"
      [(page)]="page"
      [pageSize]="pageSize"
      (pageChange)="refreshResults()"
      class="pages"
    >
    </ngb-pagination>
    <div class="information">
      {{ results.length }} /
      {{ collectionSize }}
    </div>
  </div>
</div>
