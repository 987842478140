import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { CompanyDTO, CompanyService } from "src/shared/api/generated";
import { showLoadingSpinner } from "src/shared/operators/loading-spinner.operator";
import { CompetencyManagerService } from "src/shared/services/competency-manager.service";

@Component({
  selector: "app-employee",
  templateUrl: "./employee.component.html",
  styleUrls: ["./employee.component.scss"],
})
export class EmployeeComponent implements OnInit {
  visibleCompanies: CompanyDTO[] = [];
  competencySidebarDisabled = false;

  constructor(
    private companyService: CompanyService,
    private spinnerService: NgxSpinnerService,
    private competencymanagerService: CompetencyManagerService
  ) {}

  ngOnInit(): void {
    this.refreshCompanies();
    this.competencymanagerService.clearBothCompetencies();
    this.competencymanagerService.clearEmployeeProjectCompetency();
    this.competencymanagerService.employeeCompetency.subscribe((data) => {
      if (!data.employeeId) {
        this.competencySidebarDisabled = true;
      } else {
        this.competencySidebarDisabled = false;
      }
    });
  }

  refreshCompanies() {
    this.companyService
      .getVisibleCompanies()
      .pipe(showLoadingSpinner(this.spinnerService))
      .subscribe((companies) => {
        this.visibleCompanies = [...companies].sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      });
  }
}
