<td (dblclick)="setEditStateTrue('name')">
  <input
    id="position-name"
    *ngIf="this.editedCell === 'name'"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handlePositionUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="setEditStateFalse()"
    (change)="handlePositionUpdate()"
    [(ngModel)]="editedPosition.type"
    (click)="inputClicked($event)"
  />
  <label
    class="text-overflow-dotted"
    [ngClass]="{ hidden: this.editedCell === 'name' }"
  >
    {{ position.type }}
  </label>
</td>
<td class="text-center">
  <fa-icon
    class="remove-icon fs-6"
    (click)="onDeletePosition(position.id!)"
    [icon]="['fas', 'trash']"
  ></fa-icon>
</td>
