import {
  AbstractControl,
  FormArray,
  ValidationErrors,
  ValidatorFn,
} from "@angular/forms";
import { CompetencyDTO } from "src/shared/api/generated";

export function uniqueCompetencyValidator(
  array: CompetencyDTO[] | any[]
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = array.filter(
      (competency) =>
        competency.id === control.get("competencyId")?.value &&
        competency.level === control.get("level")?.value
    );
    const error: ValidationErrors = { notUnique: true };
    return valid.length === 0 ? null : error;
  };
}

export function duplicateCompetencyValidator(
  formArray: FormArray
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = formArray.controls.filter(
      (competencyControl) =>
        competencyControl.get("competencyId")?.value ===
          control.get("competencyId")?.value &&
        competencyControl.get("level")?.value === control.get("level")?.value
    );
    const error: ValidationErrors = { isDuplicate: true };
    return valid.length < 2 ? null : error;
  };
}

export function wrongLevelValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = !(
      control.get("level")?.value > 5 ||
      control.get("level")?.value < 1 ||
      control.get("level")?.value % 1 !== 0
    );
    const error: ValidationErrors = { wrongLevel: true };
    return valid ? null : error;
  };
}
