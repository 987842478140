<app-base-modal
  [activeModal]="activeModal"
  [title]="'plan.create_modal.title' | translate"
  [secondaryTitle]="employeeName"
>
  <div class="content">
    <form class="form" [formGroup]="planForm">
      <div class="form-row" *ngIf="employeeOption">
        <label class="row-label">
          {{ "plan.create_modal.employee" | translate }}
        </label>
        <div class="wrapper">
          <ngx-select-dropdown
            data-testid="employee"
            [config]="employeeSelectConfig"
            [options]="employees"
            formControlName="selectedEmployee"
            [multiple]="false"
            (change)="fetchEmployeeCompetencies($event)"
            class="select min-w-60"
          ></ngx-select-dropdown>
        </div>
      </div>
      <div class="form-row">
        <label class="row-label">
          {{ "plan.create_modal.project" | translate }}
        </label>
        <div class="wrapper">
          <ngx-select-dropdown
            data-testid="project"
            [config]="projectSelectConfig"
            [options]="projects"
            formControlName="selectedProject"
            [multiple]="false"
            (change)="fetchCompetencies($event)"
            class="select min-w-60"
          ></ngx-select-dropdown>
        </div>
      </div>
      <div class="form-row">
        <label class="row-label">{{
          "plan.create_modal.project_duration" | translate
        }}</label>
        <div class="wrapper">
          <label
            *ngIf="selectedProject && selectedProject.id! >= 0"
            class="date-label"
            >{{ selectedProject.start | date }} -
            {{ selectedProject.end | date }}
          </label>
          <label
            *ngIf="!selectedProject || selectedProject.id! < 0"
            class="date-label"
          >
            -
          </label>
        </div>
      </div>
      <div class="form-row">
        <label for="start" class="row-label">{{
          "plan.create_modal.start" | translate
        }}</label>
        <div class="wrapper">
          <input formControlName="from" id="start" type="date" />
          <span
            *ngIf="
              planForm.get('from')?.hasError('required') &&
              planForm.get('from')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="end" class="row-label">{{
          "plan.create_modal.end" | translate
        }}</label>
        <div class="wrapper">
          <input formControlName="to" id="end" type="date" />
          <span
            *ngIf="
              planForm.get('to')?.hasError('required') &&
              planForm.get('to')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
          <span *ngIf="planForm.hasError('invalidDate')" class="form-error">
            {{ "plan.create_modal.error.to_earlier_than_from" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="percentage" class="row-label">
          {{ "plan.create_modal.percentage" | translate }}
        </label>
        <div class="wrapper">
          <div class="range-input-wrapper">
            <input
              type="range"
              min="0"
              max="100"
              step="5"
              formControlName="percentage"
              [value]="planForm.value.percentage"
            />
            <input
              id="percentage"
              type="number"
              min="0"
              max="100"
              class="percentage-number-input"
              formControlName="percentage"
              [value]="planForm.value.percentage"
              (blur)="validatePercentageField()"
            />
          </div>
          <span
            *ngIf="
              planForm.get('percentage')?.hasError('min') &&
              planForm.get('percentage')?.touched
            "
            class="form-error"
          >
            {{ "form.error.percentage_min_value" | translate }}
          </span>
          <span
            *ngIf="
              planForm.get('percentage')?.hasError('required') &&
              planForm.get('percentage')?.touched
            "
            class="form-error"
          >
            {{ "form.error.required" | translate }}
          </span>
        </div>
      </div>
      <div class="form-row">
        <label for="description" class="row-label">
          {{ "plan.create_modal.description" | translate }}
        </label>
        <div class="wrapper">
          <textarea
            id="description"
            class="px-2"
            rows="4"
            placeholder="{{ 'plan.create_modal.placeholder' | translate }}"
            formControlName="description"
          ></textarea>
        </div>
      </div>
      <div class="form-row">
        <label class="row-label">{{
          "plan.create_modal.competency" | translate
        }}</label>
        <div class="w-50">
          <app-competency-pill-container
            [competencies]="competencies"
            [allCompetencies]="allCompetencies"
            [employeeCompetencies]="employeeCompetencies"
            [commands]="commands"
            [isAddDisabled]="isCompetencyPillDisabled()"
            [isAddPillButtonActive]="true"
            (newCompetencyEvent)="checkAndAddNewCompetency($event)"
          ></app-competency-pill-container>
        </div>
      </div>
    </form>
  </div>
  <div *ngIf="error" class="error mt-4">
    <ngb-alert class="alert" type="danger" (closed)="closeAlert()">
      {{ errorMessage }}
    </ngb-alert>
  </div>
  <ng-container modal-actions>
    <button class="btn secondary-btn" (click)="activeModal.close()">
      {{ "button.cancel" | translate }}
    </button>
    <button class="btn primary-btn" (click)="saveNewPlan()">
      {{ "button.save" | translate }}
    </button>
  </ng-container>
</app-base-modal>
