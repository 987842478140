<td data-testid="edit-name" (dblclick)="setEditStateTrue('name')">
  <input
    id="name"
    *ngIf="this.editedCell === 'name' && this.employee.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEmployeeUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleEmployeeUpdate()"
    [(ngModel)]="editEmployee.name"
    (click)="inputClicked($event)"
  />
  <input
    id="name"
    *ngIf="!this.employee.id"
    type="text"
    [(ngModel)]="employee.name"
  />
  <label
    class="w-100 text-overflow-dotted"
    [ngClass]="{ hidden: this.editedCell === 'name' || !this.employee.id }"
    [ngbTooltip]="employee.name"
    [openDelay]="300"
    placement="top"
    container="body"
    >{{ employee.name }}</label
  >
</td>
<td data-testid="edit-email" (dblclick)="setEditStateTrue('email')">
  <input
    id="email"
    *ngIf="this.editedCell === 'email' && this.employee.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEmployeeUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleEmployeeUpdate()"
    [(ngModel)]="editEmployee.email"
    (click)="inputClicked($event)"
  />
  <input
    id="email"
    *ngIf="!this.employee.id"
    type="text"
    [(ngModel)]="employee.email"
  />
  <label
    class="w-100 text-overflow-dotted"
    [ngClass]="{ hidden: this.editedCell === 'email' || !this.employee.id }"
    [ngbTooltip]="employee.email"
    [openDelay]="300"
    placement="top"
    container="body"
    >{{ employee.email }}</label
  >
</td>
<td class="text-center">
  <select
    *ngIf="this.employee.id"
    [(ngModel)]="editEmployee.internal"
    [disabled]="this.isEditingDisabled()"
    (change)="handleEmployeeUpdate()"
    (click)="inputClicked($event)"
  >
    <option value="true">
      {{ "employee.internal" | translate }}
    </option>
    <option value="false">
      {{ "employee.external" | translate }}
    </option>
  </select>
  <select *ngIf="!this.employee.id" [(ngModel)]="employee.internal">
    <option value="true">
      {{ "employee.internal" | translate }}
    </option>
    <option value="false">
      {{ "employee.external" | translate }}
    </option>
  </select>
</td>
<td class="text-center" (dblclick)="setEditStateTrue('externalPartner')">
  <input
    *ngIf="this.editedCell === 'externalPartner' && this.employee.id"
    type="text"
    appPrefixFocusAndSelect
    (keydown.enter)="handleEmployeeUpdate()"
    (keydown.escape)="setEditStateFalse()"
    (focusout)="handleEmployeeUpdate()"
    [(ngModel)]="editEmployee.externalPartner"
    (click)="inputClicked($event)"
  />
  <input
    *ngIf="!this.employee.id"
    [disabled]="employee.internal!"
    type="text"
    [(ngModel)]="employee.externalPartner"
  />
  <label
    class="w-100 text-overflow-dotted"
    [ngClass]="{
      hidden: this.editedCell === 'externalPartner' || !this.employee.id
    }"
    [ngbTooltip]="employee.externalPartner"
    [openDelay]="300"
    placement="top"
    container="body"
    >{{ employee.externalPartner }}</label
  >
</td>
<td class="text-center">
  <select
    *ngIf="this.employee.id"
    (change)="handleEmployeeUpdate()"
    [(ngModel)]="editEmployee.seniority"
    [disabled]="this.isEditingDisabled()"
    (click)="inputClicked($event)"
  >
    <option [ngValue]="null">---</option>
    <option
      [value]="seniority.value"
      *ngFor="let seniority of seniorities | keyvalue"
    >
      {{ "employee.seniority." + seniority.value | translate }}
    </option>
  </select>
  <select *ngIf="!this.employee.id" [(ngModel)]="employee.seniority">
    <option [ngValue]="null">---</option>
    <option
      [value]="seniority.value"
      *ngFor="let seniority of seniorities | keyvalue"
    >
      {{ "employee.seniority." + seniority.value | translate }}
    </option>
  </select>
</td>
<td class="text-center">
  <select
    *ngIf="this.employee.id"
    (change)="handleEmployeeUpdate()"
    [(ngModel)]="editEmployee.positionId"
    [disabled]="this.isEditingDisabled()"
    (click)="inputClicked($event)"
  >
    <option [value]="null">---</option>
    <option *ngFor="let position of positions" [value]="position.id">
      {{ position.type }}
    </option>
  </select>
  <select *ngIf="!this.employee.id" [(ngModel)]="employee.positionId">
    <option [value]="null">---</option>
    <option *ngFor="let position of positions" [value]="position.id">
      {{ position.type }}
    </option>
  </select>
</td>
<td class="text-center">
  <fa-icon
    class="cv-icon"
    [icon]="['fas', 'file']"
    (click)="openReadOnlyProfile(employee.id)"
  ></fa-icon>
</td>
<td class="text-center">
  <fa-icon
    class="education-icon"
    *ngIf="!employee.education || !employee.education.length"
    [icon]="['far', 'file']"
    (click)="openEducationModal()"
  ></fa-icon>
  <fa-icon
    class="education-icon"
    *ngIf="employee.education && employee.education.length"
    [icon]="['fas', 'file']"
    (click)="openEducationModal()"
  ></fa-icon>
</td>
<td class="text-center project">
  <div
    (click)="selectProjectCell($event)"
    [ngClass]="{
      selection: employee.id && selectedProjectCellEmployeeId === employee.id,
      'disabled-icon': createMode
    }"
    class="project-number"
  >
    {{ employee.currentAndFutureProjects?.length }}
  </div>
</td>
<td class="text-center competency">
  <div
    data-testid="employee-competency"
    (click)="selectCompetencyCell($event)"
    [ngClass]="{
      selection: employee.id && selectedCellEmployeeId === employee.id,
      'search-find': isSearchResult(),
      'disabled-icon': createMode
    }"
    class="competency-number"
  >
    {{ averageOfTop3Competencies }}
  </div>
</td>
